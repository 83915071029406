import {FC, ReactElement} from 'react'
import {Edit, EditProps, useNotify} from 'react-admin'

const ExtendedEdit: FC<EditProps & {children: ReactElement}> = ({children, ...props}) => {
  const notify = useNotify()
  const autoHideNotificationDuration = 3000
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{
        onMutate: () => {
          notify(
            'ra.notification.updated',
            {
              autoHideDuration: autoHideNotificationDuration,
              messageArgs: {smart_count: 1},
              type: 'info',
              undoable: false,
            },
          )
        },
        ...props.mutationOptions,
      }}
    >
      {children}
    </Edit>
  )
}

export default ExtendedEdit
