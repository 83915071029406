import {
  Identify,
  identify,
  init,
  reset,
  setUserId,
  track,
} from '@amplitude/analytics-browser'

import {assert} from './asserts'
import env from './env'
import platform from './platform'

let isTrackingEnabled = false
const identity = new Identify()
const apiKey = env('AMPLITUDE_API_KEY')

if (isTrackingEnabled && !!apiKey) {
  init(apiKey)
}

const setUser = (id: string | null, emailAddress: string | null) => {
  assert(
    !!(emailAddress && id) || !(emailAddress || id),
    "'id' and 'emailAddress' must be both null or both not null"
  )
  if (!(apiKey && isTrackingEnabled)) return
  if (id) {
    setUserId(id)
    identify(identity
      .set('Email Address', emailAddress as string)
      .set('User Platform', platform)
    )
  }
  else {
    identify(identity
      .unset('Email Address')
      .unset('User Platform')
    )
    reset()
  }
}

const trackEvent: EventTracker = (name, category, source) => {
  apiKey && isTrackingEnabled && track(name, {Category: category, Source: source})
}

const trackPageView = () => {}

const setTrackingEnabled = (isEnabled: boolean) => {
  isTrackingEnabled = isEnabled
}

const EventCategory = {
  CHANNEL: 'CHANNEL',
  CHAT: 'CHAT',
  CONTACT: 'CONTACT',
  TEMPLATE: 'TEMPLATE',
}

type EventTracker = (name: string, category: string, source?: string) => void

export {
  EventCategory,
  setTrackingEnabled,
  setUser,
  trackEvent,
  trackPageView,
}
