/**
 * Center relatively to the viewport.
 */
const Center = ({children}: {children: React.ReactNode}) => (
  <div
    style={{
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      minHeight: '100vh',
    }}
  >
    {children}
  </div>
)

export default Center
