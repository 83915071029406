import {useEffect} from "react"
import {useLocaleState} from "react-admin"

import useSessionMerchantUser from "../hooks/useSessionMerchantUser"

const AppLanguageSetup = () => {
  const {merchantUser: {language} = {}} = useSessionMerchantUser()
  const [locale, setLocale] = useLocaleState()
  useEffect(() => {
    language && (language !== locale) && setLocale(language)
  }, [language, locale, setLocale])
  return null
}

export default AppLanguageSetup
