import {makeStyles} from '@mui/styles'
import {createElement, FC, ReactNode, useMemo} from 'react'
import {useTranslate} from 'react-admin'
import reactStringReplace from 'react-string-replace'

/*
  Polyvalent translation component that supports all default translation feature from
  "useTranslate()", and add line break and custom tags support.
  These tags string can be replaced concrete JSX components that render.
  This allows to build more complex translation messages where and keeps us from the need
  of splitting the translation messages into multiple parts.

  -- example:
  translation message (de.ts):
   {
     messageKey: "My complex text with a word in '<color>green...</color>'"
   }.

  <Translation
     translationKey="messageKey"
     tags={{
       color: match => <span className={useStyles().greenColor}>{match}</span>
     }}
  />

  The above example renders the translation message but with text "green..." wrapped in a
  span and with a green color.

  Without this component we would need to write two different translation messages which
  breaks the best practice described here
  https://shopify.engineering/internationalization-i18n-best-practices-front-end-developers
  as in certain languages, the position of the word "green..." might not be
  the same as it is in english.
*/
const RichTranslationText: FC<TranslationProps> = ({
  className,
  rootElement = 'span',
  tags,
  translationKey,
  translationOptions,
  // TODO: type this component so that the it recognize the props supported by the
  //  "rootElement"
  ...props
}) => {
  const styles = useStyles()
  const translate = useTranslate()
  const replaceable: Tags = useMemo(() => ({
    i: match => <i>{match}</i>,
    strong: match => <strong>{match}</strong>,
    ...tags,
  }), [tags])
  return useMemo(
    () => createElement<any>(
      rootElement,
      {className: `${styles.root} ${className ?? ''}`, ...props},
      Object.keys(replaceable).reduce<string | ReactNode[]>(
        (replacedText, tag) => reactStringReplace(
          replacedText,
          new RegExp(`<${tag}>(.*?)</${tag}>`, 'g'),
          match => replaceable[tag](match)
        ),
        translate(translationKey, translationOptions)
      )
    ),
    [
      className,
      props,
      replaceable,
      rootElement,
      styles,
      translate,
      translationKey,
      translationOptions,
    ]
  )
}

const useStyles = makeStyles(() => ({
  root: {
    whiteSpace: 'pre-wrap',
  },
}))

interface TranslationProps {
  [key: string]: any,
  className?: string,
  rootElement?: string | FC,
  tags?: Tags,
  translationKey: string,
  translationOptions?: Record<string, any>
}

type Tags = Record<string, (match: string) => ReactNode>

export default RichTranslationText
