import {usePermissions} from 'react-admin'
import {useParams} from 'react-router-dom'

import useIsWegoMerchantUser from './useIsWegoMerchantUser'

const useHasPermission = (action: string, resource: string) => {
  const isWegoMerchantUser = useIsWegoMerchantUser()
  const params = useParams<InboxRouteParams>()
  const {
    isLoading, permissions: {allowedRoles = [], channelAdminChannelIds = []} = {},
  } = usePermissions<Permissions>()
  const isOwner = allowedRoles.includes(MERCHANT_OWNER_ROLE)
  const channelId = [
    'channels',
    'channel_merchant_users',
    'channel_chat_bot_users',
    'channel_working_hours',
    'whatsapp_accounts',
  ].includes(resource) ? params?.channelId : undefined
  if (isLoading || (
    // Merchant "Wego" doesn't want their non-owners to crud merchant customer users
    isWegoMerchantUser && !isOwner && (resource === 'merchant_customer_users') &&
    ['create', 'delete', 'edit'].includes(action)
  )) {
    return false
  }
  return (
    !!PERMISSIONS[resource] &&
    allowedRoles.some(
      role => PERMISSIONS[resource][role]?.includes(action) && (
        (role !== MERCHANT_CHANNEL_ADMIN_ROLE) ||
        ((role === MERCHANT_CHANNEL_ADMIN_ROLE) && (resource === 'campaigns')) ||
        (!!channelId && (channelAdminChannelIds.includes(channelId) || isOwner))
      )
    )
  )
}

const MERCHANT_USER_ROLE = 'merchant'
const MERCHANT_OWNER_ROLE = 'merchant_owner'
const MERCHANT_CHANNEL_ADMIN_ROLE = 'merchant_channel_admin'
const SANDBOX_MERCHANT_USER_ROLE = 'sandbox_merchant_user'

const PERMISSIONS = {
  campaigns: {
    [MERCHANT_CHANNEL_ADMIN_ROLE]: ['create', 'list', 'show'],
    [MERCHANT_OWNER_ROLE]: ['create', 'list', 'show'],
    [MERCHANT_USER_ROLE]: ['list', 'show'],
    [SANDBOX_MERCHANT_USER_ROLE]: ['show'],
  },
  channel_chat_bot_users: {
    [MERCHANT_CHANNEL_ADMIN_ROLE]: ['edit', 'list', 'show'],
    [MERCHANT_OWNER_ROLE]: ['edit', 'list', 'show'],
    [MERCHANT_USER_ROLE]: ['list', 'show'],
    [SANDBOX_MERCHANT_USER_ROLE]: ['show'],
  },
  channel_working_hours: {
    [MERCHANT_CHANNEL_ADMIN_ROLE]: ['edit', 'list', 'show'],
    [MERCHANT_OWNER_ROLE]: ['edit', 'list', 'show'],
    [MERCHANT_USER_ROLE]: ['list', 'show'],
    [SANDBOX_MERCHANT_USER_ROLE]: ['show'],
  },
  channels: {
    [MERCHANT_CHANNEL_ADMIN_ROLE]: ['edit', 'list', 'show'],
    [MERCHANT_OWNER_ROLE]: ['edit', 'list', 'show'],
    [MERCHANT_USER_ROLE]: ['list', 'show'],
    [SANDBOX_MERCHANT_USER_ROLE]: ['show'],
  },
  chat_message_templates: {
    [MERCHANT_CHANNEL_ADMIN_ROLE]: ['create', 'edit', 'list', 'show'],
    [MERCHANT_OWNER_ROLE]: ['create', 'delete', 'edit', 'list', 'show'],
    [MERCHANT_USER_ROLE]: ['create', 'list', 'show'],
    [SANDBOX_MERCHANT_USER_ROLE]: ['show'],
  },
  merchant_customer_users: {
    [MERCHANT_CHANNEL_ADMIN_ROLE]: ['create', 'edit', 'list', 'show'],
    [MERCHANT_OWNER_ROLE]: [
      'create', 'delete', 'edit', 'export', 'import', 'list', 'show',
    ],
    [MERCHANT_USER_ROLE]: ['create', 'edit', 'list', 'show'],
    [SANDBOX_MERCHANT_USER_ROLE]: ['show'],
  },
  merchant_users: {
    [MERCHANT_CHANNEL_ADMIN_ROLE]: ['list', 'show'],
    [MERCHANT_OWNER_ROLE]: ['edit', 'import', 'list', 'show'],
    [MERCHANT_USER_ROLE]: ['list', 'show'],
    [SANDBOX_MERCHANT_USER_ROLE]: ['show'],
  },
  merchants: {
    [MERCHANT_CHANNEL_ADMIN_ROLE]: ['show'],
    [MERCHANT_OWNER_ROLE]: ['edit', 'show'],
    [MERCHANT_USER_ROLE]: ['show'],
    [SANDBOX_MERCHANT_USER_ROLE]: ['show'],
  },
  whatsapp_accounts: {
    [MERCHANT_CHANNEL_ADMIN_ROLE]: ['edit', 'show'],
    [MERCHANT_OWNER_ROLE]: ['connect', 'edit', 'show'],
    [MERCHANT_USER_ROLE]: ['show'],
    [SANDBOX_MERCHANT_USER_ROLE]: ['show'],
  },
}

type InboxRouteParams = Record<'channelId' | 'chatId', string>

export default useHasPermission

