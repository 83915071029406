import {makeStyles} from '@mui/styles'
import {FC} from 'react'

import {ChatMessageTemplates} from '../../../types/graphqlSchema'
import {ExternalLinkIcon} from '../../icons'

const ChatMessageTemplateActionButton: FC<ChatMessageTemplateActionButtonProps> = ({
  template,
}) => {
  const styles = useStyles()
  return (
    <div className={styles.root}>
      <ExternalLinkIcon />
      <a href={template.buttonUrl as string} rel="noreferrer" target="_blank">
        {template.buttonText}
      </a>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    '& a': {
      color: theme.palette.info.main,
      fontWeight: 600,
      textDecoration: 'none',
    },
    alignItems: 'start',
    borderTop: `1px solid ${theme.palette.disabled.main}`,
    color: theme.palette.info.main,
    display: 'flex',
    fill: theme.palette.info.main,
    justifyContent: 'center',
    paddingTop: theme.remSpacing(1),
  },
}))

interface ChatMessageTemplateActionButtonProps {
  template: ChatMessageTemplates
}

export default ChatMessageTemplateActionButton
