import {makeStyles} from '@mui/styles'
import {FC} from 'react'
import Highlighter, {HighlighterProps} from "react-highlight-words"

const WordsHighlighter: FC<WordsHighlighterProps> = ({searchWords, ...props}) => (
  // @ts-ignore
  <Highlighter
    autoEscape
    highlightClassName={useStyles().highlight}
    searchWords={searchWords ?? []}
    {...props}
  />
)

const useStyles = makeStyles(theme => ({
  highlight: {
    backgroundColor: 'unset',
    color: theme.palette.info.main,
  },
}))


interface WordsHighlighterProps extends Omit<HighlighterProps, 'searchWords'> {
  searchWords: HighlighterProps['searchWords'] | undefined
}

export default WordsHighlighter
