import {Alert, AlertProps} from '@mui/material'
import {FC} from 'react'

import {CheckIcon, InfoIcon, WarningIcon} from './icons'

const ExtendedAlert: FC<AlertProps> = ({children, severity, ...props}) => (
  <Alert icon={<ExtendedAlertIcon severity={severity}/>} severity={severity} {...props}>
    {children}
  </Alert>
)

const ExtendedAlertIcon = ({severity}) => {
  switch (severity) {
    case 'warning':
    case 'error':
      return <WarningIcon />
    case 'success':
      return <CheckIcon />
    case 'info':
    default:
      return <InfoIcon />
  }
}

export default ExtendedAlert
