import {Box} from '@mui/material'
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'

import FlinkitFullLogo from '../assets/imgs/flinkitFullLogo.svg'
import theme from '../theme'


// Empty layout component without navbars and links, intented to be used for login,
// signup and password reset pages.
const EmptyLayout = ({children}) => {
  const styles = useStyles()
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className={styles.root}>
          <Box sx={{display: {lg: 'block', xs: 'none'}}}>
            <FlinkitFullLogo className={styles.logo}/>
          </Box>
          {children}
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

const useStyles = makeStyles(() => ({
  logo: {
    left: theme.remSpacing(13),
    position: 'absolute',
    top: theme.remSpacing(6),
  },
  root: {
    background: theme.palette.background.paper,
    height: 'calc(100vh - var(--safe-area-top-inset))',
    overflowY: 'scroll',
    paddingTop: 'var(--safe-area-top-inset)',
  },
}))

export default EmptyLayout
