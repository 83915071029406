import {createElement} from 'react'
import {makeStyles} from 'tss-react/mui'


const LineClamp = ({children, clamp = 2, rootElement = 'span'}) => createElement(
  rootElement,
  {className: useStyles({clamp}).classes.root},
  children,
)

const useStyles = makeStyles<MakeStyleProps>()((_, {clamp}) => ({
  root: {
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': clamp,
    display: '-webkit-box',
    overflow: 'hidden',
  },
}))

interface MakeStyleProps {
  clamp: number
}

export default LineClamp
