import {makeStyles} from '@mui/styles'
import {FC, HTMLAttributes} from 'react'

import Dot from './Dot'

// Circle size is 80% of the current `font-size`
const AttentionIndicator: FC<AttentionIndicatorProps> = ({className, ...props}) => {
  const styles = useStyles()
  return (
    <Dot className={`${styles.root} ${className ?? ''}`} {...props} />
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.error.main,
    marginLeft: '0.5em',
  },
}))

type AttentionIndicatorProps = HTMLAttributes<HTMLElement>

export default AttentionIndicator
