import {makeStyles} from '@mui/styles'
import {FC, useMemo} from 'react'
import Linkify from 'react-linkify'

import {useCompileChatMessageText} from '../../../hooks/useCompileChatMessageText'
import {
  ChatMessages,
  ChatMessageTemplates,
  ChatMessageTemplateValues,
} from '../../../types/graphqlSchema'

const ChatMessageText: FC<ChatMessageTextProps> = ({
  chatMessageTemplate,
  chatMessageTemplateValues,
  forwardedChatMessage,
  text,
}) => {
  const styles = useStyles()
  const {compileChatMessageText} = useCompileChatMessageText()
  const compiledText = useMemo(
    () => compileChatMessageText(
      forwardedChatMessage ?? ({chatMessageTemplate, chatMessageTemplateValues, text})
    ),
    [
      chatMessageTemplate,
      chatMessageTemplateValues,
      compileChatMessageText,
      forwardedChatMessage,
      text,
    ]
  )
  if (!compiledText) return null
  return (
    <div className={`${styles.root} ${forwardedChatMessage ? styles.forwarded : ''}`}>
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a href={decoratedHref} key={key} rel="noreferrer" target="_blank">
            {decoratedText}
          </a>
        )}
      >
        {compiledText}
      </Linkify>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  forwarded: {
    fontStyle: 'italic',
  },
  root: {
    color: theme.palette.primary.main,
    paddingBottom: '0',
    ...theme.typography.body1,
    position: 'relative',
  },
}))

interface ChatMessageTextProps {
  chatMessageTemplate?: ChatMessageTemplates
  chatMessageTemplateValues: ChatMessageTemplateValues[]
  forwardedChatMessage?: ChatMessages
  text?: string,
}

export default ChatMessageText
