import {useLazyQuery} from '@apollo/client'
import {Box, BoxProps, IconButton} from '@mui/material'
import {makeStyles} from '@mui/styles'
import gql from 'graphql-tag'
import {debounce} from 'lodash-es'
import {forwardRef, useImperativeHandle, useMemo, useRef, useState} from 'react'
import {useTranslate} from 'react-admin'

import {ChatMessages, QueryRoot} from '../../types/graphqlSchema'
import {CloseIcon} from '../icons'
import ChatSearchInboxInput from './ChatSearchInboxInput'
import SearchInboxChatMessageList from './SearchInboxChatMessageList'

const ChatMessageSearchInboxSidebar = forwardRef<
  ChatMessageSearchInboxSidebarRef, ChatMessageSearchInboxSidebarProps
>(({
  chatId,
  onChatMessageClicked,
  onClose,
  ...props
}, ref) => {
  const styles = useStyles()
  const inputRef = useRef<HTMLInputElement>()
  useImperativeHandle(ref, () => ({
    focusSearchInboxInput: () => inputRef.current?.focus(),
  }))
  const [searchInboxText, setSearchInboxText] = useState("")
  const [
    searchChatMessages,
    {
      data: {chat_messages: searchedChatMessages = []} = {},
      loading: isSearchingChatMessages,
    },
  ] = useLazyQuery<QueryRoot['chat_messages']>(CHAT_MESSAGE_SEARCH_QUERY)
  const debounceSearchChatMessages = useMemo(
    () => debounce((text: string) => {
      if (text.trim().length < 3) return
      // useLazyQuery auto-runs the query(just like a normal) query when its dependecies
      // change. As a workaround we pass the dependencies at the moment the function is
      // called.
      // See related issue: https://github.com/apollographql/apollo-client/issues/9317
      searchChatMessages({
        variables: {
          chatId,
          pattern: text.trim() ?
            `%${text.trim().split(' ')
              // Eliminate empty strings(otherwise, the pattern always matches everything)
              .filter(Boolean)
              .join('%')
            }%` : '',
        },
      })
    }, 500), [chatId, searchChatMessages]
  )
  return (
    <Box {...props} className={styles.root}>
      <div className={styles.header}>
        <IconButton onClick={onClose}>
          <CloseIcon color="secondary" />
        </IconButton>
        <span>{useTranslate()('searchInbox.searchChatMessagesTitle')}</span>
      </div>
      <div className={styles.searchInputContainer}>
        <ChatSearchInboxInput
          inputRef={inputRef}
          onChange={v => {
            setSearchInboxText(v)
            debounceSearchChatMessages(v)
          }}
          placeholder={useTranslate()('searchInbox.searchInThisChatPlaceholder')}
          value={searchInboxText}
        />
      </div>
      {searchInboxText.length >= 3 && (
        <SearchInboxChatMessageList
          chatMessages={searchedChatMessages}
          className={styles.searchInboxChatMessageList}
          loading={isSearchingChatMessages}
          onChatMessageClicked={onChatMessageClicked}
          searchWords={searchInboxText}
        />
      )}
    </Box>
  )
})

const useStyles = makeStyles(theme => ({
  header: {
    alignItems: "center",
    borderBottom: `solid .5px ${theme.palette.disabled.main}`,
    display: 'flex',
    gap: '6px',
    height: theme.remSpacing(8),
  },
  root: {
    overflowY: 'hidden',
  },
  searchInboxChatMessageList: {
    height: '100%',
    overflowY: 'auto',
  },
  searchInputContainer: {
    margin: theme.remSpacing(2.5),
  },
}))

const CHAT_MESSAGE_SEARCH_QUERY = gql`query($pattern: String!, $chatId: uuid!){
  chat_messages(
    limit: 50
    order_by: {insertionTimestamp: desc}
    where: {
      chatId: {_eq: $chatId}
      fullText: {_ilike: $pattern}
    }
  ){
    chatId
    chat{id channelId}
    chatMessageTemplate{id text}
    chatMessageTemplateValues{chatMessageTemplatePlaceholder{index type} value}
    id
    text
    timestamp
  }
}`

interface ChatMessageSearchInboxSidebarProps extends BoxProps {
  chatId: string
  onChatMessageClicked?: (chatMessage: ChatMessages) => void,
  onClose: VoidFunction
}

export interface ChatMessageSearchInboxSidebarRef {
  focusSearchInboxInput: VoidFunction
}

export default ChatMessageSearchInboxSidebar
