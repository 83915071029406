import {first} from 'lodash-es'
import {useCallback} from 'react'
import {useTranslate} from 'react-admin'

import {Users} from '../types/graphqlSchema'

const useUserDisplayName = () => {
  const translate = useTranslate()
  return useCallback(({
    channelChatBotUser,
    customerUser,
    merchantApiUser,
    merchantUser,
  }: Pick<
    Users, 'channelChatBotUser' | 'customerUser' | 'merchantApiUser' | 'merchantUser'
    > = {} as Users
  ) => {
    if (channelChatBotUser) {
      return translate('hooks.useUserDisplayName.channelChatBotUser')
    }
    if (merchantUser) {
      return `${merchantUser.firstName} ${merchantUser.lastName}`
    }
    if (merchantApiUser) return merchantApiUser.name
    const merchantCustomerUser = first(customerUser?.merchantCustomerUsers)
    return (
      `${merchantCustomerUser?.firstName ?? ''} ${merchantCustomerUser?.lastName ?? ''}`
        .trim() || customerUser?.whatsappDisplayName
    )
  }, [translate])
}

const useUsersDisplayName = () => {
  const userDisplayName = useUserDisplayName()
  return useCallback(
    (users: Users[] = []) => users.map(userDisplayName).join(', '),
    [userDisplayName],
  )
}

export {useUserDisplayName, useUsersDisplayName}
