import {MutationResultWrapper, useMutation, useQuery} from '@apollo/client'
import {
  ClickAwayListener,
  DialogContent,
  FormControlLabel,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Switch,
  Typography,
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import gql from 'graphql-tag'
import {sortBy} from 'lodash-es'
import {FC, useCallback, useRef, useState} from 'react'
import {useTranslate} from 'react-admin'

import DialogCloseButton from '../../components/DialogCloseButton'
import ExtendedDialog from '../../components/ExtendedDialog'
import {TranslationIcon} from '../../components/icons'
import useIsDesktop from '../../hooks/useIsDesktop'
import {MutationRoot} from '../../types/graphqlSchema'
import {EventCategory, trackEvent} from '../../utils/tracking'

const ChatAutoTranslationSettingButton: FC<ChatAutoTranslationSettingButtonProps> = ({
  chatId,
  disabled,
  isShowingTranslations,
  onChangeIsShowingTranslations,
}) => {
  const styles = useStyles()
  const isDesktop = useIsDesktop()
  const {
    data: {chats_by_pk: chat} = {},
  } = useQuery(CHAT_QUERY, {variables: {id: chatId}})
  const [updateChat] = useMutation<MutationRoot['update_chats_by_pk']>(
    UPDATE_CHAT_MUTATION
  )
  const setTranslationLanguage = useCallback(language => {
    updateChat({
      optimisticResponse: {
        update_chats_by_pk: {
          __typename: 'chats', autoTranslationLanguage: language, id: chatId,
        },
      } as MutationResultWrapper<MutationRoot['update_chats_by_pk']>,
      variables: {autoTranslationLanguage: language, id: chatId},
    })
  }, [chatId, updateChat])
  const anchorElementRef = useRef<HTMLButtonElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <IconButton
        aria-label="translation"
        disabled={disabled}
        ref={anchorElementRef}
        onClick={() => setIsOpen(isOpen => !isOpen)}
      >
        <TranslationIcon
          color={
            (chat?.autoTranslationLanguage || isShowingTranslations) ? 'info' : 'primary'
          }
        />
      </IconButton>
      {isDesktop ?
        (
          /*
            TODO: Show arrow (nose), described in the docs:
              https://v4.mui.com/components/popper/
            with example code here:
              https://github.com/mui/material-ui/blob/4f2a07e140c954b478a6670c009c23a59ec3e2d4/docs/src/pages/components/popper/ScrollPlayground.js
          */
          <Popper
            onClick={() => setIsOpen(isOpen => !isOpen)}
            anchorEl={anchorElementRef.current}
            className={styles.popper}
            modifiers={[
              {enabled: true, name: 'flip'},
              {
                enabled: true,
                name:
                'arrow',
                options: {element: anchorElementRef.current},
              },
            ]}
            open={isOpen}
            placement="top"
          >
            <ClickAwayListener onClickAway={() => setIsOpen(false)}>
              <Paper
                className={styles.popperPaper}
                elevation={3}
                onClick={e => e.stopPropagation()}
              >
                <TranslationSettingsContent
                  autoTranslationLanguage={chat?.autoTranslationLanguage}
                  isShowingTranslations={isShowingTranslations}
                  onChangeIsShowingTranslations={onChangeIsShowingTranslations}
                  onSetTranslationLanguage={setTranslationLanguage}
                />
                <DialogCloseButton
                  className={styles.closeButton}
                  onClick={() => setIsOpen(false)}
                />
              </Paper>
            </ClickAwayListener>
          </Popper>
        ) :
        (
          <ExtendedDialog fullScreen onClose={() => setIsOpen(false)} open={isOpen}>
            <DialogContent>
              <TranslationSettingsContent
                autoTranslationLanguage={chat?.autoTranslationLanguage}
                isShowingTranslations={isShowingTranslations}
                onChangeIsShowingTranslations={onChangeIsShowingTranslations}
                onSetTranslationLanguage={setTranslationLanguage}
              />
            </DialogContent>
            <DialogCloseButton onClick={() => setIsOpen(false)} />
          </ExtendedDialog>
        )
      }
    </>
  )
}

const TranslationSettingsContent = ({
  autoTranslationLanguage,
  isShowingTranslations,
  onChangeIsShowingTranslations,
  onSetTranslationLanguage,
}) => {
  const styles = useStyles()
  const translate = useTranslate()
  return (
    <div>
      <Typography variant="h3">
        {translate('chat.autoTranslationSettingButton.title')}
      </Typography>
      <Typography color="textSecondary" variant="body1">
        {translate('chat.autoTranslationSettingButton.subtitle')}
      </Typography>
      <MenuList>
        {
          sortBy(
            [null, ...LANGUAGES].map(
              l => ({
                label: l ?
                  translate(`languages.${l}`) :
                  translate('chat.autoTranslationSettingButton.doNotTranslate'),
                language: l,
              })
            ),
            'label'
          ).map(({label, language}) => (
            <MenuItem
              className={`
              ${styles.translationsMenuListItem}
              ${(language === autoTranslationLanguage) ? styles.selected : ''}
            `}
              key={language ?? 'off'}
              onClick={() => {
                onSetTranslationLanguage(language)
                trackEvent('SELECT_TRANSLATION_LANGUAGE', EventCategory.CHAT)
              }}
            >
              {label}
              {(language === autoTranslationLanguage) && " ✓"}
            </MenuItem>
          ))
        }
      </MenuList>
      <hr className={styles.popperSettingsSeparator} />
      <h4>{translate('chat.autoTranslationSettingButton.moreSettings')}</h4>
      <FormControlLabel
        className={styles.isShowingTranslationsSwitch}
        control={<Switch
          checked={isShowingTranslations}
          onChange={(_, isShowingTranslations) => {
            onChangeIsShowingTranslations(isShowingTranslations)
          }}
        />}
        label={translate('chat.autoTranslationSettingButton.autoDetection')}
        labelPlacement="start"
      />
    </div>
  )
}

interface ChatAutoTranslationSettingButtonProps {
  chatId: string
  disabled?: boolean
  isShowingTranslations?: boolean
  onChangeIsShowingTranslations?: (isShowingTranslations: boolean) => void
}

const useStyles = makeStyles(theme => ({
  closeButton: {
    paddingTop: theme.remSpacing(2),
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  isShowingTranslationsSwitch: {
    fontSize: theme.typography.body1.fontSize,
    marginLeft: 0,
    marginRight: 0,
  },
  popper: {
    maxWidth: '340px',
    zIndex: 1004,
  },
  popperPaper: {
    '& .MuiTypography-root': {
      marginBottom: theme.remSpacing(1),
    },
    boxSizing: 'border-box',
    maxHeight: '90vh',
    overflowY: 'auto',
    padding: theme.remSpacing(2),
  },
  popperSettingsSeparator: {
    border: '1px solid #CDD5DF',
  },
  selected: {
    fontWeight: 'bold',
  },
  translationsMenuListItem: {
    fontSize: theme.typography.body1.fontSize,
  },
}))

const CHAT_QUERY = gql`
  query($id: uuid!){chats_by_pk(id: $id){autoTranslationLanguage id}}
`

const UPDATE_CHAT_MUTATION = gql`
  mutation($id: uuid!, $autoTranslationLanguage: String) {
    update_chats_by_pk(
      pk_columns: {id: $id}
      _set: {autoTranslationLanguage: $autoTranslationLanguage}
    ){autoTranslationLanguage id}
  }
`

const LANGUAGES = [
  'bs',
  'en',
  'es',
  'fr',
  'fi',
  'hr',
  'it',
  'ku',
  'nl',
  'pl',
  'ro',
  'ru',
  'sq',
  'sr',
  'tr',
]

export default ChatAutoTranslationSettingButton
