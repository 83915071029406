import {ClickAwayListener, IconButton, Popper} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {Picker} from 'emoji-mart'
import {FC, useRef, useState} from 'react'
import {useTranslate} from 'react-admin'

import {EmojiToolbarIcon} from '../../icons'

const EmojiPickerButton: FC<EmojiPickerButtonProps> = ({disabled, onSelect}) => {
  const styles = useStyles()
  const anchorElementRef = useRef<HTMLButtonElement>(null)
  const [isShowingEmojis, setIsShowingEmojis] = useState(false)
  return (
    <>
      <IconButton
        className={styles.emojiButton}
        disabled={disabled}
        onClick={() => setIsShowingEmojis(s => !s)}
        ref={anchorElementRef}
      >
        <EmojiToolbarIcon color="primary" />
      </IconButton>
      <Popper
        anchorEl={anchorElementRef.current}
        className={styles.emojiPopper}
        onClick={e => e.stopPropagation()}
        open={isShowingEmojis}
        placement="top"
      >
        <ClickAwayListener onClickAway={() => setIsShowingEmojis(false)}>
          <Picker
            autoFocus
            emoji="grinning"
            i18n={{
              categories: {
                recent: useTranslate()('chat.toolbar.emojiPickerButton.recent'),
                search: useTranslate()(
                  'chat.toolbar.emojiPickerButton.searchResults'
                ),
              },
              search: useTranslate()('chat.toolbar.emojiPickerButton.search'),
            }}
            native
            onSelect={emoji => {
              onSelect(emoji.native)
              setIsShowingEmojis(false)
            }}
            title={
              useTranslate()('chat.toolbar.emojiPickerButton.title').toLowerCase()
            }
          />
        </ClickAwayListener>
      </Popper>
    </>
  )
}

const useStyles = makeStyles(() => ({
  emojiButton: {
    marginRight: '0.3rem',
  },
  emojiPopper: {
    zIndex: 1002,
  },
}))

interface EmojiPickerButtonProps {
  disabled?: boolean
  onSelect: (emoji: string) => void
}

export default EmojiPickerButton
