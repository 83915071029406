import {differenceInDays, format, getDay, isToday, isYesterday, parseISO} from 'date-fns'
import {useCallback} from 'react'
import {useLocale, useTranslate} from 'react-admin'

import {timeStringFromTimestamp} from '../utils/dates'

const useSimplifyAge = () => {
  const translate = useTranslate()
  const locale = useLocale()
  return useCallback(
    (
      timestamp: string,
      options: UseSimplifyAgeOptions = {canShowToday: false},
    ) => {
      if (isToday(parseISO(timestamp)) && options.canShowToday) {
        return translate('ages.today').toLowerCase()
      }
      if (isYesterday(parseISO(timestamp))) {
        return translate('ages.yesterday').toLowerCase()
      }
      const daysElapsed = differenceInDays(new Date(), parseISO(timestamp))
      if (daysElapsed < 1) return timeStringFromTimestamp(timestamp)
      if (daysElapsed < 7) return getWeekDayNames(locale)[getDay(parseISO(timestamp))]
      return format(parseISO(timestamp), 'dd.MM.yyy')
    },
    [locale, translate]
  )
}

const getWeekDayNames = (
  locale = 'de',
  format: "long" | "short" | "narrow" | undefined = 'long',
) => {
  const formatter = new Intl.DateTimeFormat(locale, {timeZone: 'UTC', weekday: format})
  const days = [1, 2, 3, 4, 5, 6, 7].map(day => {
    const dd = day < 10 ? `0${day}` : day
    return new Date(`2017-01-${dd}T00:00:00+00:00`)
  })
  return days.map(date => formatter.format(date))
}


interface UseSimplifyAgeOptions {
  canShowToday?: boolean
}


export default useSimplifyAge
