import {SvgIcon, SvgIconTypeMap} from '@mui/material'
import {
  cloneElement, Component, FC, HTMLAttributes, ReactElement, useMemo,
} from 'react'

import AddFilledIconSvg from '../assets/imgs/addFilledIcon.svg'
import AddIconSvg from '../assets/imgs/addIcon.svg'
import AppIconLogoSvg from '../assets/imgs/appIconLogo.svg'
import ApprovedIconSvg from '../assets/imgs/approvedIcon.svg'
import ArchiveIconSvg from '../assets/imgs/archiveIcon.svg'
import ArrowDownIconSvg from '../assets/imgs/arrowDownIcon.svg'
import ArrowLeftIconSvg from '../assets/imgs/arrowLeftIcon.svg'
import ArrowRightIconSvg from '../assets/imgs/arrowRightIcon.svg'
import AttachmentIconSvg from '../assets/imgs/attachmentIcon.svg'
import BlockIconSvg from '../assets/imgs/blockIcon.svg'
import BriefcaseIconSvg from '../assets/imgs/briefcaseIcon.svg'
import CameraIconSvg from '../assets/imgs/cameraIcon.svg'
import CampaignIconSvg from '../assets/imgs/campaignIcon.svg'
import ChatIconSvg from '../assets/imgs/chatIcon.svg'
import ChatMessageTemplateIconSvg from '../assets/imgs/chatMessageTemplateIcon.svg'
import ChatMessageTemplateToolbarIconSvg
  from '../assets/imgs/chatMessageTemplateToolbarIcon.svg'
import CheckIconSvg from '../assets/imgs/checkIcon.svg'
import ChevronDownIconSvg from '../assets/imgs/chevronDownIcon.svg'
import ChevronRightIconSvg from '../assets/imgs/chevronRightIcon.svg'
import ClockIconSvg from '../assets/imgs/clockIcon.svg'
import CloseFilledIconSvg from '../assets/imgs/closeFilledIcon.svg'
import CloseIconSvg from '../assets/imgs/closeIcon.svg'
import ContactsIconSvg from '../assets/imgs/contactsIcon.svg'
import DeleteIconSvg from '../assets/imgs/deleteIcon.svg'
import DeliveredStatusIconSvg from '../assets/imgs/deliveredStatusIcon.svg'
import DownloadIconSvg from '../assets/imgs/downloadIcon.svg'
import EditFilledIconSvg from '../assets/imgs/editFilledIcon.svg'
import EditIconSvg from '../assets/imgs/editIcon.svg'
import EmojiIconSvg from '../assets/imgs/emojiIcon.svg'
import EmojiToolbarIconSvg from '../assets/imgs/emojiToolbarIcon.svg'
import ErrorIconSvg from '../assets/imgs/errorIcon.svg'
import ExternalChatIconSvg from '../assets/imgs/externalchaticon.svg'
import ExternalLinkIconSvg from '../assets/imgs/externalLinkIcon.svg'
import FailedStatusIconSvg from '../assets/imgs/failedStatusIcon.svg'
import FlagIconSvg from '../assets/imgs/flagIcon.svg'
import HelpIconSvg from '../assets/imgs/helpIcon.svg'
import HubspotIconSvg from '../assets/imgs/hubspotIcon.svg'
import InfoIconSvg from '../assets/imgs/infoIcon.svg'
import InternalChatIconSvg from '../assets/imgs/internalChatIcon.svg'
import LogoIconSvg from '../assets/imgs/logo.svg'
import LogoutIconSvg from '../assets/imgs/logoutIcon.svg'
import MagicIconSvg from '../assets/imgs/magicIcon.svg'
import MessagesIconSvg from '../assets/imgs/messagesIcon.svg'
import MicrosoftDynamicsIconSvg from '../assets/imgs/microsoftDynamicsIcon.svg'
import MobileNavigationIconSvg from '../assets/imgs/mobileNavigationIcon.svg'
import MoreVertIconSvg from '../assets/imgs/moreVertIcon.svg'
import NotificationCheckIconSvg from '../assets/imgs/notificationCheckIcon.svg'
import PictureIconSvg from '../assets/imgs/pictureIcon.svg'
import ReadStatusIconSvg from '../assets/imgs/readStatusIcon.svg'
import RejectedIconSvg from '../assets/imgs/rejectedIcon.svg'
import ReplyWindowExpiredSvg from '../assets/imgs/replyWindowExpiredIcon.svg'
import ReplyWindowUnexpiredSvg from '../assets/imgs/replyWindowUnexpiredIcon.svg'
import SalesforceIconSvg from '../assets/imgs/salesforce.svg'
import SapIconSvg from '../assets/imgs/sapIcon.svg'
import SaveIconSvg from '../assets/imgs/saveIcon.svg'
import SearchIconSvg from '../assets/imgs/searchIcon.svg'
import SendIconSvg from '../assets/imgs/sendIcon.svg'
import SendingStatusIconSvg from '../assets/imgs/sendingStatusIcon.svg'
import SentStatusIconSvg from '../assets/imgs/sentStatusIcon.svg'
import SettingsIconSvg from '../assets/imgs/settingsIcon.svg'
import ShareIconSvg from '../assets/imgs/shareIcon.svg'
import SyncIconSvg from '../assets/imgs/syncIcon.svg'
import TranslationIconSvg from '../assets/imgs/translationIcon.svg'
import UnarchiveIconSvg from '../assets/imgs/unarchiveIcon.svg'
import UploadIconSvg from '../assets/imgs/uploadIcon.svg'
import UserAddIconSvg from '../assets/imgs/userAddIcon.svg'
import UserIconSvg from '../assets/imgs/userIcon.svg'
import UsersGroupIconSvg from '../assets/imgs/usersGroupIcon.svg'
import WarningIconSvg from '../assets/imgs/warningIcon.svg'
import WhatsappAddIconSvg from '../assets/imgs/whatsappAddIcon.svg'
import WhatsappAttachmentIconSvg from '../assets/imgs/whatsappAttachmentIcon.svg'
import WhatsappAudioCallSvg from '../assets/imgs/whatsappAudioCallIcon.svg'
import WhatsappAudioRecordIconSvg from '../assets/imgs/whatsappAudioRecordIcon.svg'
import WhatsappBackIconSvg from '../assets/imgs/whatsappBackIcon.svg'
import WhatsappBubbleTailIconSvg from '../assets/imgs/whatsappBubbleTailIcon.svg'
import WhatsappIconSvg from '../assets/imgs/whatsappIcon.svg'
import WhatsappVideoCallIconSvg from '../assets/imgs/whatsappVideoCallIcon.svg'
import {capitalize} from '../utils/strings'

const makeIcon = (
  Svg: FC<React.SVGAttributes<SVGElement>>
) => ({className, color, size, ...props}: SvgIconProps) => (
  <SvgIcon
    className={colorAdjustingClassName(className, color)}
    fontSize={size}
    viewBox={(Svg({}) as unknown as Component<{viewBox?: string}>).props.viewBox}
    {...props}
  >
    {/* We only need children of `Svg` since `SvgIcon` already renders to `svg` */}
    {useMemo(
      () => {
        const {children} = (
          Svg({}) as unknown as Component<{children?: ReactElement}>
        )!.props
        // Strip off the fill={} prop in paths to match Mui SVG patterns.
        return (
          (Array.isArray(children) ? children : [children]) as ReactElement[]
        ).map((child, i) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const {fill, ...props} = child.props
          const childWithoutFill = {...child, props}
          return cloneElement(childWithoutFill, {...props, key: i})
        })
      },
      [],
    )}
  </SvgIcon>
)

// Mui's `SvgIcon` only supports a limited set of semantical palette colors for its
// `color` prop instead of supporting any value from the theme's palette. We work around
// this limitation by explicitly applying mui's color class.
const colorAdjustingClassName = (className: string | undefined, color?: string) =>
  // TODO: Assert that `color` is in the list of theme palette color names
  color ? `MuiSvgIcon-color${capitalize(color)} ${className ?? ''}` : className

interface SvgIconProps extends Partial<HTMLAttributes<SVGElement>> {
  className?: string,
  color?: string
  size?: SvgIconTypeMap['props']['fontSize']
}

const AddFilledIcon = makeIcon(AddFilledIconSvg)
const AddIcon = makeIcon(AddIconSvg)
const AppIconLogo = makeIcon(AppIconLogoSvg)
const ApprovedIcon = makeIcon(ApprovedIconSvg)
const ArchiveIcon = makeIcon(ArchiveIconSvg)
const ArrowDownIcon = makeIcon(ArrowDownIconSvg)
const ArrowLeftIcon = makeIcon(ArrowLeftIconSvg)
const ArrowRightIcon = makeIcon(ArrowRightIconSvg)
const AttachmentIcon = makeIcon(AttachmentIconSvg)
const BlockIcon = makeIcon(BlockIconSvg)
const BriefcaseIcon = makeIcon(BriefcaseIconSvg)
const CameraIcon = makeIcon(CameraIconSvg)
const CampaignIcon = makeIcon(CampaignIconSvg)
const ChatIcon = makeIcon(ChatIconSvg)
const ChatMessageTemplateIcon = makeIcon(ChatMessageTemplateIconSvg)
const ChatMessageTemplateToolbarIcon = makeIcon(ChatMessageTemplateToolbarIconSvg)
const CheckIcon = makeIcon(CheckIconSvg)
const ChevronDownIcon = makeIcon(ChevronDownIconSvg)
const ChevronRightIcon = makeIcon(ChevronRightIconSvg)
const ClockIcon = makeIcon(ClockIconSvg)
const CloseFilledIcon = makeIcon(CloseFilledIconSvg)
const CloseIcon = makeIcon(CloseIconSvg)
const ContactsIcon = makeIcon(ContactsIconSvg)
const DeleteIcon = makeIcon(DeleteIconSvg)
const DeliveredStatusIcon = makeIcon(DeliveredStatusIconSvg)
const DownloadIcon = makeIcon(DownloadIconSvg)
const EditIcon = makeIcon(EditIconSvg)
const EditFilledIcon = makeIcon(EditFilledIconSvg)
const EmojiIcon = makeIcon(EmojiIconSvg)
const EmojiToolbarIcon = makeIcon(EmojiToolbarIconSvg)
const ErrorIcon = makeIcon(ErrorIconSvg)
const ExternalChatIcon = makeIcon(ExternalChatIconSvg)
const ExternalLinkIcon = makeIcon(ExternalLinkIconSvg)
const FailedStatusIcon = makeIcon(FailedStatusIconSvg)
const FlagIcon = makeIcon(FlagIconSvg)
const HelpIcon = makeIcon(HelpIconSvg)
const HubspotIcon = makeIcon(HubspotIconSvg)
const InfoIcon = makeIcon(InfoIconSvg)
const InternalChatIcon = makeIcon(InternalChatIconSvg)
const LogoIcon = makeIcon(LogoIconSvg)
const LogoutIcon = makeIcon(LogoutIconSvg)
const MagicIcon = makeIcon(MagicIconSvg)
const MessagesIcon = makeIcon(MessagesIconSvg)
const MicrosoftDynamicsIcon = makeIcon(MicrosoftDynamicsIconSvg)
const MobileNavigationIcon = makeIcon(MobileNavigationIconSvg)
const MoreVertIcon = makeIcon(MoreVertIconSvg)
const NotificationCheckIcon = makeIcon(NotificationCheckIconSvg)
const PictureIcon = makeIcon(PictureIconSvg)
const ReadStatusIcon = makeIcon(ReadStatusIconSvg)
const RejectedIcon = makeIcon(RejectedIconSvg)
const ReplyWindowExpiredIcon = makeIcon(ReplyWindowExpiredSvg)
const ReplyWindowUnexpiredIcon = makeIcon(ReplyWindowUnexpiredSvg)
const SalesforceIcon = makeIcon(SalesforceIconSvg)
const SapIcon = makeIcon(SapIconSvg)
const SaveIcon = makeIcon(SaveIconSvg)
const SearchIcon = makeIcon(SearchIconSvg)
const SendIcon = makeIcon(SendIconSvg)
const SendingStatusIcon = makeIcon(SendingStatusIconSvg)
const SentStatusIcon = makeIcon(SentStatusIconSvg)
const SettingsIcon = makeIcon(SettingsIconSvg)
const ShareIcon = makeIcon(ShareIconSvg)
const SyncIcon = makeIcon(SyncIconSvg)
const TranslationIcon = makeIcon(TranslationIconSvg)
const UnarchiveIcon = makeIcon(UnarchiveIconSvg)
const UploadIcon = makeIcon(UploadIconSvg)
const UserAddIcon = makeIcon(UserAddIconSvg)
const WarningIcon = makeIcon(WarningIconSvg)
const WhatsappAddIcon = makeIcon(WhatsappAddIconSvg)
const WhatsappAttachmentIcon = makeIcon(WhatsappAttachmentIconSvg)
const WhatsappAudioCallIcon = makeIcon(WhatsappAudioCallSvg)
const WhatsappAudioRecordIcon = makeIcon(WhatsappAudioRecordIconSvg)
const WhatsappBackIcon = makeIcon(WhatsappBackIconSvg)
const WhatsappIcon = makeIcon(WhatsappIconSvg)
const WhatsappVideoCallIcon = makeIcon(WhatsappVideoCallIconSvg)
const WhatsappBubbleTailIcon = makeIcon(WhatsappBubbleTailIconSvg)
const UserIcon = makeIcon(UserIconSvg)
const UserGroupIcon = makeIcon(UsersGroupIconSvg)

export {
  AddFilledIcon,
  AddIcon,
  AppIconLogo,
  ApprovedIcon,
  ArchiveIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  AttachmentIcon,
  BlockIcon,
  BriefcaseIcon,
  CameraIcon,
  CampaignIcon,
  ChatIcon,
  ChatMessageTemplateIcon,
  ChatMessageTemplateToolbarIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ClockIcon,
  CloseFilledIcon,
  CloseIcon,
  ContactsIcon,
  DeleteIcon,
  DeliveredStatusIcon,
  DownloadIcon,
  EditFilledIcon,
  EditIcon,
  EmojiIcon,
  EmojiToolbarIcon,
  ErrorIcon,
  ExternalChatIcon,
  ExternalLinkIcon,
  FailedStatusIcon,
  FlagIcon,
  HelpIcon,
  HubspotIcon,
  InfoIcon,
  InternalChatIcon,
  LogoIcon,
  LogoutIcon,
  MagicIcon,
  MessagesIcon,
  MicrosoftDynamicsIcon,
  MobileNavigationIcon,
  MoreVertIcon,
  NotificationCheckIcon,
  PictureIcon,
  ReadStatusIcon,
  RejectedIcon,
  ReplyWindowExpiredIcon,
  ReplyWindowUnexpiredIcon,
  SalesforceIcon,
  SapIcon,
  SaveIcon,
  SearchIcon,
  SendIcon,
  SendingStatusIcon,
  SentStatusIcon,
  SettingsIcon,
  ShareIcon,
  SyncIcon,
  TranslationIcon,
  UnarchiveIcon,
  UploadIcon,
  UserAddIcon,
  UserGroupIcon,
  UserIcon,
  WarningIcon,
  WhatsappAddIcon,
  WhatsappAttachmentIcon,
  WhatsappAudioCallIcon,
  WhatsappAudioRecordIcon,
  WhatsappBackIcon,
  WhatsappBubbleTailIcon,
  WhatsappIcon,
  WhatsappVideoCallIcon,
}
