import {IconButton, IconButtonProps} from '@mui/material'
import {makeStyles} from '@mui/styles'

import {CloseIcon} from './icons'

const DialogCloseButton: React.FC<IconButtonProps> = ({className, ...rest}) => (
  <IconButton
    className={`${useStyles().root} ${className ?? ''}`}
    {...rest}
    size="large">
    <CloseIcon />
  </IconButton>
)

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      right: theme.spacing(2),
      top: theme.spacing(3),
    },
  },
}))

export default DialogCloseButton
