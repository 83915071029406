import {
  Box,
  Button,
  Chip,
  ClickAwayListener,
  OutlinedInput,
  Paper,
  Popper,
  Typography,
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {useMemo, useRef, useState} from 'react'
import {RaRecord, useTranslate} from 'react-admin'

import useIsDesktop from '../hooks/useIsDesktop'
import {AddFilledIcon, ChevronDownIcon, CloseFilledIcon, SearchIcon} from './icons'

const MultipleChipSelect = <Choice extends RaRecord, Selection extends RaRecord>({
  canCreateNewChoice = false,
  canEditSelectedChoices = false,
  choices,
  isCreatingChoice = false,
  onCreateNewChoice,
  onDeleteChoice,
  onSelectChoice,
  optionText,
  selectedChoices = [],
  selectionToChoice,
  title,
}: MultipleChipSelectProps<Choice, Selection>) => {
  const styles = useStyles()
  const translate = useTranslate()
  const isDesktop = useIsDesktop()
  const [isPopperOpen, setIsPopperOpen] = useState(false)
  const [choicesFilter, setChoicesFilter] = useState('')
  const anchorElementRef = useRef<HTMLButtonElement>(null)
  const filteredChoices = useMemo(
    () => choices.filter(
      c => (
        optionText(c).toLowerCase().includes(choicesFilter.toLowerCase()) &&
        !selectedChoices.map(s => selectionToChoice(s)?.id).includes(c.id)
      )
    ),
    [choices, choicesFilter, optionText, selectedChoices, selectionToChoice]
  )
  return (
    <div>
      {title && <Typography color="primary" variant="subtitle2">{title}</Typography>}
      <Box className={styles.chipList}>
        {selectedChoices.map(s => (
          <Chip
            className={styles.chip}
            key={s.id}
            label={optionText(selectionToChoice(s))}
            variant="outlined"
            {...(canEditSelectedChoices && onDeleteChoice && {
              deleteIcon: <CloseFilledIcon color="info" />,
              onDelete: () => onDeleteChoice(s),
            })}
          />
        ))}
        {canEditSelectedChoices && (
          <Button
            className={styles.createChoiceButton}
            endIcon={<ChevronDownIcon />}
            onClick={() => setIsPopperOpen(true)}
            ref={anchorElementRef}
            size="small"
            variant="contained"
          >
            {translate('ra.action.add')}
          </Button>
        )}
        <Popper
          anchorEl={anchorElementRef.current}
          className={styles.popper}
          keepMounted={false}
          open={isPopperOpen}
          placement={isDesktop ? 'bottom-start' : 'top-start'}
        >
          <ClickAwayListener
            onClickAway={() => {setIsPopperOpen(false); setChoicesFilter('')}}
          >
            <Paper className={styles.paper} variant="outlined">
              <OutlinedInput
                disabled={isCreatingChoice}
                onChange={e => setChoicesFilter(e.target.value)}
                onKeyDown={e => {
                  if (
                    canCreateNewChoice &&
                    onCreateNewChoice &&
                    (e.key === 'Enter') &&
                    !!choicesFilter &&
                    !choices.map(optionText).includes(choicesFilter)
                  ) {
                    e.preventDefault()
                    onCreateNewChoice(choicesFilter)
                  }
                }}
                placeholder={translate('ra.action.search')}
                value={choicesFilter}
                startAdornment={<SearchIcon color="secondary" size="small" />}
              />
              <Box className={styles.filteredChoices}>
                {canCreateNewChoice && onCreateNewChoice && (
                  <Chip
                    className={styles.createChoiceChip}
                    disabled={(
                      !choicesFilter ||
                      isCreatingChoice ||
                      choices.map(optionText).includes(choicesFilter)
                    )}
                    label={translate('ra.action.create')}
                    onClick={() => onCreateNewChoice(choicesFilter)}
                    variant="outlined"
                  />
                )}
                {filteredChoices.map(c => (
                  <Chip
                    className={styles.chip}
                    deleteIcon={<AddFilledIcon color="info"/>}
                    key={c.id}
                    label={optionText(c)}
                    onDelete={() => onSelectChoice(c)}
                    variant="outlined"
                  />
                ))}
              </Box>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Box>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  chip: {
    '& .MuiChip-deleteIcon:hover': {
      color: theme.palette.info.dark,
    },
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.info.main,
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
  },
  chipList: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.remSpacing(1),
    marginBottom: theme.remSpacing(4),
    marginTop: theme.remSpacing(2),
  },
  createChoiceButton: {
    borderRadius: '16px',
    color: theme.palette.info.contrastText,
    display: 'flex',
    height: theme.remSpacing(4),
  },
  createChoiceChip: {
    '&.MuiChip-root': {
      '&.Mui-disabled': {
        borderColor: theme.palette.disabled.main,
        color: theme.palette.primary.main,
      },
      borderColor: theme.palette.info.main,
      color: theme.palette.info.main,
    },
    marginBottom: theme.remSpacing(2),
    width: '100%',
  },
  filteredChoices: {
    '& .MuiChip-root': {
      '&.Mui-disabled': {
        borderColor: theme.palette.disabled.main,
        color: theme.palette.primary.main,
      },
      '&:not(:last-child)': {
        marginBottom: theme.remSpacing(1),
      },
      borderColor: theme.palette.info.main,
      height: theme.remSpacing(4),
    },
    marginTop: theme.remSpacing(1),
    maxHeight: theme.remSpacing(40),
    overflowY: 'auto',
    scrollbarColor: `${theme.palette.disabled.main} ${theme.palette.background.paper}`,
    scrollbarWidth: 'thin',
  },
  paper: {
    '& .MuiOutlinedInput-root, .MuiOutlinedInput-input': {
      borderRadius: theme.remSpacing(1),
      height: theme.remSpacing(4),
      width: theme.remSpacing(35),
      ...theme.typography.body2,
    },
    borderColor: theme.palette.background.paper,
    borderRadius: theme.remSpacing(1.5),
    boxShadow: `0px 1px 3px ${theme.palette.disabled.main}`,
    boxSizing: 'border-box',
    marginTop: theme.remSpacing(1),
    padding: theme.remSpacing(2),
  },
  popper: {
    zIndex: 1,
  },
}))

interface MultipleChipSelectProps<Choice = unknown, Selection = unknown> {
  canCreateNewChoice?: boolean
  canEditSelectedChoices?: boolean
  choices: Choice[],
  isCreatingChoice?: boolean,
  onCreateNewChoice?: (c: string) => Promise<any>
  onDeleteChoice?: (c: Selection) => Promise<any>
  onSelectChoice: (c: Choice) => Promise<any>
  optionText: (c: Choice) => string
  selectedChoices?: Selection[],
  selectionToChoice: (s: Selection) => Choice,
  title?: string,
}

export default MultipleChipSelect
