import type {} from '@mui/lab/themeAugmentation'
import {createTheme} from '@mui/material/styles'
import {defaultTheme, RaThemeOptions} from 'react-admin'

import {capitalize} from '../utils/strings'
// TODO: Inline the boxShadow definitions directly where used, then delete that file
// TODO: Figure out if this box shadow customization has any valuable effect to the UI,
//       if not, simple remove them!
import boxShadows from './boxShadows'
import breakpoints from './breakpoints'
import hexToRgb from './hexToRgb'
import palette from './palette'
import typography from './typography'

const patchPaletteColorProperty = (component: string) => Object.assign(
  {},
  ...Object.keys(palette).filter(color => !!palette[color].main).map(color => ({
    [`&.${component}-color${capitalize(color)}`]: {
      color: palette[color].main,
    },
  })),
)

const patchPaletteBackgroundColorAndColorProperties = (
  component: string, colorPrefix = '',
) => Object.assign(
  {},
  ...Object.keys(palette).filter(color => !!palette[color].main).map(color => ({
    [`&.${component}-${colorPrefix}${capitalize(color)}`]: {
      backgroundColor: `rgba(${hexToRgb(palette[color].main)}, .1)`,
      color: palette[color].main,
    },
  })),
)

const reactAdminOverrides: RaThemeOptions = {
  components: {
    RaAutocompleteArrayInput: {
      styleOverrides: {
        chipContainerFilled: {
          margin: '4px 4px',
        },
        inputRoot: {
          columnGap: 0,
        },
      },
    },
    RaAutocompleteSuggestionList: {
      styleOverrides: {
        suggestionsContainer: {
          backgroundColor: palette.background.paper,
          boxShadow: `0 0 2rem 0 rgba(${hexToRgb(palette.secondary.main)},.3)`,
        },
      },
    },
    RaBulkActionsToolbar: {
      styleOverrides: {
        root: {
          '& .RaBulkActionsToolbar-title': {
            alignItems: 'center',
          },
          '& .RaBulkActionsToolbar-toolbar': {
            backgroundColor: 'unset',
            padding: '0 14px',
            position: 'relative',
            transform: 'unset',
          },
        },
      },
    },
    RaButton: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-fontSizeMedium': {
            fontSize: '1.5rem',

          },
        },
      },
    },
    RaConfigurable: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    RaCreate: {
      styleOverrides: {
        root: {
          boxSizing: 'content-box',
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
          [breakpoints.up('lg')]: {
            maxWidth: '50%',
          },
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            '&:first-child': {
              paddingRight: '1rem',
              verticalAlign: 'middle',
            },
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          '& .RaDatagrid-headerCell': {
            backgroundColor: palette.info.light,
            top: '-1px',
            verticalAlign: 'middle',
            zIndex: 1000,
          },
          '& .RaDatagrid-headerRow': {
            height: '4rem',
          },
          '& .RaDatagrid-rowCell': {
            '& .MuiTypography-root': {
              '& .MuiLink-root': {
                ...typography.body1,
                color: palette.info.main,
              },
              color: palette.text.primary,
              fontSize: typography.body1.fontSize,
            },
            verticalAlign: 'middle',
          },
          '& .RaDatagrid-table': {
            '& .MuiTableRow-root': {
              '& .MuiTableCell-root': {
                textAlign: 'start',
              },
              '& .MuiTableCell-root:first-child': {
                paddingLeft: '2rem',
                textAlign: 'start',
                [breakpoints.up('lg')]: {
                  paddingLeft: '2.5rem',
                },
              },
              // When the last row cell is also has ".column-editIconButton" class.
              '& .MuiTableCell-root:last-child.column-editIconButton, ': {
                '& > .MuiButtonBase-root': {
                  padding: '4px 5px',
                },
                textAlign: 'end',
              },
              '& .MuiTableCell-root:last-child.column-showIconButton': {
                '& > .MuiButtonBase-root': {
                  padding: '4px 5px',
                },
                textAlign: 'end',
              },
            },
            '& .MuiTableSortLabel-icon': {
              color: `${palette.info.main} !important`,
            },
            boxSizing: 'unset',
            tableLayout: 'fixed',
          },
        },
      },
    },
    RaEdit: {
      styleOverrides: {
        root: {
          '& .RaEdit-noActions': {
            marginTop: 0,
          },
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
        },
      },
    },
    RaEditButton: {
      styleOverrides: {
        root: {
          '& .MuiButton-startIcon': {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            margin: 'auto',
            width: '100%',
          },
        },
      },
    },
    RaEmpty: {
      styleOverrides: {
        root: {
          '& .RaEmpty': {
            '&-icon': {
              display: 'none',
            },
            '&-message': {
              color: palette.text.primary,
              fontSize: '1.125rem',
            },
            '&-toolbar': {
              '& .MuiButtonBase-root': {
                display: 'none',
              },
            },
          },
        },
      },
    },
    RaFilterForm: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
      },
    },
    RaFormInput: {
      styleOverrides: {
        input: {
          width: '100%',
        },
      },
    },
    RaLabeled: {
      styleOverrides: {
        label: {
          color: palette.text.primary,
          transform: 'none',
          ...typography.body1,
        },
        value: {
          padding: 0,
        },
      },
    },
    RaList: {
      styleOverrides: {
        root: {
          '& .RaList-bulkActionsDisplayed': {
            marginTop: 0,
          },
          '& .RaList-main': {
            '& table.MuiTable-root': {
              '& .MuiTableCell-root': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
              tableLayout: 'fixed',
            },
            height: 'fit-content',
          },
          width: '100%',
        },
      },
    },
    RaListToolbar: {
      styleOverrides: {
        root: {
          [breakpoints.down('lg')]: {
            marginLeft: '2rem',
          },
          '& .MuiCardContent-root': {
            paddingBottom: '1rem',
            paddingTop: '1rem',
          },
          backgroundColor: `${palette.background.paper} !important`,
          flexDirection: 'column-reverse',
          margin: '2rem 2.5rem ',
          padding: '0 !important',
          width: 'calc(100% - 5rem)',
        },
      },
    },
    RaLoading: {
      styleOverrides: {
        root: {
          '& .RaLoading-icon': {
            color: palette.disabled.main,
            height: '3rem!important',
            width: '3rem!important',
          },
          '& .RaLoading-message > .MuiTypography-h5': {
            ...typography.h3,
          },
        },
      },
    },
    RaReferenceField: {
      styleOverrides: {
        root: {
          '& .RaReferenceField-link': {
            textDecoration: 'none',
          },
          width: '100%',
        },
      },
    },
    RaSaveButton: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            display: 'none',
          },
        },
      },
    },
    RaSearchInput: {
      styleOverrides: {
        input: {
          marginTop: '8px',
        },
      },
    },
    RaShow: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            color: palette.text.secondary,
            ...typography.caption,
          },
          [breakpoints.up('lg')]: {
            maxWidth: '50%',
          },
          '& .ra-field': {
            marginBottom: '2rem',
          },
          boxSizing: 'content-box',
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
        },
      },
    },
    RaSimpleShowLayout: {
      styleOverrides: {
        root: {
          padding: '8px 0',
        },
      },
    },
    RaSingleFieldList: {
      styleOverrides: {
        root: {
          marginBottom: 'unset',
          marginTop: 'unset',
        },
      },
    },
    RaToolbar: {
      styleOverrides: {
        root: {
          '&.MuiToolbar-root': {
            backgroundColor: palette.background.paper,
            margin: 0,
            padding: 0,
          },
        },
      },
    },
    RaTopToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: `${palette.background.paper} !important`,
          paddingTop: 0,
        },
      },
    },
  },
}

const theme = createTheme({
  ...defaultTheme,
  breakpoints,
  components: {
    ...defaultTheme.components,
    ...reactAdminOverrides.components,
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: palette.background.secondary,
            borderColor: palette.background.secondary,
          },
          border: `1px solid ${palette.background.paper}`,
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: '4rem',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        icon: {
          '& .MuiSvgIcon-root': {
            alignSelf: 'center',
          },
        },
        message: {
          margin: 'auto 0',
        },
        root: {
          ...patchPaletteBackgroundColorAndColorProperties('MuiAlert', 'standard'),
          border: 'solid 1px',
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        slotProps: {
          paper: {
            elevation: 2,
          },
        },
      },
      styleOverrides: {
        option: {
          padding: '0.5rem 1rem!important',
        },
        paper: {
          borderRadius: '0.625rem',
          boxShadow: '7px 7px 25px 0px rgba(0, 0, 0, 0.20)',
        },
        root: {
          width: '100%',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        img: {
          borderRadius: '50%',
          borderStyle: 'none',
          verticalAlign: 'middle',
          width: '100%',
        },
        root: {
          alignItems: 'center',
          backgroundColor: palette.disabled.main,
          borderRadius: '50%',
          color: palette.background.paper,
          display: 'inline-flex',
          fontSize: typography.body2.fontSize,
          height: '48px',
          justifyContent: 'center',
          width: '48px',
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          '&:hover': {
            zIndex: '6!important',
          },
          border: `2px solid ${palette.background.paper}`,
          marginLeft: '-.75rem',
          position: 'relative',
          zIndex: '5!important',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: palette.primary.main,
          border: '0',
          borderRadius: '1rem',
          color: palette.primary.contrastText,
          display: 'inline-block',
          fontSize: '66%',
          fontWeight: 600,
          height: 'unset',
          lineHeight: '1',
          padding: '.35rem .375rem',
          textAlign: 'center',
          textTransform: 'uppercase',
          transition:
            `color .15s ease-in-out,background-color .15s ease-in-out,border-color
            .15s ease-in-out,box-shadow .15s ease-in-out`,
          verticalAlign: 'baseline',
          whiteSpace: 'nowrap',
        },
        colorError: {
          backgroundColor: palette.error.main,
          color: palette.error.contrastText,
        },
        colorPrimary: {
          backgroundColor: palette.primary.main,
          color: palette.primary.contrastText,
        },
        colorSecondary: {
          backgroundColor: palette.secondary.main,
          color: palette.secondary.contrastText,
        },
        dot: {
          position: 'absolute',
          right: 3,
          top: 7,
        },
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          '&:hover': {
            backgroundColor: `${palette.info.dark} !important`,
            borderColor: `${palette.info.dark} !important`,
          },
          backgroundColor: palette.info.main,
          border: `1px solid ${palette.info.main}`,
          boxShadow: 'none !important',
          color: palette.info.contrastText,
        },
        containedPrimary: {
          '&:disabled': {
            borderColor: `${palette.disabled.main}`,
          },
          backgroundColor: palette.info.main,
        },
        // TODO: This previously unused color now styles `error` colored
        //       buttons. Ideally, we would like to use <Button color='error' />
        //       but this is not supported
        containedSecondary: {
          '&:hover': {
            backgroundColor: `${palette.error.dark} !important`,
            borderColor: palette.error.dark,
          },
          backgroundColor: palette.error.main,
          borderColor: palette.error.main,
        },
        containedSizeLarge: {
          borderRadius: '.4375rem',
          padding: '.875rem 1rem',
          ...typography.body2,
        },
        containedSizeSmall: {
          borderRadius: '.375rem',
          padding: '.25rem .5rem',
          ...typography.caption,
        },
        outlined: {
          '&:hover': {
            backgroundColor: palette.info.main,
            boxShadow: 'none',
            color: palette.info.contrastText,
          },
          borderColor: palette.info.main,
          boxShadow: 'none',
          color: palette.info.main,
          padding: '.625rem 1.25rem',
        },
        outlinedPrimary: {
          '&:hover': {
            backgroundColor: palette.info.main,
            borderColor: palette.info.main,
            color: palette.background.paper,
          },
          borderColor: palette.info.main,
          color: palette.info.main,
        },
        outlinedSecondary: {
          '&:hover': {
            backgroundColor: palette.secondary.main,
            borderColor: palette.secondary.main,
            color: palette.primary.main,
          },
          backgroundColor: 'transparent',
          borderColor: palette.secondary.main,
          color: palette.primary.main,
        },
        outlinedSizeLarge: {
          borderRadius: '.4375rem',
          padding: '.875rem 1rem',
          ...typography.body2,
        },
        outlinedSizeSmall: {
          borderRadius: '.375rem',
          padding: '.25rem .5rem',
          ...typography.caption,
        },
        root: {
          '& .MuiButton-label': {
            display: 'flex',
          },
          '& > .MuiButton-label > .MuiSvgIcon-root:not(:last-child)': {
            // Add margin between button icon and label text, if button is not icon-only
            marginRight: '0.5rem',
          },
          '& > .MuiButton-label > span': {
            // Overwrite react-admin's `RaButton` padding-left as the icon already adds a
            // margin-right prop; there's no need to combine both settings. At the end we
            // don't use react-admin's RaButton component very often, that's why gravitate
            // the CSS props on regular material-ui usage and that is why adding a
            // margin-right to button icons is semantically stronger (see above)
            paddingLeft: 0,
          },
          '&.Mui-disabled': {
            '& svg': {
              filter: 'grayscale(1) !important',
              opacity: '.5 !important',
            },
          },
          borderRadius: '.375rem',
          boxShadow: 'none',
          color: palette.primary.main,
          letterSpacing: '.025em',
          margin: '0.3rem',
          padding: '.625rem 1.25rem',
          position: 'relative',
          textAlign: 'center',
          textTransform: 'none',
          transition: 'all .15s ease',
          userSelect: 'none',
          verticalAlign: 'middle',
          willChange: 'transform',
          ...typography.body1,
        },
        startIcon: {
          '& .MuiSvgIcon-root': {
            fontSize: '24px',
          },
        },
        text: {
          padding: '.625rem 1.25rem',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundClip: 'initial',
          backgroundColor: palette.background.paper,
          border: '0',
          borderRadius: '.375rem',
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          overflow: 'unset',
          position: 'relative',
          wordWrap: 'break-word',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          root: {
            '&:last-child': {
              backgroundColor: palette.background.paper,
              borderRadius: '0 0 calc(.375rem - 1px) calc(.375rem - 1px)',
              borderTop:
                `1px solid rgba(${hexToRgb(palette.primary.main)},.05)`,
              padding: '1.25rem 1.5rem',
            },
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          flex: '1 1 auto',
          minHeight: '1px',
          padding: '0',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          '&:first-child': {
            borderRadius: 'calc(.375rem - 1px) calc(.375rem - 1px) 0 0',
          },
          backgroundColor: palette.background.paper,
          borderBottom:
            `1px solid rgba(${hexToRgb(palette.primary.main)},.05)`,
          marginBottom: '0',
          padding: '1.25rem 1.5rem',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            height: '1.5rem',
            width: '1.5rem',
          },
          '&.Mui-checked': {
            color: `${palette.info.main} !important`,
          },
          '&:hover': {
            backgroundColor: 'initial',
            border: 'initial',
            color: palette.secondary.main,
          },
          border: 'initial',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        deleteIcon: {
          '&:hover': {
            color: palette.info.dark,
          },
          color: palette.info.main,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          [breakpoints.up('lg')]: {
            maxWidth: '960px',
          },
        },
        maxWidthMd: {
          [breakpoints.up('md')]: {
            maxWidth: '720px',
          },
        },
        maxWidthSm: {
          [breakpoints.up('sm')]: {
            maxWidth: '540px',
          },
        },
        maxWidthXl: {
          [breakpoints.up('xl')]: {
            maxWidth: '1140px',
          },
        },
        maxWidthXs: {
          [breakpoints.up('sm')]: {
            maxWidth: '540px',
          },
        },
        root: {
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: '15px',
          paddingRight: '15px',
          width: '100%',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: palette.background.paper,
          width: '100%',
          [breakpoints.up('lg')]: {
            maxHeight: 'min(1024px, calc(100% - 64px)) !important',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          borderBottomLeftRadius: '.4375rem',
          borderBottomRightRadius: '.4375rem',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          padding: '1.25rem',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '& .edit-page': {
            paddingLeft: 0,
            paddingRight: 0,
            width: '100%',
          },
          '& .show-page': {
            '& .MuiTableCell-root:first-child': {
              paddingLeft: '0 !important',
            },
            '& .MuiTableCell-root:last-child': {
              textAlign: 'initial',
            },
            maxWidth: 'none',
            paddingLeft: 0,
            paddingRight: 0,
          },
          flex: '1 1 auto',
          padding: '1.5rem',
          position: 'relative',
          [breakpoints.up('lg')]: {
            padding: '1.5rem 2rem',
          },
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          '&:not(:first-child)': {
            paddingTop: '2rem',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '+ .MuiDialogContent-root': {
            paddingTop: '1.5rem',
          },
          ...typography.h3,
          [breakpoints.up('lg')]: {
            paddingLeft: '2rem',
            paddingRight: '2rem',
            paddingTop: '2rem',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: `rgba(${hexToRgb(palette.primary.main)}, 0.1)`,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        docked: {
          width: '250px',
        },
        paper: {
          paddingBottom: '1rem',
          paddingTop: '1rem',
          width: '250px',
        },
        paperAnchorDockedLeft: {
          borderRight: 'none',
          boxShadow: `
            0 0 2rem 0 rgba(${hexToRgb(palette.secondary.main)},.15) !important
          `,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        primary: {
          '&:hover': {
            backgroundColor: palette.info.dark,
          },
          backgroundColor: palette.info.main,
        },
        root: {
          [breakpoints.up('lg')]: {
            bottom: '24px',
          },
          // TODO: Figure out using rem instead of px.
          bottom: '55px',
          margin: 0,
          position: 'absolute',
          right: '20px',
          zIndex: 1000,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        adornedEnd: {
          paddingRight: '.75rem',
        },
        adornedStart: {
          gap: '.625rem',
          paddingLeft: '.75rem',
        },
        input: {
          '&:not(:first-child)': {
            borderBottomLeftRadius: 0,
            borderLeft: 0,
            borderTopLeftRadius: 0,
          },
          border: 0,
          boxShadow: 'none',
          flex: '1 1 auto',
          marginBottom: 0,
          minWidth: 0,
          padding: '.625rem .75rem',
          position: 'relative',
          width: '1%',
        },
        multiline: {
          backgroundColor: palette.background.paper,
          paddingLeft: 0,
        },
        root: {
          '&, &:hover, &.Mui-focused': {
            '&.Mui-disabled': {
              backgroundColor: palette.background.main,
            },
            backgroundColor: palette.background.paper,
          },
          alignItems: 'center',
          border: 0,
          borderRadius: 0,
          boxShadow: boxShadows.inputBoxShadow,
          display: 'flex',
          flexWrap: 'wrap',
          position: 'relative',
          transition: 'box-shadow .15s ease',
          width: '100%',
        },
        underline: {
          '&:after': {
            border: `2px solid ${palette.info.main}`,
            borderBottom: `2px solid ${palette.info.main}`,
            borderRadius: '.375rem',
            height: '100%',
          },
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          lineHeight: 'unset',
        },
        root: {
          // As we don't want input label to be clickable we activate pointerEvents
          // only for input components.
          '& .MuiSwitch-root': {
            pointerEvents: 'auto',
          },
          '&:has(> .MuiSwitch-root)': {
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          marginBottom: '1.5rem',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: palette.text.secondary,
          },
          color: palette.primary.main,
          display: 'inline-block',
          fontSize: '.875rem',
          fontWeight: 600,
          marginBottom: '.5rem',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        container: {
          '& > .MuiDivider-vertical.MuiDivider-flexItem': {
            // Fix broken layouting: https://stackoverflow.com/a/68832210/543875
            marginRight: '-1px',
          },
          marginLeft: '-15px',
          marginRight: '-15px',
          width: 'unset',
        },
        item: {
          paddingLeft: '15px',
          paddingRight: '15px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorSecondary: {
          '&:hover': {
            backgroundColor: palette.info.main,
            border: `solid 2px ${palette.background.paper}`,
            color: palette.background.paper,
          },
          border: `solid 2px ${palette.info.main}`,
          color: palette.info.main,
        },
        root: {
          '&.Mui-disabled': {
            '& .MuiSvgIcon-root': {
              color: palette.disabled.main,
            },
            '&& $label': {
              color: palette.disabled.main,
            },
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        filled: {
          '&.Mui-positionStart': {
            border: '0',
            borderBottomRightRadius: '0',
            borderTopRightRadius: '0',
            boxShadow: 'none',
            marginRight: '-1px',
          },
          display: 'flex',
        },
        positionEnd: {
          paddingLeft: 0,
        },
        positionStart: {
          paddingLeft: 0,
        },
        root: {
          alignItems: 'center',
          backgroundColor: palette.background.paper,
          border: '0',
          color: palette.secondary.main,
          display: 'flex',
          fontSize: '.875rem',
          fontWeight: 400,
          height: 'calc(1.5em + 1.25rem + 2px)',
          lineHeight: '1.5',
          marginBottom: '0',
          marginTop: '0!important',
          padding: '.625rem 0',
          textAlign: 'center',
          transition: 'all .2s cubic-bezier(.68,-.55,.265,1.55)',
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::-webkit-date-and-time-value': {
            paddingLeft: '4px',
            paddingTop: '23px',
            textAlign: 'start',
          },
          '&[type="time"]': {
            // At least on Firefox there's always too much right padding unless set
            // explictly to 0. The original padding led to input text being cut off at the
            // right end of the input. Given that we want time inputs to always show its
            // entire text, having a zero padding has otherwise no negative impact.
            paddingRight: '0 !important',
          },
          backgroundClip: 'padding-box',
          backgroundColor: palette.background.paper,
          border: '1px solid #cad1d7',
          borderRadius: '0',
          boxShadow: 'none',
          boxSizing: 'border-box',
          color: palette.text.primary,
          display: 'block',
          fontSize: typography.body1.fontSize,
          fontWeight: 400,
          height: '100%',
          lineHeight: 1.5,
          padding: '.625rem .75rem !important',
          transition: 'all .2s cubic-bezier(.68,-.55,.265,1.55)',
          width: '100%',
        },
        root: {
          '& .Mui-disabled': {
            backgroundColor: palette.background.main,
            cursor: 'text',
            opacity: '.5 !important',
          },
          minHeight: '3.75rem',
          paddingLeft: '0',
        },
        sizeSmall: {
          marginBottom: 0,
          marginTop: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          transform: 'translate(14px, 15px) scale(1)',
        },
        root: {
          '&.Mui-error, &.Mui-focused': {
            opacity: 1,
          },
          color: palette.text.secondary,
          fontSize: typography.body1.fontSize,
          fontWeight: 400,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar: {
          borderRadius: 0,
          boxShadow: 'none',
          color: palette.background.paper,
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
          justifyContent: 'center',
          overflow: 'hidden',
          textAlign: 'center',
          transition: 'width .6s ease',
          whiteSpace: 'nowrap',
        },
        root: {
          backgroundColor: `${palette.background.secondary}!important`,
          borderRadius: '.25rem',
          boxShadow: boxShadows.linearProgressBoxShadow,
          display: 'flex',
          fontSize: '.75rem',
          height: '8px',
          lineHeight: '0',
          marginBottom: '1rem',
          overflow: 'hidden',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: `${palette.info.main}`,
          cursor: 'pointer',
          fontWeight: 600,
          textDecoration: 'unset',
        },
        underlineAlways: {
          textDecoration: 'underline',
        },
        underlineHover: {
          '& .MuiTypography-root': {
            color: `${palette.info.main}`,
          },
          '&:hover': {
            textDecoration: 'underline',
          },
          padding: 'unset',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        gutters: {
          paddingLeft: '.75rem',
          paddingRight: '.75rem',
        },
        root: {
          '& .Mui-selected': {
            backgroundColor: palette.background.secondary,
          },
          color: palette.primary.main,
          display: 'block',
          paddingBottom: '.25rem',
          paddingTop: '.25rem',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          whiteSpace: 'break-spaces',
          wordBreak: 'break-word',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundClip: 'padding-box',
          backgroundColor: palette.background.paper,
          border: `0 solid rgba(${hexToRgb(palette.primary.main)},.15)`,
          borderRadius: '.4375rem',
          boxShadow: boxShadows.menuBoxShadow,
          color: palette.primary.main,
          fontSize: typography.body1.fontSize,
          listStyle: 'none',
          minWidth: '12rem',
          padding: '.5rem 0',
          textAlign: 'left',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '& i': {
            fontSize: typography.body1.fontSize,
            marginRight: '1rem',
            verticalAlign: '-17%',
          },
          '&, & > .MuiListItemText-root > .MuiListItemText-primary': {
            fontSize: '.875rem',
            fontWeight: 400,
          },
          backgroundColor: 'initial',
          border: 0,
          clear: 'both',
          color: palette.text.primary,
          display: 'flex', // horizontally align subelements such as an icon and text
          padding: '.5rem 1rem',
          textAlign: 'inherit',
          whiteSpace: 'nowrap',
          width: '100%',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        adornedEnd: {
          paddingRight: '.75rem',
        },
        adornedStart: {
          paddingLeft: '.75rem',
        },
        input: {
          '&:not(:last-child)': {
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
            paddingRight: 0,
          },
          borderBottom: '0',
          borderLeft: '0',
          borderRight: '0',
          borderTop: '0',
          color: palette.primary.main,
          padding: '.625rem .75rem',
        },
        multiline: {
          height: '100%',
        },
        notchedOutline: {
          borderColor: palette.disabled.main,
        },
        root: {
          borderRadius: '2px',
          height: 'calc(1.8em + 1.25rem + 2px)',
          paddingRight: '0',
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        ul: {
          gap: '.5rem',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        ellipsis: {
          '&:hover': {
            backgroundColor: 'unset!important',
          },
        },
        root: {
          '&:hover': {
            backgroundColor: palette.disabled.main,
          },
          borderRadius: '50%',
          color: palette.secondary.main,
          fontSize: '.875rem',
          height: '36px',
          width: '36px',
        },
        sizeLarge: {
          height: '46px',
          lineHeight: '46px',
          width: '46px',
        },
        sizeSmall: {
          height: '36px',
          lineHeight: '36px',
          width: '36px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'none',
        },
        root: {
          color: palette.primary.main,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '0.625rem',
          boxShadow: boxShadows.popoverBoxShadow,
          color: palette.primary.main,
          fontSize: '0.875rem',
          padding: '0.5rem 0.95rem',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            height: '1.5rem',
            width: '1.5rem',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: palette.text.secondary,
        },
        root: {
          alignItems: 'center',
          display: 'flex',
          height: '3.75rem !important',
        },
        select: {
          '&.Mui-disabled': {
            backgroundColor: palette.background.main,
          },
          backgroundColor: palette.background.paper,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        rail: {
          backgroundColor: palette.disabled.main,
          borderRadius: '5px',
          boxShadow: boxShadows.sliderBoxShadow,
        },
        root: {
          borderRadius: '5px',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          display: 'flex',
          padding: '0',
        },
        root: {
          border: '1px solid transparent',
          borderRadius: '.375rem',
          fontSize: '.875rem',
          marginBottom: '1rem',
          padding: '1rem 1.5rem',
          position: 'relative',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          ...patchPaletteColorProperty('MuiSvgIcon'),
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            '& + .MuiSwitch-track': {
              backgroundColor: `${palette.info.main}!important`,
              borderColor: palette.info.main,
            },
            '& .MuiSwitch-thumb': {
              backgroundColor: palette.info.main,
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        icon: {
          padding: 0,
        },
        root: {
          [breakpoints.down('xs')]: {
            fontSize: typography.body2.fontSize,
          },
          '&:focus': {
            opacity: 1,
          },
          fontSize: typography.body1.fontSize,
          fontWeight: typography.body1.fontWeight,
          minWidth: 'unset',
          padding: '6px 12px',
          textTransform: 'none',
        },
        textColorInherit: {
          '&.Mui-selected': {
            color: palette.text.primary,
          },
          color: palette.text.secondary,
          opacity: 1,
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: '0',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          borderCollapse: 'collapse',
          color: palette.primary.main,
          marginBottom: '1rem',
          width: '100%',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          borderBottom: `1px solid ${palette.background.default}`,
          borderTop: `1px solid ${palette.background.default}`,
          color: palette.text.primary,
          fontSize: typography.body1.fontSize,
          fontWeight: 600,
          letterSpacing: '1px',
          padding: '1rem',
          paddingBottom: '.75rem',
          paddingTop: '.75rem',
          textTransform: 'capitalize',
          verticalAlign: 'bottom',
          whiteSpace: 'nowrap',
          ...typography.subtitle2,
        },
        paddingCheckbox: {
          '& .MuiButtonBase-root.MuiCheckbox-root.PrivateSwitchBase-root': {
            padding: 0,
          },
          '&.MuiTableCell-root:first-child': {
            paddingRight: '1rem',
          },
          verticalAlign: 'middle',
        },
        root: {
          borderBottom: `1px solid ${palette.background.default}`,
          borderTop: `1px solid ${palette.background.default}`,
          fontSize: '.8125rem',
          verticalAlign: 'top',
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            color: palette.text.secondary,
            fontSize: typography.body1.fontSize,
          },
          display: 'flex',
          justifyContent: 'center',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          '& > span': {
            backgroundColor: palette.primary.main,
            width: '85%',
          },
          backgroundColor: 'transparent',
          display: 'flex',
          justifyContent: 'center',
        },
        root: {
          display: 'flex',
          flexShrink: 0, // Stop cutting off multi-line tab labels
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          marginLeft: '1.5rem',
          marginRight: '1.5rem',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: palette.background.paper,
        },
        tooltip: {
          backgroundColor: palette.background.paper,
          borderRadius: '0.375rem',
          boxShadow: '0px 0.125rem 0.625rem rgba(0, 0, 0, 0.25)',
          color: palette.primary.main,
          fontSize: '0.75rem',
          fontWeight: 400,
          lineHeight: 1.5,
          maxWidth: '360px',
          padding: '0.25rem 0.5rem',
          textAlign: 'left',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        overline: {
          textTransform: 'unset',
        },
        root: {
          '& > *': {
            verticalAlign: 'middle',
          },
          '& > .MuiSvgIcon-root': {
            marginRight: '.5em',
          },
        },
      },
    },
  },
  defaultColorScheme: 'light',
  palette,
  typography,
})

theme.hexToRgb = hexToRgb
theme.remSpacing = spacing => theme.typography.pxToRem(
  Number.parseInt(theme.spacing(spacing).split('px')[0])
)

export type AppTheme = typeof theme
export {breakpoints}

export default theme
