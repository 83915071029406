import {useMutation, useQuery} from '@apollo/client'
import {Button, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import gql from 'graphql-tag'
import {FC} from 'react'
import {useGetOne, useNotify, useTranslate} from 'react-admin'

import useIsChannelAdmin from '../hooks/useIsChannelAdmin'
import useIsOwner from '../hooks/useIsOwner'
import {
  Channels,
  MutationRoot,
  QueryRoot,
  WhatsappAccountConnection,
} from '../types/graphqlSchema'
import {actionErrorCode} from '../utils/errors'
import ExtendedAlert from './ExtendedAlert'

const WhatsappAccountDisconnectionAlert: FC<WhatsappAccountDisconnectionAlertProps> = ({
  channelId,
  className,
}) => {
  const styles = useStyles()
  const isOwner = useIsOwner()
  const isChannelAdmin = useIsChannelAdmin(channelId)
  const canReconnectWhatsappAccount = isOwner || isChannelAdmin
  const translate = useTranslate()
  const {
    data: {whatsappAccountId} = {},
  } = useGetOne<Channels>('channels', {id: channelId})
  const notify = useNotify()
  const {
    data: {
      getWhatsappAccountConnection: {
        status, webhookHostname,
      } = {} as WhatsappAccountConnection,
    } = {},
    error: whatsappAccountConnectionError,
    loading: isLoadingWhatsappAccountConnection,
    refetch: refetchGetWhatsappAccountConnection,
  } = useQuery<QueryRoot['getWhatsappAccountConnection']>(
    GET_WHATSAPP_ACCOUNT_CONNECTION_QUERY,
    {
      skip: !whatsappAccountId,
      variables: {id: whatsappAccountId},
    },
  )
  const [resetWhatsappAccountWebhookUrl] = useMutation<
    MutationRoot['resetWhatsappAccountWebhookUrl']
  >(
    RESET_WHATSAPP_ACCOUNT_WEBHOOK_URL_MUTATION,
    {
      onCompleted: () => {
        notify(
          'dialogs.channelSettings.general.' +
          'whatsappAccountDisconnectionAlert.successMessages.connected'
        )
        refetchGetWhatsappAccountConnection()
      },
      onError: e => notify(
        'dialogs.channelSettings.general.whatsappAccountDisconnectionAlert.' +
        `errorMessages.${actionErrorCode(e)}`,
        {type: 'error'},
      ),
      variables: {whatsappAccountId},
    },
  )
  if (
    isLoadingWhatsappAccountConnection ||
    whatsappAccountConnectionError ||
    [undefined, 'CONNECTED'].includes(status)
  ) return null
  return (
    <ExtendedAlert className={`${styles.root} ${className ?? ''}`} severity="warning">
      <Typography paragraph variant="caption">
        {status === 'NOT_READY_YET' && translate(
          'dialogs.channelSettings.general.whatsappAccountDisconnectionAlert.' +
          'message.notReady'
        )}
        {['CONNECTED_TO_DIFFERENT_ENVIRONMENT', 'DISCONNECTED'].includes(status) &&
          translate(
            'dialogs.channelSettings.general.whatsappAccountDisconnectionAlert.' +
            'message.disconnected'
          )
        }
      </Typography>
      {['CONNECTED_TO_DIFFERENT_ENVIRONMENT', 'DISCONNECTED'].includes(status) && (
        <>
          {(
            (window.location.hostname === 'localhost') ||
            window.location.hostname.endsWith('.review.flinkit.de')
          ) && (
            <Typography variant="caption">
              {translate(
                'dialogs.channelSettings.general.whatsappAccountDisconnectionAlert.' +
                'currentConnection',
                {webhookHostname}
              )}
            </Typography>
          )}
          <Button
            disabled={!canReconnectWhatsappAccount}
            fullWidth
            onClick={() => resetWhatsappAccountWebhookUrl()}
            variant={canReconnectWhatsappAccount ? 'contained' : 'outlined'}
          >
            {translate('dialogs.channelSettings.general.' +
              'whatsappAccountDisconnectionAlert.connectAction')}
          </Button>
        </>
      )}
    </ExtendedAlert>
  )
}

const GET_WHATSAPP_ACCOUNT_CONNECTION_QUERY = gql`query($id: uuid!) {
  getWhatsappAccountConnection(whatsappAccountId: $id){status webhookHostname}
}`
const RESET_WHATSAPP_ACCOUNT_WEBHOOK_URL_MUTATION = gql`
  mutation($whatsappAccountId: uuid!){
    resetWhatsappAccountWebhookUrl(whatsappAccountId: $whatsappAccountId)
  }
`
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiButtonBase-root': {
      margin: 0,
      marginTop: theme.remSpacing(1),
    },
    paddingBottom: 0,
    paddingLeft: theme.remSpacing(1),
    paddingTop: 0,
  },
}))

interface WhatsappAccountDisconnectionAlertProps {
  channelId: string
  className?: string
}

export default WhatsappAccountDisconnectionAlert
