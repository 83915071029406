import {useMutation} from '@apollo/client'
import {
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import {makeStyles} from '@mui/styles'
import gql from 'graphql-tag'
import {FC, useCallback, useContext, useMemo, useState} from 'react'
import {
  List,
  SimpleList,
  useGetIdentity,
  useLogout,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'

import {SandboxContext} from '../../contexts/SandboxContext'
import useHasPermission from '../../hooks/useHasPermission'
import useIsDesktop from '../../hooks/useIsDesktop'
import useSessionMerchantUser from '../../hooks/useSessionMerchantUser'
import {MerchantsCreate} from '../../resources/merchantsResource'
import {MerchantUserCreate, MerchantUserEdit} from '../../resources/merchantUsersResource'
import OrganizationMerchantUsersList
  from '../../resources/organizationMerchantUsersResource'
import theme from '../../theme'
import {MerchantUsers, MutationRoot, WhatsappAccounts} from '../../types/graphqlSchema'
import {isNativeMobileApp} from '../../utils/platform'
import Accordion from '../Accordion'
import AccordionWrapper from '../AccordionWrapper'
import DialogCloseButton from '../DialogCloseButton'
import ExtendedAlert from '../ExtendedAlert'
import ExtendedDialog from '../ExtendedDialog'
import FileUploadButton from '../FileUploadButton'
import {ChevronDownIcon, DeleteIcon, LogoutIcon} from '../icons'
import ProfileInitials from '../settings/ProfileInitials'
import TabPanel from '../TabPanel'
import ChannelsAndMerchantUsersManagementShow
  from './ChannelsAndMerchantUsersManagementShow'
import SandboxSettings from './SandboxSettings'
import ThirdPartyConnections, {
  ThreeSixtyConnectionButton, VonageConnectionButton,
} from './ThirdPartyConnections'

const ProfileDialog: FC<ProfileDialogProps> = ({initialTabIndex = 0, onClose, open}) => {
  const styles = useStyles()
  const {merchantUser = {} as MerchantUsers} = useSessionMerchantUser()
  const [tabIndex, setTabIndex] = useState(initialTabIndex)
  const tabPanelLabelAndComponents = useTabPanelLabelAndComponents()
  return (
    <ExtendedDialog
      className={styles.root}
      disableEnforceFocus
      id="profile"
      keepMounted={false}
      maxWidth="md"
      onClose={onClose}
      open={open}
      // To allow inner ClickAwayListener to work.
      onClick={undefined}
    >
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>{useTranslate()('dialogs.profile.title')}</DialogTitle>
      {tabPanelLabelAndComponents.length > 1 && (
        <Tabs
          TabIndicatorProps={{children: <span/>}}
          indicatorColor="primary"
          onChange={(_, newTabIndex: number) => {
            setTabIndex(newTabIndex)
          }}
          textColor="primary"
          value={tabIndex}
          variant="fullWidth"
        >
          {tabPanelLabelAndComponents.map(([label], i) => (
            <Tab key={i} label={label} />
          ))}
        </Tabs>
      )}
      <DialogContent>
        {tabPanelLabelAndComponents.map(([, TabPanelComponent], i) => (
          <TabPanel dir={theme.direction} index={i} key={i} value={tabIndex}>
            <TabPanelComponent merchantUser={merchantUser} />
          </TabPanel>
        ))}
      </DialogContent>
    </ExtendedDialog>
  )
}

const MerchantUserSettings: FC<TabPanelProps> = ({merchantUser}) => {
  const logout = useLogout()
  const translate = useTranslate()
  const styles = useStyles()
  const isDesktop = useIsDesktop()
  if (!merchantUser) return null // <Edit> component raises an error if `id` is unset
  return (
    <>
      <ProfileInitials
        backgroundColor={merchantUser.userColor}
        className={styles.profileInitials}
        name={`${merchantUser.firstName} ${merchantUser.lastName}`}
      />
      <MerchantUserEdit id={merchantUser.id} />
      <ThirdPartyConnections />
      <Divider className={styles.divider} />
      <Toolbar className={styles.toolbar} disableGutters>
        <Grid container>
          {!isDesktop && (
            <Button
              color="primary"
              onClick={logout}
              startIcon={<LogoutIcon />}
              variant="contained"
            >
              {translate('actions.logout')}
            </Button>
          )}
          <UserAccountDeletionButton />
        </Grid>
      </Toolbar>
    </>
  )
}

const UserAccountDeletionButton = () => {
  const {identity: {id} = {}} = useGetIdentity()
  const translate = useTranslate()
  const logout = useLogout()
  const [deleteUserAccount] = useMutation(
    USER_ACCOUNT_DELETION_MUTATION,
    {
      onCompleted: () => logout(),
      onError: e => alert(translate(
        'dialogs.profile.merchantUsersSettings.accountDeletionButtonError',
        {message: e.message}
      )),
      variables: {id},
    }
  )
  return (
    <Button
      color="secondary"
      disabled={!id}
      onClick={() => {
        if (window.confirm(translate(
          'dialogs.profile.merchantUsersSettings.accountDeletionConfirm'
        ))) {
          deleteUserAccount()
        }
      }}
      startIcon={<DeleteIcon color="background" />}
      variant="contained"
    >
      {translate('dialogs.profile.merchantUsersSettings.accountDeletionButton')}
    </Button>
  )
}

const CompanySettings: FC<TabPanelProps> = () => {
  const styles = useStyles()
  const translate = useTranslate()
  return (
    <>
      <ExtendedAlert className={styles.companySettingsAlert} severity="info">
        <Link
          href={ONBOARDING_RESOURCE_URL}
          {...(!isNativeMobileApp && {rel: 'noreferer', target: '_blank'})}
          underline="hover">
          {useTranslate()('dialogs.profile.companySettings.faq.linkText')}
        </Link>
        {' '}{useTranslate()('dialogs.profile.companySettings.faq.text')}
      </ExtendedAlert>
      <AccordionWrapper className={styles.companySettingsRoot}>
        <Accordion elevation={2} name="create-channels">
          <AccordionSummary expandIcon={<ChevronDownIcon />}>
            <Typography>
              {useTranslate()('dialogs.profile.companySettings.createNumber.title')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ThreeSixtyConnectionButton />
            <VonageConnectionButton />
            <Typography variant="subtitle2">
              {useTranslate()(
                'dialogs.profile.companySettings.createNumber.numbersAlreadyCreated'
              )}
            </Typography>
            <List
              actions={false}
              empty={
                <Typography>
                  {translate('dialogs.profile.companySettings.createNumber.emptyList')}
                </Typography>
              }
              exporter={false}
              pagination={false}
              resource="whatsapp_accounts"
            >
              <SimpleList<WhatsappAccounts>
                linkType={false}
                primaryText={record => record.phoneNumber}
                rowStyle={() => ({paddingBottom: 0, paddingLeft: 0, paddingTop: 0})}
              />
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={2} name="create-merchant-users">
          <AccordionSummary expandIcon={<ChevronDownIcon />}>
            <Typography>
              {translate(
                'dialogs.profile.companySettings.addMerchantUser.title'
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MerchantUsersImportButton />
            <MerchantUserCreate />
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={2} name="create-channel-merchant-users">
          <AccordionSummary expandIcon={<ChevronDownIcon />}>
            <Typography>
              {translate(
                'dialogs.profile.companySettings.channelsAndUsers.title'
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ChannelsAndMerchantUsersManagementShow />
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={2} name="create-merchants">
          <AccordionSummary expandIcon={<ChevronDownIcon />}>
            <Typography>
              {translate('dialogs.profile.companySettings.location.title')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {translate('dialogs.profile.companySettings.location.subtitle')}
            </Typography>
            <MerchantsCreate />
            <OrganizationMerchantUsersList />
          </AccordionDetails>
        </Accordion>
      </AccordionWrapper>
    </>
  )
}

const useTabPanelLabelAndComponents = (): ComponentLabelAndComponent<TabPanelProps> => {
  const translate = useTranslate()
  const {isSandbox} = useContext(SandboxContext)
  const canEditMerchant = useHasPermission('edit', 'merchants')
  return useMemo(
    () => [
      [translate('dialogs.profile.merchantUsersSettings.title'), MerchantUserSettings],
      canEditMerchant && [
        translate('dialogs.profile.companySettings.title'), CompanySettings,
      ],
      isSandbox && [translate('dialogs.profile.sandbox.title'), SandboxSettings],
    ].filter(Boolean) as ComponentLabelAndComponent<TabPanelProps>,
    [canEditMerchant, isSandbox, translate],
  )
}

const MerchantUsersImportButton = () => {
  const notify = useNotify()
  const refresh = useRefresh()
  const translate = useTranslate()
  const [
    importMerchantUsers,
    {loading: isImportingContactFilters},
  ] = useMutation<MutationRoot['importMerchantUsers']>(
    MERCHANT_USERS_IMPORT_MUTATION,
    {
      onCompleted: ({importMerchantUsers}) => {
        refresh()
        notify(
          'resources.merchant_users.import.success',
          {
            messageArgs: {importMerchantUsersCount: importMerchantUsers.length},
            type: 'success',
          },
        )
      },
      onError: e => {
        notify(translate(e.message), {type: 'error'})
      },
    }
  )
  const importMerchantUsersCsv = useCallback(([file]: FileAttachment[]) => {
    if (window.confirm(
      translate('resources.merchant_users.import.confirm', {filename: file.filename})
    )) {
      importMerchantUsers({variables: {csvBase64Content: file.encodedContent}})
    }
  }, [importMerchantUsers, translate])
  return (
    <FileUploadButton
      loading={isImportingContactFilters}
      mimeTypes={['text/csv']}
      onSelectFiles={importMerchantUsersCsv}
    >
      {translate('resources.merchant_users.import.button')}
    </FileUploadButton>
  )
}

// TODO: In the user we want to be compliant with the way how user information needs to be
//       obfuscated once a user signs off. There should probably be a postgres
//       after-update trigger function or a cron job which does the obfuscation.
const USER_ACCOUNT_DELETION_MUTATION = gql`
  mutation($id: uuid!){
    update_users_by_pk(
      _set: {isDeleted: true}
      pk_columns: {id: $id}
    ){id}
  }
`

const MERCHANT_USERS_IMPORT_MUTATION = gql`
  mutation($csvBase64Content: String!){
    importMerchantUsers(csvBase64Content: $csvBase64Content){
      merchantUser{id}
    }
  }
`

const ONBOARDING_RESOURCE_URL = 'https://flinkit.notion.site/Champion-0c225990dbb14431be3cd155a6f1beb3'

const useStyles = makeStyles(theme => ({
  companySettingsAlert: {
    marginBottom: theme.remSpacing(2),
  },
  companySettingsRoot: {
    '& .MuiAccordionDetails-root': {
      display: 'block',
    },
    '& .MuiList-padding': {
      padding: `${theme.remSpacing(.2)} 0`,
    },
    '& .MuiTypography-root': {
      marginLeft: theme.remSpacing(.4),
    },
    '& .MuiTypography-subtitle2': {
      marginBottom: theme.remSpacing(1),
      marginLeft: theme.remSpacing(.4),
      marginTop: theme.remSpacing(2),
    },
  },
  divider: {
    marginBottom: theme.remSpacing(5),
    marginTop: theme.remSpacing(5),
  },
  profileInitials: {
    color: theme.palette.primary.contrastText,
  },
  root: {
    [theme.breakpoints.up('lg')]: {
      '& .MuiDialog-paper': {
        position: 'fixed',
        top: theme.typography.pxToRem(5),
      },
      '& .MuiDialogContent-root': {
        padding: `${theme.remSpacing(0.8)} ${theme.remSpacing(2)}`,
      },
    },
  },
  thirdPartyConnectionsButtons: {
    // TODO: Move this style to the global theme
    '& .MuiButton-root': {
      [theme.breakpoints.down('lg')]: {
        fontSize: theme.typography.button.fontSize,
      },
    },
  },
  thirdPartyConnectionsTitle: {
    padding: `${theme.remSpacing(1)} 0`,
  },
  toolbar: {
    '& .MuiGrid-root': {
      flexDirection: 'row-reverse',
    },
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'end',
  },
}))

interface ProfileDialogProps {
  initialTabIndex?: number,
  onClose: () => void
  open: boolean
}

interface TabPanelProps {
  merchantUser: MerchantUsers | undefined,
}

export default ProfileDialog
