import {useQuery} from '@apollo/client'
import gql from 'graphql-tag'
import {useCallback, useMemo} from 'react'
import {useTranslate} from 'react-admin'

import {ChatMessages, QueryRoot} from '../types/graphqlSchema'
import {useUserDisplayName} from './useUserDisplayName'

const useCompileChatMessageText = () => {
  const translate = useTranslate()
  const userDisplayName = useUserDisplayName()
  const {
    data: {
      chat_message_template_placeholder_types:
      chatMessageTemplatePlaceholderTypes = [],
    } = {},
  } = useQuery<QueryRoot['chat_message_template_placeholder_types']>(
    CHAT_MESSAGE_TEMPLATE_PLACEHOLDER_TYPES_QUERY,
  )
  const placeholderTypeToLabel: PlaceholderTypeToLabel = useMemo(() => (
    chatMessageTemplatePlaceholderTypes?.reduce(
      (typeToTranslation, placeholder) => ({
        ...typeToTranslation,
        [placeholder.type]: translate(
            `chat_message_template_placeholder_types.${placeholder.type}`
        ),
      }),
      {}
    ) ?? {}
  ), [chatMessageTemplatePlaceholderTypes, translate])
  const compileTemplateText = useCallback(
    (chatMessage: Partial<ChatMessages> | undefined) =>
      chatMessage?.chatMessageTemplateValues?.reduce?.(
        (compiledText, {chatMessageTemplatePlaceholder: {index, type}, value}) => (
          // Only replace the placeholder if `value` isn't `undefined/null or `""`. The
          // latter is the case for template messages where the user didn't enter all
          // values yet before inserting the template message.
          !!value ?
            compiledText
              ?.replaceAll?.(`{{${index}}}`, value)
              // Replacing smart placeholders with their corresponding values in order
              // to show the value instead of the smart placeholder
              ?.replaceAll?.(`{{%${type}%}}`, value) :
            compiledText
        ),
        chatMessage.chatMessageTemplate?.text
      ),
    []
  )
  const compileChatMessageText = useCallback(
    (chatMessage?: Partial<ChatMessages>): string|undefined => {
      let filledPlaceholderCompiledText: string | undefined
      if (chatMessage?.text) filledPlaceholderCompiledText = chatMessage?.text
      else if (chatMessage?.reactedChatMessage) {
        filledPlaceholderCompiledText = chatMessage?.reactedChatMessage ?
          translate(
            'chat.compileChatMessageText.emojiReactionText',
            {
              emoji: chatMessage.reactionEmoji,
              merchantCustomerUserName:
                userDisplayName({customerUser: chatMessage.authorUser?.customerUser}),
              messageText: compileTemplateText(chatMessage.reactedChatMessage),
            },
          ) : undefined
      }
      else filledPlaceholderCompiledText = compileTemplateText(chatMessage)
      return chatMessage?.chatMessageTemplate?.chatMessageTemplatePlaceholders?.filter(
        p => !!p.type
      ).reduce(
        (compiledText, {index, type}) => (
          compiledText
            ?.replaceAll?.(`{{${index}}}`, `{{%${placeholderTypeToLabel[type!]}%}}`)
        ), filledPlaceholderCompiledText
      ) ?? filledPlaceholderCompiledText
    },
    [compileTemplateText, placeholderTypeToLabel, translate, userDisplayName]
  )
  return {compileChatMessageText, placeholderTypeToLabel}
}


const CHAT_MESSAGE_TEMPLATE_PLACEHOLDER_TYPES_QUERY = gql`
  query{chat_message_template_placeholder_types{type}}
`

interface PlaceholderTypeToLabel {
  [type_: string]: string;
}

export {useCompileChatMessageText}
