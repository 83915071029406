import {Button} from '@mui/material'
import {useState} from 'react'
import {
  useDeleteMany,
  useListContext,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'

import ConfirmationDialog from './ConfirmationDialog'
import {DeleteIcon} from './icons'

const BulkDeleteButton = () => {
  const {onUnselectItems, resource, selectedIds} = useListContext()
  const refresh = useRefresh()
  const [isOpen, setIsOpen] = useState(false)
  const notify = useNotify()
  const translate = useTranslate()
  const [deleteMany] = useDeleteMany(
    resource,
    {ids: selectedIds},
    {
      onSuccess() {
        onUnselectItems()
        setIsOpen(false)
        refresh()
        notify(
          'ra.notification.deleted',
          {messageArgs: {smart_count: selectedIds.length}, type: 'info'},
        )
      },
    }
  )
  const translateOptions = {
    name: translate(`resources.${resource}.name`, {_: 'items'}).toLowerCase(),
    smart_count: selectedIds.length,
  }
  return (
    <>
      <Button
        color="secondary"
        onClick={() => setIsOpen(true)}
        startIcon={<DeleteIcon color="background" />}
        variant="contained"
      >
        {translate('actions.delete')}
      </Button>
      <ConfirmationDialog
        confirmationText={translate('actions.confirm')}
        onCancel={() => setIsOpen(false)}
        onConfirm={() => deleteMany()}
        open={isOpen}
        title={translate('ra.message.bulk_delete_title', translateOptions)}
      >
        {translate('ra.message.bulk_delete_content', translateOptions)}
      </ConfirmationDialog>
    </>
  )
}

export default BulkDeleteButton
