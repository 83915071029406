import {FilePicker, PickFilesResult} from '@capawesome/capacitor-file-picker'
import {Folder, PhotoLibrary} from '@mui/icons-material'
import {
  Button,
  ClickAwayListener,
  Paper,
  Popper,
  Typography,
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {FC, MutableRefObject, useCallback, useMemo} from 'react'
import {useNotify, useTranslate} from 'react-admin'

import {arrayBufferToHex, base64ToArrayBuffer} from '../utils/buffers'
import {THREE_SIXTY_CLOUD_API_MAX_FILE_SIZE_BYTES} from '../utils/consts'

const MobileFilePickerPopper: FC<MobileFilePickerPopperProps> = ({
  anchorElementRef,
  encoding = 'hex',
  isOpen = false,
  mimeTypes,
  multiple = false,
  onClose,
  onSelectFiles,
  shouldCheckForThreeSixtyCloudApiMaxFileSize = false,
}) => {
  const styles = useStyles()
  const notify = useNotify()
  const translate = useTranslate()
  const fileMimeTypes = useMemo(
    () => Array.isArray(mimeTypes) ? mimeTypes : mimeTypes.split(','),
    [mimeTypes],
  )
  const onPickFiles = useCallback(
    ({files}: PickFilesResult) => {
      const fileAttachments = files.reduce(
        (attachments: FileAttachment[], file) => {
          if (
            shouldCheckForThreeSixtyCloudApiMaxFileSize &&
            (file.size > THREE_SIXTY_CLOUD_API_MAX_FILE_SIZE_BYTES)
          ) {
            notify(
              'validations.files.maxSize',
              {
                messageArgs: {
                  fileSize: (file.size / 1_048_576).toFixed(2),
                  filename: file.name,
                  maxSize: THREE_SIXTY_CLOUD_API_MAX_FILE_SIZE_BYTES / 1_048_576,
                },
                type: 'error',
              }
            )
            return attachments
          }
          if (!fileMimeTypes.includes(file.mimeType)) {
            notify(
              'validations.files.unsupportedFileType',
              {messageArgs: {type: file.mimeType}, type: 'error'},
            )
            return attachments
          }
          const arrayBuffer = base64ToArrayBuffer(file.data as string)
          const attachment = {
            encodedContent: encoding === 'hex' ?
              arrayBufferToHex(arrayBuffer) : file.data as string,
            file: new File(
              [arrayBuffer],
              file.name,
              {type: file.mimeType},
            ),
            filename: file.name,
            mimeType: file.mimeType,
          }
          return [...attachments, attachment]
        },
        [],
      )
      onSelectFiles(fileAttachments)
    },
    [
      encoding,
      onSelectFiles,
      fileMimeTypes,
      notify,
      shouldCheckForThreeSixtyCloudApiMaxFileSize,
    ]
  )
  return (
    <Popper
      anchorEl={anchorElementRef.current}
      modifiers={[{enabled: true, name: 'flip'}]}
      open={isOpen}
      placement="bottom-start"
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper className={styles.root} elevation={20} onClick={e => e.stopPropagation()}>
          <Button
            className={styles.option}
            onClick={e => {
              e.stopPropagation()
              onClose()
              FilePicker.pickFiles({
                multiple: multiple, readData: true, types: fileMimeTypes,
              })
                .then(onPickFiles)
                .catch(error => {
                  if (error.message.includes('pickFiles canceled')) return
                  throw error
                })
            }}
            variant="text"
          >
            <Folder color="action" />
            <Typography>
              {translate('chat.toolbar.fileAttachment.selectFromFiles')}
            </Typography>
          </Button>
          <Button
            className={styles.option}
            onClick={e => {
              e.stopPropagation()
              onClose()
              FilePicker.pickMedia({multiple: multiple, readData: true})
                .then(onPickFiles)
                .catch(error => {
                  if (error.message.includes('pickFiles canceled')) return
                  throw error
                })
            }}
            variant="text"
          >
            <PhotoLibrary color="action" />
            <Typography>
              {translate('chat.toolbar.fileAttachment.selectFromGallery')}
            </Typography>
          </Button>
        </Paper>
      </ClickAwayListener>
    </Popper>
  )
}

const useStyles = makeStyles(theme => ({
  option: {
    '& .MuiButton-label': {
      gap: theme.remSpacing(1),
      justifyContent: 'start',
    },
    '&.MuiButton-text': {
      padding: theme.remSpacing(1),
    },
    display: 'flex',
    flexGrow: 1,
    gap: theme.remSpacing(2),
  },
  root: {
    '&.MuiPaper-rounded': {
      borderRadius: theme.remSpacing(2),
    },
    alignItems: 'stretch',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.remSpacing(1),
  },
}))

interface MobileFilePickerPopperProps {
  anchorElementRef: MutableRefObject<HTMLDivElement | undefined>,
  encoding?: 'base64'|'hex'
  isOpen?: boolean
  mimeTypes: string | string[]
  multiple?: boolean
  onClose: () => void
  onSelectFiles: (files: FileAttachment[]) => void
  shouldCheckForThreeSixtyCloudApiMaxFileSize?: boolean
}

export default MobileFilePickerPopper
