import {words} from 'lodash-es'

const capitalize = s => s && s[0].toUpperCase() + s.slice(1)

const initials = (text: string) =>
  words(text).slice(0, 2).map(w => w[0]).join('').toUpperCase()

// Adopted from https://stackoverflow.com/a/57863619/13474554
const snakeToCamel = s => s.replace(/(_\w)/g, k => k[1].toUpperCase())

export {capitalize, initials, snakeToCamel}
