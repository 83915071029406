import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const useIsDesktop = () => {
  const theme = useTheme()
  // `noSsr` is used to allow `useMediaQuery` to resolve the correct
  // value on the first render.
  // See: https://mui.com/material-ui/react-use-media-query/#client-side-only-rendering
  return useMediaQuery(theme.breakpoints.up('lg'), {noSsr: true})
}

export default useIsDesktop
