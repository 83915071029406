import {Grid2, Toolbar, Tooltip, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {FC, ReactNode} from 'react'
import {Link, useTranslate} from 'react-admin'
import {useMatch} from 'react-router-dom'

import {AppTheme} from '../../theme'
import BackNavigationLink from '../BackNavigationLink'
import DisplayBox from '../DisplayBox'
import {BlockIcon} from '../icons'

const ChatHeader: FC<ChatHeaderProps> = ({
  children,
  isSpam = false,
  redirectTo,
  subtitle,
  title,
}) => {
  const styles = useStyles({isSpam})
  return (
    <Toolbar className={styles.root}>
      <DisplayBox desktop={false} mobile={true}>
        <BackNavigationLink
          className={styles.backNavigationLink}
          fallbackPath={useMatch('/inbox/:channelId')?.pathname ?? '/team-chat'}
        />
      </DisplayBox>
      <Grid2
        className={styles.titleGrid}
        // @ts-ignore: "component" props not found
        component={redirectTo ? Link : undefined}
        to={redirectTo}
        underline="none"
      >
        <Typography
          className={styles.title}
          color="textPrimary"
          variant="subtitle2"
        >
          {title}
          <Tooltip
            arrow
            title={useTranslate()('chat.external.header.blockedNumberTooltip')}
          >
            <span>
              {isSpam && <BlockIcon className={styles.blockIcon} color="background" />}
            </span>
          </Tooltip>
        </Typography>
        <Typography className={styles.subtitle} color="textSecondary" variant="caption">
          {subtitle}
        </Typography>
      </Grid2>
      {children}
    </Toolbar>
  )
}

const useStyles = makeStyles<AppTheme, MakeStylesOptions>(theme => {
  const textEllipsis = {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap' as any,
  }
  return {
    backNavigationLink: {
      zIndex: 1002,
    },
    backNavigationLinkGrid: {
      paddingLeft: 0,
    },
    blockIcon: {
      marginLeft: theme.remSpacing(1),
    },
    root: {
      alignItems: 'center',
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.disabled.main,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      marginLeft: 0,
      marginRight: 0,
      paddingRight: 0,
    },
    subtitle: {
      ...textEllipsis,
    },
    title: {
      ...textEllipsis,
    },
    titleGrid: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.remSpacing(2.5),
        marginTop: theme.remSpacing(3),
        paddingLeft: theme.remSpacing(1),
      },
      flexGrow: 1,
      marginBottom: theme.remSpacing(3),
      marginTop: theme.remSpacing(3),
      minWidth: theme.typography.pxToRem(140),
      paddingLeft: 0,
    },
  }
})

interface ChatHeaderProps {
  children?: ReactNode
  isSpam?: boolean
  redirectTo?: string
  subtitle?: ReactNode
  title: ReactNode
}

interface MakeStylesOptions {
  isSpam: boolean
}

export default ChatHeader
