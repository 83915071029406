import {Box, Snackbar, ThemeProvider, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {useCallback, useEffect, useState} from 'react'
import {NotificationPayload, useNotificationContext, useTranslate} from 'react-admin'

import useIsDesktop from '../hooks/useIsDesktop'
import theme from '../theme'
import {ErrorIcon, InfoIcon, NotificationCheckIcon, WarningIcon} from './icons'

/**
 * Notifications component, adapted from react admin's implementation so that we can
 * customize the exact look of the snackbar's contents.
 * Refer to the actual react-admin implementation under:
 *    /node_modules/react-admin/node_modules/ra-ui-materialui/src/layout/Notification.tsx
 */
const ExtendedNotification = () => {
  const styles = useStyles()
  const isDesktop = useIsDesktop()
  const {notifications, takeNotification} = useNotificationContext()
  const [open, setOpen] = useState(false)
  const [notification, setNotification] = useState<NotificationPayload | undefined>()
  const translate = useTranslate()
  const handleRequestClose = useCallback(() => setOpen(false), [setOpen])
  const handleExited = useCallback(() => setNotification(undefined), [])
  useEffect(() => {
    if (notifications.length && !notification) {
      // Set a new snack when we don't have an active one
      const currentNotification = takeNotification()
      if (currentNotification) {
        setNotification(currentNotification)
        setOpen(true)
      }
    }
    else if (notifications.length && notification && open) {
      // Close an active snack when a new one is added
      setOpen(false)
    }
    const beforeunload = (e: BeforeUnloadEvent) => {
      e.preventDefault()
      const confirmationMessage = ''
      e.returnValue = confirmationMessage
      return confirmationMessage
    }
    if (notification?.notificationOptions?.undoable) {
      window.addEventListener('beforeunload', beforeunload)
      return () => {
        window.removeEventListener('beforeunload', beforeunload)
      }
    }
  }, [notifications, notification, open, takeNotification])
  if (!notification) return null
  const {message, notificationOptions} = notification
  const Icon = NOTIFICATION_TYPE_TO_ICON[notification.type as string]
  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={open}
        autoHideDuration={notificationOptions?.autoHideDuration ?? 5000}
        TransitionProps={{onExited: handleExited}}
        onClose={handleRequestClose}
        anchorOrigin={{horizontal: 'center', vertical: isDesktop ? 'bottom' : 'top'}}
      >
        <Box
          alignItems="center"
          className={styles.root}
          sx={theme => ({
            backgroundColor: theme.palette[notification.type].light,
            border: `1px solid ${theme.palette[notification.type].main}`,
          })}
          display="flex"
          gap="0.5rem"
        >
          <Icon color={notification.type} />
          <Typography variant="caption">
            {translate(message as string, notificationOptions?.messageArgs)}
          </Typography>
        </Box>
      </Snackbar>
    </ThemeProvider>
  )
}

const NOTIFICATION_TYPE_TO_ICON = {
  error: ErrorIcon,
  info: InfoIcon,
  success: NotificationCheckIcon,
  warning: WarningIcon,
}

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '10px',
    color: theme.palette.primary.main,
    marginTop: 'var(--safe-area-top-inset)',
    padding: theme.remSpacing(2),
    width: theme.remSpacing(55),
  },
}))

export default ExtendedNotification
