import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {FC} from 'react'
import {useTranslate} from 'react-admin'

import {TranslationIcon} from '../../icons'

const ChatMessageTranslationBanner: FC<ChatMessageTranslationBannerProps> = ({
  detectedLanguage, translatedLanguages,
}) => {
  const styles = useStyles()
  const translate = useTranslate()
  const bannerMessage = detectedLanguage ?
    translate(
      'chat.chatMessageBubble.translationBanner.translatedToDetectedLanguage',
      {language: translate(`languages.${detectedLanguage.split('-')[0]}`)}
    ) :
    translate(
      'chat.chatMessageBubble.translationBanner.translatedToTranslatedLanguages',
      {languages: translatedLanguages.map(l => translate(`languages.${l}`)).join(", ")}
    )
  return (
    <div className={styles.root}>
      <TranslationIcon className={styles.icon} size="small" />
      <Typography color="textSecondary" variant="body2">
        {bannerMessage}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.remSpacing(0.5),
  },
  root: {
    '& .MuiTypography-root': {
      display: 'inline-flex',
    },
    alignItems: 'center',
    display: 'flex',
  },
}))

interface ChatMessageTranslationBannerProps {
  detectedLanguage?: string
  translatedLanguages: string[]
}

export default ChatMessageTranslationBanner
