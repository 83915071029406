import {Box} from '@mui/material'
import {FC, ReactNode} from 'react'

import useIsDesktop from '../hooks/useIsDesktop'

const TabPanel: FC<TabPanelProps> = ({children, index, value, ...otherProps}) => {
  const isDesktop = useIsDesktop()
  return (
    <div
      aria-labelledby={`full-width-tab-${index}`}
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      role="tabpanel"
      {...otherProps}
    >
      {value === index && (<Box p={isDesktop ? 3 : 0}>{children}</Box>)}
    </div>
  )
}

interface TabPanelProps {
  [key:string]: unknown,
  children: ReactNode,
  index: number,
  value: number,
}

export default TabPanel
