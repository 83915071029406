import {OutlinedInput, OutlinedInputProps, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {FC, MutableRefObject} from 'react'
import {useTranslate} from 'react-admin'

import {ArrowLeftIcon, CloseFilledIcon} from '../icons'

const ChatSearchInboxInput: FC<ChatSearchInboxProps> = ({
  isShowingArrowLeftIcon, onChange, onLeftArrowIconClicked, ...props
}) => {
  const styles = useStyles()
  return (
    <>
      <OutlinedInput
        className={styles.root}
        endAdornment={
          <CloseFilledIcon
            className={styles.closeIcon}
            color="disabled"
            onClick={() => onChange("")}
          />
        }
        fullWidth
        onChange={e => onChange(e.target.value)}
        startAdornment={isShowingArrowLeftIcon && (
          <ArrowLeftIcon
            className={styles.leftIcon}
            onClick={onLeftArrowIconClicked}
          />
        )}
        {...props}
      />
      <Typography color="textSecondary" variant="caption">
        {useTranslate()('searchInbox.minCharactersForMessagesSearch')}
      </Typography>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  closeIcon: {
    cursor: 'pointer',
  },
  leftIcon: {
    cursor: 'pointer',
  },
  root: {
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.info.main}`,
      },
    },
    borderRadius: '6px',
  },
}))

interface ChatSearchInboxProps extends Omit<OutlinedInputProps, 'onChange'> {
  inputRef: MutableRefObject<HTMLInputElement | undefined>
  isShowingArrowLeftIcon?: boolean
  onChange: (value: string) => void
  onLeftArrowIconClicked?: () => void
}

export default ChatSearchInboxInput
