import {Tooltip, TooltipProps} from '@mui/material'
import {forwardRef} from 'react'

const ExtendedTooltip = ({children, ...props}: TooltipProps): JSX.Element => (
  <Tooltip {...props}>
    <TooltipRefForwarder>
      {children}
    </TooltipRefForwarder>
  </Tooltip>
)


const TooltipRefForwarder = forwardRef<HTMLDivElement, Pick<TooltipProps, 'children'>>(
  (props, ref) => (
    <div ref={ref} {...props}>{props.children}</div>
  )
)

export default ExtendedTooltip
