
import {
  Box,
  Chip,
  Grid2,
  List,
  ListItemButton,
  ListItemProps,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {first} from 'lodash-es'
import {FC, useEffect, useMemo, useRef} from 'react'
import {useTranslate} from 'react-admin'
import {Link, useLocation} from 'react-router-dom'
import {Virtuoso} from 'react-virtuoso'

import Empty from '../../components/Empty'
import {useCompileChatMessageText} from '../../hooks/useCompileChatMessageText'
import useSimplifyAge from '../../hooks/useSimplifyAge'
import {Chats} from '../../types/graphqlSchema'
import {userInitials} from '../../utils/users'
import AttentionIndicator from '../AttentionIndicator'
import {FlagIcon, WarningIcon} from '../icons'

const ChatList: FC<ChatListProps> = ({
  chats,
  chatTitle: ChatTitle,
  fetchMoreChats,
  hasMessagePreview,
  hasMoreChats,
  isChatSearchInbox,
  // TODO: The side effects of this prop should by directly implemented by
  //       `ExternalChatPage` because concrete implement details should not leak into
  //       this generic component
  isExternalMultiMerchantUserChat,
  isFetchingMore,
  listTitle,
  loading,
  rightComponent: RightComponent,
  selectedChatId,
  ...props
}) => {
  const styles = useStyles()
  const {hash, pathname} = useLocation()
  const canFetchMoreChats = useRef(false)
  useEffect(() => {
    if (!chats.length) return
    const timeoutId = setTimeout(() => {
      canFetchMoreChats.current = true
    }, 100)
    return () => {
      clearTimeout(timeoutId)
      canFetchMoreChats.current = false
    }
  }, [chats.length])
  if (loading && !isFetchingMore) return (
    <Box
      className={styles.root}
      display="flex"
      flexDirection="column"
      gap="1rem"
      paddingX="1rem"
    >
      {[...Array(6).keys()]
        .map(i => (
          <Skeleton
            sx={{marginTop: '10px'}}
            height="3.5rem"
            key={i}
            variant="rectangular" />
        ))}
    </Box>
  )
  if (!chats.length) return isChatSearchInbox ? null : <Empty resource="chats" />
  if (isChatSearchInbox) return (
    <List>
      {listTitle && (
        <Typography
          className={styles.listTitle}
          paragraph
          variant="subtitle2"
        >
          {listTitle}
        </Typography>
      )}
      {chats.map(chat => (
        <ChatListItem
          {...{
            ChatTitle,
            RightComponent,
            hasMessagePreview,
            hash,
            pathname,
            selectedChatId,
          }}
          chat={chat}
          isChatSearchInbox
          isExternalMultiMerchantUserChat={isExternalMultiMerchantUserChat}
          key={chat.id}
        />
      ))}
    </List>
  )
  return (
    <div {...props} className={styles.root}>
      <Virtuoso<Chats>
        atBottomStateChange={atBottom => {
          if (!(atBottom && hasMoreChats && canFetchMoreChats.current)) return
          fetchMoreChats()
        }}
        atBottomThreshold={50}
        computeItemKey={(_, chat) => chat.id}
        data={chats}
        itemContent={(_, chat) => (
          <ChatListItem
            {...{
              ChatTitle,
              RightComponent,
              hasMessagePreview,
              hash,
              pathname,
              selectedChatId,
            }}
            chat={chat}
            isChatSearchInbox={isChatSearchInbox}
            isExternalMultiMerchantUserChat={isExternalMultiMerchantUserChat}
            key={chat.id}
          />
        )}
        overscan={{main: 300, reverse: 300}}
        totalCount={chats.length}
      />
    </div>
  )
}

// TODO: Move into separate file
const ChatListItem: FC<ChatListItemProps> = ({
  chat,
  ChatTitle,
  hash,
  hasMessagePreview,
  isChatSearchInbox,
  isExternalMultiMerchantUserChat,
  pathname,
  RightComponent,
  selectedChatId,
  ...props
}) => {
  const translate = useTranslate()
  const simplifyAge = useSimplifyAge()
  const styles = useStyles()
  const {compileChatMessageText} = useCompileChatMessageText()
  const [, basePath] = pathname.split('/')
  const path = `/${basePath}/${[
    chat.channelId, chat.id,
  ].filter(Boolean).join('/')}${hash}`
  const mostRecentChatMessage = useMemo(() => first(chat.chatMessages), [chat])
  // TODO: Move side effects for external chat styling into `ExternalChatPage` component
  const isExternal = useMemo(() => chat.type === 'EXTERNAL', [chat])
  return (
    <ListItemButton
      // @ts-ignore (on the "button" prop):
      //  Type 'boolean' is not assignable to type 'true'
      button
      className={`
        ${styles.chatListItem}
        ${(isExternalMultiMerchantUserChat && !chat.assignedMerchantUser) ?
        styles.isUnassignedExternalMultiMerchantUserChat : ''
        }
      `}
      component={Link as any}
      selected={chat.id === selectedChatId}
      to={path}
      {...props}
    >
      <Grid2 className={styles.chatListItemGrid} container>
        {isExternal && (
          <Grid2 className={styles.leftComponentsGridItem}>
            {
              // TODO: In hasura, add calculated relationship object `chats.userChat`
              //       so that we can write instead `c.userChat?.isFlagged`
              first(chat.userChats)?.isFlagged && (
                <FlagIcon color="warning" size="small" />
              )
            }
            {(isExternalMultiMerchantUserChat && chat.assignedMerchantUser) && (
              <Chip
                className={styles.assignedMerchantUserChip}
                label={userInitials(chat.assignedMerchantUser)}
                size="small"
                style={{background: chat.assignedMerchantUser.userColor}}
              />
            )}
          </Grid2>
        )}
        <Grid2 className={styles.chatMessageGridItem}>
          <ListItemText
            className={styles.chatMessageListItemText}
            primary={<ChatTitle chat={chat} />}
            secondary={hasMessagePreview &&
              (compileChatMessageText(mostRecentChatMessage) ||
                translate('chat.chatList.withoutText'))
            }
            secondaryTypographyProps={{
              variant: "caption",
            }}
          />
        </Grid2>
        <Grid2 className={styles.chatMessageAgeGridItem}>
          <Typography color="textSecondary" variant="caption">
            {simplifyAge(
              mostRecentChatMessage?.timestamp ?? chat?.lastActivityTimestamp as string
            )}
          </Typography>
        </Grid2>
        <Grid2 className={styles.indicatorsGridItem} container>
          {!!chat.unreadMessageCount && <AttentionIndicator />}
          {chat.hasUnreadFailedChatMessage && <WarningIcon color="error" size="inherit"/>}
        </Grid2>
        {RightComponent && !isChatSearchInbox && (
          <Grid2 className={styles.rightComponentGridItem}>
            <RightComponent
              chat={chat}
              isExternalMultiMerchantUserChat={!!isExternalMultiMerchantUserChat}
            />
          </Grid2>
        )}
      </Grid2>
    </ListItemButton>
  )
}

const useStyles = makeStyles(theme => ({
  assignedMerchantUserChip: {
    '& > span.MuiChip-label': {
      textOverflow: 'clip', // Overwrite default 'ellipsis' value
    },
    color: theme.palette.background.paper,
    cursor: 'inherit', // overwrite default behavior of `<Chip>`
    height: '18px',
    width: '32px',
    ...theme.typography.overline,
  },
  chatListItem: {
    borderTop: `1px solid ${theme.palette.disabled.main}`,
    display: 'flex',
    height: '3.75rem',
  },
  chatListItemGrid: {
    '& > .MuiGrid-item': {
      padding: 0,
    },
    alignItems: 'center',
    flexWrap: 'nowrap',
    margin: 0,
    width: '100%',
  },
  chatMessageAgeGridItem: {
    textAlign: 'end',
    width: '10rem',
  },
  chatMessageGridItem: {
    marginLeft: theme.remSpacing(1),
    overflow: 'hidden',
    width: '100%',
  },
  chatMessageListItemText: {
    '& .MuiListItemText-primary > p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& .MuiListItemText-secondary': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  indicatorsGridItem: {
    display: 'flex',
    justifyContent: 'right',
    width: `${theme.remSpacing(6)} !important`,
  },
  isUnassignedExternalMultiMerchantUserChat: {
    '&:hover': {
      backgroundColor: '#FBEFEF',
    },
    backgroundColor: '#FBEFEF',
  },
  leftComponentsGridItem: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: theme.remSpacing(8),
  },
  listTitle: {
    color: theme.palette.info.main,
    marginLeft: theme.remSpacing(7),
  },
  rightComponentGridItem: {
    marginRight: theme.remSpacing(2),
    width: 'auto',
  },
  root: {
    '& .MuiListItem-root:last-of-type': {
      borderBottom: `1px solid ${theme.palette.disabled.main}`,
    },
    height: '100%',
  },
}))

interface ChatListProps {
  chatTitle: FC<{chat: Chats}>
  chats: Chats[]
  className?: string
  fetchMoreChats: () => Promise<void>
  hasMessagePreview: boolean
  hasMoreChats?: boolean
  isChatSearchInbox?: boolean
  isExternalMultiMerchantUserChat?: boolean
  isFetchingMore?: boolean
  listTitle?: string
  loading: boolean
  rightComponent?: FC<{chat: Chats, isExternalMultiMerchantUserChat: boolean}>
  selectedChatId: string
}

interface ChatListItemProps extends ListItemProps {
  ChatTitle: FC<{chat: Chats}>
  RightComponent?: FC<{chat: Chats, isExternalMultiMerchantUserChat: boolean}>
  chat: Chats
  hasMessagePreview: boolean
  hash: string
  isChatSearchInbox?: boolean
  isExternalMultiMerchantUserChat?: boolean
  pathname: string
  selectedChatId?: string
}

export default ChatList
