import {MutationFunctionOptions} from '@apollo/client'
import {Chip, DialogContentText, Grid2} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {debounce, groupBy} from 'lodash-es'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import {useTranslate} from 'react-admin'
import {GroupedVirtuoso, GroupedVirtuosoHandle, LocationOptions} from 'react-virtuoso'

import {useCompileChatMessageText} from '../../../hooks/useCompileChatMessageText'
import useSimplifyAge from '../../../hooks/useSimplifyAge'
import {ChatMessages} from '../../../types/graphqlSchema'
import {EventCategory, trackEvent} from '../../../utils/tracking'
import uuidv4 from '../../../utils/uuid'
import StartChatDialog from '../StartChatDialog'
import ChatMessageListItem from './ChatMessageListItem'

const ChatMessageList = forwardRef<ChatMessageListForwardRef, ChatMessageListProps>(({
  chatMessages = [],
  fetchMoreChatMessages,
  isForwardingChatMessage,
  isShowingTranslations,
  onForwardChatMessage,
  onLastChatMessageVisibleInTheViewPortChange,
}, ref) => {
  const translate = useTranslate()
  const styles = useStyles()
  const simplifyAge = useSimplifyAge()
  const {compileChatMessageText} = useCompileChatMessageText()
  const [selectedChatMessageId, setSelectedChatMessageId] = useState<string>()
  const [isForwardingDialogOpen, setIsForwardingDialogOpen] = useState(false)
  const reversedChatMessages = useMemo(() => [...chatMessages].reverse(), [chatMessages])
  const virtuosoRef = useRef<GroupedVirtuosoHandle>()
  const scrollerRef = useRef<HTMLElement>(null)
  const isScrollbarAtBottom = useRef(true)
  const debounceSetLastChatMessageVisibleInTheViewPort = useMemo(
    () => debounce(onLastChatMessageVisibleInTheViewPortChange, 500),
    [onLastChatMessageVisibleInTheViewPortChange],
  )
  useImperativeHandle(ref, () => ({
    isScrollbarPositionAtBottom: () => isScrollbarAtBottom.current,
    scrollToBottom: () => {
      virtuosoRef.current?.scrollToIndex({
        align: 'end',
        behavior: 'auto',
        index: reversedChatMessages.length - 1,
      })
    },
    scrollToChatMessage(
      chatMessageId: string,
      {behavior = 'auto', delay = 100} = {}
    ) {
      const index = reversedChatMessages.findIndex(m => m.id === chatMessageId)
      if (index === -1) return
      const scroll = () => virtuosoRef.current?.scrollToIndex({
        align: 'center',
        behavior,
        index,
      })
      if (delay <= 0) scroll()
      else setTimeout(scroll, delay)
    },
  }))
  const scrollToChatMessageId = useRef<string>()
  const canFetchMoreChatMessages = useRef<boolean>(false)
  const groupNameToChatMessages = useMemo(
    () => groupBy(
      reversedChatMessages,
      c => simplifyAge(c.insertionTimestamp as string, {canShowToday: true})
    ), [reversedChatMessages, simplifyAge]
  )
  // Specifies the amount of items in each group
  // (and, actually, how many groups are there). For example, passing [20, 30]
  // will display 2 groups with 20 and 30 items each.
  const groupCounts = useMemo<number[]>(() => Object.values(groupNameToChatMessages).map(
    messages => messages.length
  ), [groupNameToChatMessages])
  const groupNames = useMemo<string[]>(
    () => Object.keys(groupNameToChatMessages), [groupNameToChatMessages]
  )
  const selectedChatMessage = useMemo(
    () => chatMessages.find(({id}) => id === selectedChatMessageId),
    [chatMessages, selectedChatMessageId]
  )
  useEffect(() => {
    if (!scrollToChatMessageId.current) return
    // When the user scrolls to the top and new messages are fetched, the scroll position
    // is not kept, instead, the scroll stays fixed at the top.
    // Unfortunately, virtuoso only keeps the scroll position when the user is scrolling
    // to the bottom. The bellow is a workaround used to keep the scroll bar to the same
    // position (position computed using the chatMessageId) while scrolling to the top.
    virtuosoRef.current?.scrollToIndex(
      reversedChatMessages.findIndex(m => m.id === scrollToChatMessageId.current)
    )
    scrollToChatMessageId.current = undefined
  }, [reversedChatMessages])
  useEffect(() => {
    if (!reversedChatMessages.length) return
    // This is a naive workaround to solve the problem where "fetch more" is called
    // right after the first render of the Virtuoso list. We set a delay so that
    // "fetch more" can't be called until 100 milliseconds after the first rendering of
    // the component. It's enough time to prevent the component from calling "fetch more"
    // and it's a small enough time not to be perceptible by the end-user.
    const timeoutId = setTimeout(() => {
      canFetchMoreChatMessages.current = true
    }, 100)
    return () => {
      clearTimeout(timeoutId)
      canFetchMoreChatMessages.current = false
    }
  }, [reversedChatMessages])
  if (!chatMessages.length) return null
  return (
    <div className={styles.root}>
      <GroupedVirtuoso<ChatMessages>
        alignToBottom
        atBottomStateChange={atBottom => {
          isScrollbarAtBottom.current = atBottom
          if (!(atBottom && canFetchMoreChatMessages.current)) return
          fetchMoreChatMessages({shouldFetchEarlierMessages: false})
        }}
        atBottomThreshold={200}
        atTopStateChange={async atTop => {
          if (!(atTop && canFetchMoreChatMessages.current)) return
          scrollToChatMessageId.current = reversedChatMessages[0].id
          fetchMoreChatMessages({shouldFetchEarlierMessages: true})
        }}
        atTopThreshold={200}
        className={styles.groupedVirtuosoList}
        computeItemKey={index => reversedChatMessages[index]?.id ?? index}
        groupContent={index => (
          <Grid2
            className={styles.chatMessageGroupGrid}
            container
            justifyContent="center"
            key={groupNames[index] || 'Ausstehend'}
          >
            <Grid2>
              <Chip
                className={styles.chatMessageGroupChip}
                label={groupNames[index] || translate('statuses.pending')}
              />
            </Grid2>
          </Grid2>
        )}
        groupCounts={groupCounts}
        initialTopMostItemIndex={reversedChatMessages.length - 1}
        itemContent={index => {
          const chatMessage = reversedChatMessages[index]
          return (
            <div>
              <ChatMessageListItem
                isShowingTranslations={isShowingTranslations}
                onForward={() => {
                  setSelectedChatMessageId(chatMessage.id)
                  setIsForwardingDialogOpen(true)
                }}
                value={chatMessage}
              />
            </div>
          )
        }}
        rangeChanged={range => {
          if (!reversedChatMessages[range.endIndex]) return
          debounceSetLastChatMessageVisibleInTheViewPort(
            reversedChatMessages[range.endIndex]
          )
        }}
        ref={virtuosoRef as any}
        scrollerRef={element => {
          // @ts-ignore: Cannot assign to "current" because it is a read-only property
          scrollerRef.current = element
        }}
      />
      <StartChatDialog
        actionButtonText={translate('actions.send')}
        hasInternalChats
        isChatNameEditable={false}
        loading={isForwardingChatMessage}
        onClose={() => setIsForwardingDialogOpen(false)}
        onStartChat={({userIds}) => {
          const forwardingResult = onForwardChatMessage({
            variables: {
              chat: {userIds},
              forwardedChatMessageId: selectedChatMessage?.id,
              id: uuidv4(),
              type: 'FORWARD',
            },
          })
          trackEvent('FORWARD_MESSAGE', EventCategory.CHAT)
          return forwardingResult as any
        }}
        open={isForwardingDialogOpen}
        title={translate('chat.chatMessageList.index.forward')}
      >
        <Grid2 className={styles.innerDialogContent}>
          <DialogContentText>
            {selectedChatMessage?.authorUserDisplayName}
          </DialogContentText>
          <DialogContentText>{
            compileChatMessageText(selectedChatMessage) ??
            translate('chat.chatMessageList.index.noText')
          }</DialogContentText>
        </Grid2>
      </StartChatDialog>
    </div>
  )
})

const useStyles = makeStyles(theme => ({
  chatMessageGroupChip: {
    backgroundColor: '#e0e0e0',
    borderRadius: '6px',
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
    margin: '10px 0',
    minWidth: '13ch',
  },
  chatMessageGroupGrid: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  forwardedChatMessageItem: {
    background: '#EEEEEE !important',
  },
  groupedVirtuosoList: {
    '& [data-testid="virtuoso-item-list"]': {
      marginLeft: theme.remSpacing(2),
      marginRight: theme.remSpacing(2),
    },
    overflowX: 'hidden',
  },
  innerDialogContent: {
    '& .MuiDialogContentText-root': {
      fontStyle: 'italic',
      marginLeft: '10px',
    },
    '& .MuiDialogContentText-root:first-child': {
      fontWeight: '900 !important',
    },
    borderLeft: 'solid 5px #eee',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}))

interface ChatMessageListProps {
  chatMessages?: ChatMessages[]
  fetchMoreChatMessages: (_: {shouldFetchEarlierMessages: boolean}) => Promise<void>
  isForwardingChatMessage?: boolean
  isShowingTranslations?: boolean
  onForwardChatMessage: (
    options?: MutationFunctionOptions<ChatMessages, any>
  ) => Promise<void>
  onLastChatMessageVisibleInTheViewPortChange: (chatMessage: ChatMessages) => void
}

export interface ChatMessageListForwardRef {
  isScrollbarPositionAtBottom: () => boolean
  scrollToBottom: () => void
  scrollToChatMessage: (
    chatMessageId: string,
    options?: {behavior?: LocationOptions['behavior'], delay?: number},
  ) => void
}

export default ChatMessageList
