import {CustomerUsers, MerchantCustomerUsers} from '../types/graphqlSchema'

const customerUserMetadata = (customerUser: CustomerUsers = {} as CustomerUsers) => {
  const [
    {companyName, customerCode} = {} as MerchantCustomerUsers,
  ] = customerUser?.merchantCustomerUsers as MerchantCustomerUsers[]
  return [customerUser?.whatsappPhoneNumber, companyName, customerCode]
    .filter(Boolean).join(', ')
}

const userInitials = ({firstName, lastName}) =>
  `${firstName.trim()[0] ?? ""}${lastName.trim()[0] ?? ""}`.toUpperCase()

export {customerUserMetadata, userInitials}
