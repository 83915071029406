import {Typography} from '@mui/material'
import {FC} from 'react'
import {useTranslate} from 'react-admin'

import env from '../../../utils/env'
import platform from '../../../utils/platform'

const LocationChatMessageFileAttachment: FC<LocationChatMessageFileAttachmentProps> = ({
  value,
}) => {
  const {address, latitude, longitude, name} = value ?? {}
  const translate = useTranslate()
  if (!latitude || !longitude) {
    return <>{translate(
      'chat.chatMessageBubble.chatMessageFileAttachmentList.' +
      'chatMessageFileAttachment.locationChatMessageFileAttachment.cannotShowGpsPosition'
    )}</>
  }
  const link = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
  return (
    <div>
      <a href={link} rel="noreferrer" target="_blank">
        {
          GMAPS_API_KEY ? (
            <img
              alt="In Google Maps öffnen"
              loading="lazy"
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=17&size=300x200&maptype=roadmap&key=${GMAPS_API_KEY}`}
              width="100%"
            />
          ) : link
        }
      </a>
      <Typography color="secondary" variant="subtitle1">{name}</Typography>
      <Typography color="textSecondary" variant="body2">{address}</Typography>
    </div>
  )
}

const GMAPS_API_KEY = {
  'android': env('GMAPS_ANDROID_API_KEY'),
  'ios': env('GMAPS_IOS_API_KEY'),
  'web': env('GMAPS_BROWSER_API_KEY'),
}[platform]

interface LocationChatMessageFileAttachmentProps {
  value: {
    address?: string
    latitude: string
    longitude: string
    name?: string
  }
}

export default LocationChatMessageFileAttachment
