import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/zoom'

import {useQuery} from '@apollo/client'
import {
  CloudDownload as DownloadIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from '@mui/icons-material'
import {
  Alert,
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  ListItemText,
  Typography,
} from '@mui/material'
import gql from 'graphql-tag'
import {orderBy, unionBy} from 'lodash-es'
import {FC, Fragment, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useTranslate} from 'react-admin'
import {usePageVisibility} from 'react-page-visibility'
import {Navigation, Zoom} from 'swiper/modules'
import {Swiper, SwiperSlide} from 'swiper/react'
import {makeStyles} from 'tss-react/mui'

import ApiUrlsContext from '../../../contexts/ApiUrlsContext'
import useIsDesktop from '../../../hooks/useIsDesktop'
import {ChatMessageFileAttachments, QueryRoot} from '../../../types/graphqlSchema'
import {isNativeIosApp} from '../../../utils/platform'
import DialogCloseButton from '../../DialogCloseButton'
import ExtendedDialog from '../../ExtendedDialog'
import RichTranslationText from '../../RichTranslationText'
import AudioPlayer from '../ChatToolbar/AudioPlayer'
import ChatMessageTranslation from './ChatMessageTranslation'
import LocationChatMessageFileAttachment from './LocationChatMessageFileAttachment'

const ChatMessageFileAttachment: FC<ChatMessageFileAttachmentProps> = ({
  chatId,
  isTranslationVisible,
  shouldDisplayFileName = true,
  shouldShowImagePreview = true,
  value: a,
}) => {
  const translate = useTranslate()
  const isDesktop = useIsDesktop()
  const isPageVisible = usePageVisibility() ?? true
  const [
    currentAttachment, setCurrentAttachment,
  ] = useState<ChatMessageFileAttachments>(a)
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [isFullscreen, setFullscreen] = useState(!isDesktop)
  const {getFilesApiUrl} = useContext(ApiUrlsContext)
  const getUrl = useCallback<GetUrl>(({attachment: a, shouldStartDownload = false}) =>
    a && `${getFilesApiUrl(a.key)}${shouldStartDownload ? `/${a.filename}` : ''}`
  , [getFilesApiUrl])
  const isImageFile = (/image\/\w+/).test(a.mimeType)
  const isAudioFile = (/audio\/\w+/).test(a.mimeType)
  const thumbnailUrl = useMemo<string|undefined>(() => {
    if (isImageFile) return getUrl({attachment: a})
    if (a?.thumbnailMediaKey) return getFilesApiUrl(a.thumbnailMediaKey)
    return undefined
  }, [a, getFilesApiUrl, getUrl, isImageFile])
  const {classes} = useStyles({isAudioFile})
  const defaultChatMessageFileAttachments = useMemo(() => [a], [a])
  const {
    data: {
      chat_message_file_attachments:
        allChatMessageFileAttachments = defaultChatMessageFileAttachments,
    } = {},
    subscribeToMore,
  } = useQuery<QueryRoot['chat_message_file_attachments']>(
    ALL_CHAT_MESSAGE_FILE_ATTACHMENTS_QUERY,
    {skip: !isDialogOpen, variables: {chatId}}
  )
  useEffect(() => {
    if (!isDialogOpen && isPageVisible) return
    return subscribeToMore({
      document: ALL_CHAT_MESSAGE_FILE_ATTACHMENTS_SUBSCRIPTION,
      // @ts-ignore
      updateQuery: (prev, {subscriptionData: {data} = {}}) => {
        const fileAttachments = data?.chat_message_file_attachments
        return {
          chat_message_file_attachments: orderBy(
            unionBy(prev?.chat_message_file_attachments, fileAttachments, 'id'),
            'insertionTimestamp',
          ),
        }
      },
      variables: {chatId},
    })
  }, [subscribeToMore, chatId, isDialogOpen, isPageVisible])
  const initialIndex = useMemo(
    () => allChatMessageFileAttachments
      .findIndex(attachment => attachment.id === a.id),
    [a, allChatMessageFileAttachments]
  )
  // XXX: If there are multiple attachments, they all render hovered at the same time
  return (
    <Grid className={classes.chatMessageFileAttachment} item xs={12}>
      <ListItemText>
        {
          (a.mimeType === 'application/location+json') ?
            (<LocationChatMessageFileAttachment value={a.metadata} />) :
            (
              isAudioFile ? (
                <AudioPlayer fileAttachmentUrl={getUrl({attachment: a})} />
              ) : (
                <Link
                  className={`${classes.link} ${classes.attachmentLinkContainer}`}
                  onClick={() => setDialogOpen(shouldShowImagePreview)}
                >
                  {
                    !!thumbnailUrl &&
                    <img
                      alt={a.filename}
                      className={classes.thumbnailImage}
                      loading="lazy"
                      src={thumbnailUrl}
                    />
                  }
                  {!isImageFile && shouldDisplayFileName && <div>{a.filename}</div>}
                </Link>
              )
            )
        }
      </ListItemText>
      {a.transcribedText && (
        <>
          <ListItemText primaryTypographyProps={{variant: 'body2'}}>
            {translate(
              'chat.chatMessageBubble.chatMessageFileAttachmentList.' +
              'chatMessageFileAttachment.transcribedTextWarning',
            )}
          </ListItemText>
          <ListItemText className={classes.transcribedText}>
            {a.transcribedText}
          </ListItemText>
        </>
      )}
      {isTranslationVisible &&
        <ChatMessageTranslation
          translations={a.chatMessageFileAttachmentTranslations}
        />
      }
      <ExtendedDialog
        className={classes.dialog}
        fullScreen={isFullscreen}
        onClose={() => setDialogOpen(false)}
        open={isDialogOpen}
      >
        <DialogTitle className={classes.dialogTitle}>
          <Typography className={classes.title}>
            {currentAttachment?.filename ?? a.filename}
          </Typography>
          <IconButton
            className={classes.fullscreenButton}
            onClick={() => setFullscreen(f => !f)}
          >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
          <DialogCloseButton
            className={classes.closeDialogButton}
            onClick={() => setDialogOpen(false)}
          />
        </DialogTitle>
        <DialogContent>
          {
            // TODO: This is a temporary fix for iOS and safari, where .ogg file formats
            //       are not supported
            // @ts-ignore
            (!!window.safari || isNativeIosApp) &&
            (/audio\/ogg/).test(a.mimeType) &&
            <Alert severity="warning">
              <RichTranslationText
                rootElement={Fragment}
                tags={{
                  a: match => (
                    <a href={getUrl({attachment: a, shouldStartDownload: true})}>
                      {match}
                    </a>
                  ),
                }}
                translationKey={'chat.chatMessageBubble.chatMessageFileAttachmentList.' +
                  'chatMessageFileAttachment.downloadAudioMessage'
                }
              />
            </Alert>
          }
          {
            isImageFile ?
              /*
                Only once all file attachments are loaded, `initialSlide` can be assigned
                the right prop value. However `<Swiper />` doesn't re-initialize since it
                has already rendered before all attachments were loaded. That's why `key`
                is being reset to instruct React to remount this component with the new
                initial value. See also: https://stackoverflow.com/a/48451229/543875
               */
              <Swiper
                centeredSlides={true}
                className={classes.swiper}
                initialSlide={initialIndex}
                key={allChatMessageFileAttachments.length === 1 ?
                  'attachments-loading-swiper' :
                  'attachments-loaded-swiper'}
                // @ts-ignore
                lazy={{
                  enabled: true,
                  loadPrevNext: true,
                  loadPrevNextAmount: 1,
                }}
                modules={[Navigation, Zoom]}
                navigation
                onSlideChange={({activeIndex}) => {
                  setCurrentAttachment(allChatMessageFileAttachments[activeIndex])
                }}
                slidesPerView={1}
                spaceBetween={50}
                zoom
              >
                {allChatMessageFileAttachments.map(attachment => (
                  <SwiperSlide className={classes.swiperSlide} key={attachment.id}>
                    <SwiperImage
                      alt={attachment.filename}
                      attachment={attachment}
                      isFullscreenDialog={isFullscreen}
                      src={getUrl({attachment})}
                    />
                    {/* Required by the swiper component */}
                    <div className="swiper-lazy-preloader" />
                  </SwiperSlide>
                ))}
              </Swiper> :
              <iframe
                className={classes.iframe}
                src={getUrl({attachment: a})}
                title={a.filename}
              />
          }
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            component="a"
            href={
              getUrl({attachment: currentAttachment ?? a, shouldStartDownload: true})
            }
            startIcon={<DownloadIcon />}
          >
            {translate('actions.download')}
          </Button>
        </DialogActions>
      </ExtendedDialog>
    </Grid>
  )
}

const SwiperImage = ({alt, attachment: a, isFullscreenDialog, src}) => {
  const {classes: styles} = useStyles({})
  return (
    /*
      The `maxWidth` condition allows a better display of images on large screens whose
      width is greater than the height.
      Without this condition they appear too small on large screens.
      Compare with these two images:
      h > w: https://unsplash.com/photos/1SPcjA1W8M4
      w > h: https://unsplash.com/photos/GncCCBNpWMU
     */
    <Container
      className={`swiper-zoom-container ${styles.swiperImageContainer}`}
      maxWidth={a.width > a.height ? 'xl' : 'sm'}
    >
      <img
        alt={alt}
        className={`
          ${styles.swiperImage}
          ${isFullscreenDialog ?
          styles.swiperImageDialogFullScreen :
          styles.swiperImageDialogNormalScreen}
        `}
        loading="lazy"
        src={src}
        width={a.width ? `${a.width}px` : undefined}
      />
    </Container>
  )
}

const CHAT_MESSAGE_FILE_ATTACHMENTS_QUERY_FIELDS = `
 filename height id insertionTimestamp key mimeType width
`

const ALL_CHAT_MESSAGE_FILE_ATTACHMENTS_SUBSCRIPTION = gql`
  subscription($chatId: uuid!) {
    chat_message_file_attachments(
      order_by: {insertionTimestamp: asc}
      where: {
        mimeType: {_ilike: "image/%"}
        chatMessage: {_or: [
          {chatId: {_eq: $chatId}}
          {forwardingChatMessages: {chatId: {_eq: $chatId}}}
        ]}
      }
    ){${CHAT_MESSAGE_FILE_ATTACHMENTS_QUERY_FIELDS}}
  }
`

const ALL_CHAT_MESSAGE_FILE_ATTACHMENTS_QUERY = gql`
  query($chatId: uuid!) {
    chat_message_file_attachments(
      order_by: {insertionTimestamp: asc}
      where: {
        mimeType: {_ilike: "image/%"}
        chatMessage: {_or: [
          {chatId: {_eq: $chatId}}
          {forwardingChatMessages: {chatId: {_eq: $chatId}}}
        ]}
      }
    ){${CHAT_MESSAGE_FILE_ATTACHMENTS_QUERY_FIELDS}}
  }
`

const useStyles = makeStyles<MakeStylesProps>()((theme, {isAudioFile}) => ({
  attachmentLinkContainer: {
    ...(isAudioFile && {
      '& img': {
        width: '24px',
      },
      alignItems: 'center',
      columnGap: '1rem',
      display: 'flex',
    }),
  },
  chatMessageFileAttachment: {
    padding: '0',
  },
  closeDialogButton: {
    '&.MuiButtonBase-root': {
      position: 'unset',
    },
  },
  dialog: {
    '& .MuiDialog-paper:not(.MuiDialog-paperFullScreen)': {
      height: '100%',
      maxHeight: 'min(800px, calc(100% - 64px))',
      maxWidth: 'min(1024px, calc(100% - 64px))',
      width: '100%',
    },
    '& .MuiDialogContent-root': {
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
    },
  },
  dialogActions: {
    '& .MuiButtonBase-root': {
      marginTop: '8px',
    },
    borderTop: `solid 1px ${theme.palette.grey[300]}`,
  },
  dialogTitle: {
    alignItems: 'center',
    borderBottom: `solid 1px ${theme.palette.grey[300]}`,
    display: 'flex',
    margin: 0,
    paddingBottom: theme.remSpacing(1),
    paddingRight: theme.remSpacing(3.5),
    paddingTop: theme.remSpacing(1),
  },
  fullscreenButton: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    marginRight: theme.remSpacing(.5),
  },
  iframe: {
    border: 0,
    display: 'block', // https://stackoverflow.com/a/12726445/543875
    height: '100%',
    width: '100%',
  },
  link: {
    color: theme.palette.info.main,
    cursor: 'pointer',
    fontWeight: 700,
  },
  swiper: {
    '& .swiper-button-next': {
      color: theme.palette.secondary.main,
    },
    '& .swiper-button-prev': {
      color: theme.palette.secondary.main,
    },
    '& .swiper-lazy-loading': {
      display: 'none',
    },
    '& .swiper-lazy-preloader': {
      backgroundColor: theme.palette.background.paper,
      position: 'absolute',
    },
    height: '100%',
  },
  swiperImage: {
    maxHeight: '10vh',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  swiperImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
  },
  swiperImageDialogFullScreen: {
    '@media (min-height: 300px)': {
      maxHeight: '40vh',
    },
    '@media (min-height: 400px)': {
      maxHeight: '50vh',
    },
    '@media (min-height: 500px)': {
      maxHeight: '60vh',
    },
    '@media (min-height: 600px)': {
      maxHeight: '70vh',
    },
    '@media (min-height: 700px)': {
      maxHeight: '80vh',
    },
  },
  swiperImageDialogNormalScreen: {
    '@media (min-height: 300px)': {
      maxHeight: '30vh',
    },
    '@media (min-height: 400px)': {
      maxHeight: '40vh',
    },
    '@media (min-height: 500px)': {
      maxHeight: '50vh',
    },
    '@media (min-height: 600px)': {
      maxHeight: '60vh',
    },
    '@media (min-height: 700px)': {
      maxHeight: '70vh',
    },
  },
  swiperImageTransformComponentContent: {
    justifyContent: 'center',
    width: '100%',
  },
  swiperImageTransformComponentWrapper: {
    width: '100%',
  },
  swiperSlide: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
  thumbnailImage: {
    borderRadius: '3px',
    maxHeight: 'calc(min(500px, 40vh))',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  title: {
    flexGrow: 1,
  },
  transcribedText: {
    fontStyle: 'italic',
  },
}))

interface ChatMessageFileAttachmentProps {
  chatId: string
  isTranslationVisible?: boolean
  shouldDisplayFileName?: boolean
  shouldShowImagePreview?: boolean
  value: ChatMessageFileAttachments
}

interface GetUrl {
  (options: {attachment?: ChatMessageFileAttachments, shouldStartDownload?: boolean}):
    string|undefined
}

interface MakeStylesProps {
  isAudioFile?: boolean
}

export default ChatMessageFileAttachment
