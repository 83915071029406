import {IconButton} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {FC, useCallback} from 'react'

import useTakePicture from '../../../hooks/useTakePicture'
import theme from '../../../theme'
import {CameraIcon} from '../../icons'

const CameraButton: FC<CameraButtonProps> = ({
  disabled = false,
  fileEncoding,
  onTakePicture,
}) => {
  const classes = useStyles(theme)
  const takePicture = useTakePicture({encoding: fileEncoding})
  const pickPicture = useCallback(async () => {
    const picture = await takePicture()
    if (picture) onTakePicture(picture)
  }, [onTakePicture, takePicture])
  return (
    <IconButton
      aria-label="camera"
      className={classes.root}
      color="primary"
      disabled={disabled}
      onClick={pickPicture}
    >
      <CameraIcon />
    </IconButton>
  )
}

interface CameraButtonProps {
  className?: string,
  disabled?: boolean,
  fileEncoding: FileEncoding,
  onTakePicture: (picture: CameraFileAttachment) => void,
}

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiIconButton-root': {
      height: '30px',
      width: '30px',
    },
  },
}))

export default CameraButton
