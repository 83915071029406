import {
  OperationVariables, QueryResultWrapper, useMutation, useQuery,
} from '@apollo/client'
import {Grid2, IconButton, LinearProgress, Snackbar} from '@mui/material'
import {makeStyles} from '@mui/styles'
import gql from 'graphql-tag'
import {orderBy, unionBy} from 'lodash-es'
import {
  FC,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  useGetIdentity,
  useGetList,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin'
import {usePageVisibility} from 'react-page-visibility'

import useFileSelection from '../../hooks/useFileSelection'
import useSessionMerchantUser from '../../hooks/useSessionMerchantUser'
import {
  ChatMessages,
  ChatTypesEnum,
  MerchantUsers,
  MutationRoot,
  MutationRootInsertChatMessageArgs,
  QueryRoot,
  WhatsappMessageStatusesEnum,
  WhatsappMessageTypesEnum,
} from '../../types/graphqlSchema'
import {isEmbedded} from '../../utils/embedded'
import {isNativeMobileApp, platform} from '../../utils/platform'
import {EventCategory, trackEvent} from '../../utils/tracking'
import uuidv4 from '../../utils/uuid'
import {ChatMessageTemplateToolbarIcon} from '../icons'
import MobileFilePickerPopper from '../MobileFilePickerPopper'
import ChatAutoTranslationSettingButton from './ChatAutoTranslationSettingButton'
import ChatContext from './ChatContext'
import ChatMessageList, {ChatMessageListForwardRef} from './ChatMessageList'
import ChatToolbar from './ChatToolbar'

const Chat: FC<ChatProps> = ({
  header,
  isChatToolbarVisible = true,
  // TODO: reply windows is specific to external chats and should not leak here
  isReplyWindowExpired,
  // TODO: translation settings are only for external chats and should not leak here
  isShowingTranslations,
  onChangeIsShowingTranslations,
  // TODO: ExternalInboundChatMessageReceived is specific to external chats and should not
  //  leak here
  onExternalInboundChatMessageReceived,
  // TODO: chat type specific behavior should not leak here
  type,
}) => {
  // TODO: convert all CSS to useStyles
  const styles = useStyles()
  const isPageVisible = usePageVisibility() ?? true
  const [text, setText] = useState("")
  const [isTemplateDialogOpen, setIsTemplateDialogOpen] = useState(false)
  const [isMobileFilePickerPopperOpen, setIsMobileFilePickerPopperOpen] = useState(false)
  const {identity: {id: userId, isImpersonatedUser} = {}} = useGetIdentity()
  const {merchantUser} = useSessionMerchantUser()
  const chatMessageListContainerRef = useRef<HTMLDivElement>()
  const firstLoadEffectRef = useRef(true)
  const redirect = useRedirect()
  const scrollToMostRecentChatMessage = useCallback(() => {
    chatMessageListRef.current?.scrollToBottom()
  }, [])
  const {channelId, chatId, chatMessageId} = useContext(ChatContext)
  const chatMessagesQueryVariables = {
    chatId,
    cursorComparison: {'_lte': 'NOW()'},
    limit: CHAT_MESSAGES_LOAD_MORE_LIMIT,
    timestampOrderBy: 'desc',
  }
  const userChatQueryVariables = {chatId}
  const chatMessageListRef = useRef<ChatMessageListForwardRef>()
  const [
    lastChatMessageVisibleInTheViewPort, setLastChatMessageVisibleInTheViewPort,
  ] = useState<ChatMessages>()
  const {
    data: {user_chats: [{mostRecentReadChatMessage = undefined} = {}] = []} = {},
  } = useQuery<QueryRoot['user_chats']>(USER_CHAT_QUERY, {
    skip: !chatId, variables: userChatQueryVariables,
  })
  const {
    data: {chat_messages: chatMessages} = {},
    fetchMore,
    loading: isLoadingChatMessages,
    refetch: refetchChatMessages,
    subscribeToMore,
  } = useQuery<QueryRoot['chat_messages']>(
    CHAT_MESSAGES_QUERY, {variables: chatMessagesQueryVariables},
  )
  useQuery<QueryRoot['chat_messages_by_pk']>(
    SELECTED_CHAT_MESSAGE_QUERY,
    {
      async onCompleted({
        chat_messages_by_pk: {id, insertionTimestamp = undefined} = {} as ChatMessages,
      }) {
        if (!insertionTimestamp) return
        if (chatMessages?.some(m => m.id === id)) {
          return chatMessageListRef.current?.scrollToChatMessage(
            id, {behavior: 'smooth', delay: 0}
          )
        }
        const {data: {chat_messages: beforeChatMessages}} = await fetchMore({
          updateQuery: (
            {chat_messages: previousChatMessages = []} = {} as any,
          ) => ({
            chat_messages: orderBy(
              // Keep the same messages as before not to update the messages list
              // twice. The final messages list will be given at once in the
              // "after message" load. This also helps to prevent the scroll bar from
              // weirdly jumping before going to the selected message.
              previousChatMessages,
              'timestamp',
              'desc'
            ),
          } as QueryResultWrapper<QueryRoot['chat_messages']>),
          variables: {
            cursorComparison: {'_lte': insertionTimestamp},
            limit: 15,
          },
        })
        await fetchMore({
          updateQuery: (
            __,
            {fetchMoreResult: {chat_messages: afterChatMessages} = {}},
          ) => ({
            chat_messages: orderBy(
              unionBy(beforeChatMessages, afterChatMessages, 'id'),
              'timestamp',
              'desc'
            ),
          } as QueryResultWrapper<QueryRoot['chat_messages']>),
          variables: {
            cursorComparison: {'_gte': insertionTimestamp},
            limit: 15,
            timestampOrderBy: 'asc',
          },
        })
        chatMessageListRef.current?.scrollToChatMessage(id, {delay: 0})
      },
      skip: !(
        chatMessageId &&
        // There's still a small chance that this query is completed before the
        // "chat messages query".
        // The "chat Messages query" must have been called at least once to run this
        // "selected chat message" query. If it's not the case, the "fetch more" function
        // called once this query is completed will fail.
        chatMessages
      ),
      variables: {chatMessageId},
    }
  )
  const {data: mimeTypes = []} = useGetList(
    'file_mime_types',
    {sort: {field: 'mimeType', order: 'DESC'}},
  )
  const {
    dropZoneProps,
    files: fileAttachments,
    input: fileAttachmentInput,
    isDraggedFileRejected,
    isDragging,
    openFileSelectionDialog,
    setFiles: setFileAttachments,
  } = useFileSelection({
    mimeTypes: mimeTypes.map(r => r.mimeType),
    multiple: true,
    shouldCheckForThreeSixtyCloudApiMaxFileSize: type === ChatTypesEnum.External,
  })
  const [
    insertChatMessage, {error: chatMessageInsertError, loading: isInsertingChatMessage},
  ] = useMutation<MutationRoot['insertChatMessage'], MutationRootInsertChatMessageArgs>(
    CHAT_MESSAGE_INSERT_MUTATION,
    {
      onCompleted: () => {
        // TODO: Shouldn't this already happening whenever chat messages add to the list?
        scrollToMostRecentChatMessage()
      },
      // @ts-ignore
      update: (cache, {data: {insertChatMessage: {chatMessage}} = {}} = {}) => {
        const {chat_messages: chatMessages}: any = cache.readQuery({
          query: CHAT_MESSAGES_QUERY,
          variables: chatMessagesQueryVariables,
        })
        cache.writeQuery({
          data: {chat_messages: chatMessages.find(c => c.id === chatMessage.id) ?
            chatMessages : [chatMessage, ...chatMessages],
          },
          query: CHAT_MESSAGES_QUERY,
          variables: chatMessagesQueryVariables,
        })
      },
      variables: {
        platform: isEmbedded ? 'iframe' : platform,
      } as MutationRootInsertChatMessageArgs,
    },
  )
  const forwardChatMessage = useCallback(
    options => insertChatMessage(options)
      .then(({data}) => {
        const chatId = data?.insertChatMessage?.chatMessage?.chatId
        chatId && redirect(`/team-chat/${chatId}`)
      }),
    [insertChatMessage, redirect]
  )
  const sendChatMessage = useCallback(
    async options => {
      await insertChatMessage(options)
      setText("")
      setFileAttachments([])
      setIsTemplateDialogOpen(false)
    },
    [insertChatMessage, setFileAttachments],
  )
  const [upsertMostRecentReadChatMessageId] = useMutation<
    MutationRoot['insert_user_chats_one']
  >(
    USER_CHAT_UPSERT_MUTATION, {
      // @ts-ignore: Property "insert_user_chats_one" does not exist on type
      // MutationResultWrapper<UserChats | undefined> | {} | null
      update: (cache, {data: {insert_user_chats_one: userChat} = {}} = {}) => {
        cache.writeQuery(
          {data: {user_chats: [userChat]},
            query: USER_CHAT_QUERY,
            variables: userChatQueryVariables,
          })
      },
    })
  const fetchMoreChatMessages = useCallback(async (
    {shouldFetchEarlierMessages}: {shouldFetchEarlierMessages: boolean}
  ) => {
    if (!chatMessages?.length) return
    try {
      await fetchMore({
        updateQuery: (
          {chat_messages: previousChatMessages = []} = {} as any,
          {fetchMoreResult: {chat_messages: moreChatMessages} = {}},
        ) => ({
          chat_messages: orderBy(
            unionBy(previousChatMessages, moreChatMessages, 'id'),
            'timestamp',
            'desc'
          ),
        } as QueryResultWrapper<QueryRoot['chat_messages']>),
        variables: {
          ...(shouldFetchEarlierMessages ? {
            cursorComparison: {
              '_lte': chatMessages[chatMessages.length - 1].insertionTimestamp,
            },
            timestampOrderBy: 'desc',
          } : {
            cursorComparison: {'_gte': chatMessages[0].insertionTimestamp},
            timestampOrderBy: 'asc',
          }) as OperationVariables,
          chatId,
          limit: CHAT_MESSAGES_LOAD_MORE_LIMIT,
        },
      })
    }
    catch (error) {
      // Invariant violation: 17 is thrown when component is unmounted while query is
      // still in progress, e.g. when user navigates to different channel/page.
      // See: https://github.com/apollographql/apollo-client/issues/4114#issuecomment-502111099
      if ((error as Error).toString().includes('Invariant Violation')) {
        return
      }
      throw error
    }
  }, [chatId, chatMessages, fetchMore])
  const sendTemplateChatMessage = useCallback<OnSendTemplateChatMessage>(async ({
    chatMessageTemplateId,
    chatMessageTemplateValues,
    templateHeaderMediaFile,
  }) => {
    const id = uuidv4()
    await sendChatMessage({
      optimisticResponse: optimisticChatMessage({
        channelId,
        chatId,
        id,
        merchantUser,
        text: null,
        type: WhatsappMessageTypesEnum.Template,
      }),
      variables: {
        chat: {chatId},
        ...templateHeaderMediaFile,
        chatMessageTemplateId,
        chatMessageTemplateValues,
        id,
        type: 'TEMPLATE',
      },
    })
  }, [channelId, chatId, merchantUser, sendChatMessage])
  const sendTextChatMessage = useCallback(async () => {
    const id = uuidv4()
    await sendChatMessage({
      optimisticResponse: optimisticChatMessage({
        channelId,
        chatId,
        id,
        merchantUser,
        text,
        type: WhatsappMessageTypesEnum.Text,
      }),
      variables: {
        chat: {chatId},
        chatMessageFileAttachments: fileAttachments.map(
          ({encodedContent, filename, mimeType}) => ({
            contentHex: encodedContent,
            filename,
            mimeType,
          })
        ),
        id,
        text,
        type: 'TEXT',
      },
    })
  }, [channelId, chatId, fileAttachments, merchantUser, sendChatMessage, text])
  const notify = useNotify()
  useEffect(() => {
    // Don't subscribe (and effectively also unsubscribe) if page isn't visible in browser
    if (!isPageVisible) return
    // TODO: Instead of using ApolloProvider, use ra-realtime package w/ subscriptions
    // @ts-ignore
    return subscribeToMore({
      document: CHAT_MESSAGE_EVENT_LOGS_SUBSCRIPTION,
      // @ts-ignore
      updateQuery: (prev, {subscriptionData: {data} = {}}) => {
        // @ts-ignore
        const incomingChatMessages = data?.chat_messages_event_logs_stream?.map(
          ({chatMessage}) => chatMessage
        )
        // We want to keep the scrollbar at the bottom of the page when a new inbound
        // message is received only if the scrollbar was already at the bottom before the
        // message arrives.
        if (chatMessageListRef.current?.isScrollbarPositionAtBottom()) {
          setTimeout(scrollToMostRecentChatMessage, 10)
        }
        if (incomingChatMessages?.some(m => !!m.authorUser.customerUser)) {
          onExternalInboundChatMessageReceived?.(incomingChatMessages[0])
        }
        return {
          chat_messages: orderBy(
            unionBy(
              incomingChatMessages as ChatMessages[], prev?.chat_messages, 'id'
            ).filter(({whatsappMessagesStatus}) =>
              whatsappMessagesStatus !== WhatsappMessageStatusesEnum.Canceled
            ),
            'timestamp',
            'desc',
          ),
        }
      },
      /*
        Hasura not only bundles multiple message insert events into a single notification
        event. It also hands over _all_ messages matching the subscription query, being
        them newly inserted messages or already existing ones. This said, Hasura doesn't
        just give us the new rows, it gives _all_ rows which match the query. This said,
        we need to specify how many rows we want to fetch at most per subscription
        notification. Hence, the `limit` specifies how many inserted messages we expect
        at most to come in "at the same time". In case there were more rows inserted
        "simultaneously" we would get only the amount specified by `limit`, missing any
        new message exceeding the limit.
      */
      variables: {
        chatId,
        cursor: new Date().toISOString(),
        limit: 30,
      },
    })
  }, [
    chatId,
    isPageVisible,
    onExternalInboundChatMessageReceived,
    scrollToMostRecentChatMessage,
    subscribeToMore,
  ])
  useEffect(
    () => {
      const lastReadChatMessage = lastChatMessageVisibleInTheViewPort
      if (
        lastReadChatMessage &&
        userId &&
        isPageVisible &&
        !isImpersonatedUser &&
        // Don't run mutation against optimistic chat message responses.
        // We use chat.type to discriminate optimistic responses from actual responses.
        // TODO: We should use a client side field for this instead.
        lastReadChatMessage.chat?.type &&
        (
          lastReadChatMessage.chat.type === 'INTERNAL' ||
          (
            // Do not run mutation for messages with null, SENDING or PENDING status.
            lastReadChatMessage.chat.type === 'EXTERNAL' &&
            lastReadChatMessage.whatsappMessagesStatus &&
            !([
              WhatsappMessageStatusesEnum.Sending,
              WhatsappMessageStatusesEnum.Pending,
            ] as string[]).includes(lastReadChatMessage.whatsappMessagesStatus)
          )
        )
      ) {
        // The mostRecentReadChatMessage can't be updated with a value older than
        // itself(the current one)
        if (
          mostRecentReadChatMessage && (
            new Date(lastReadChatMessage.insertionTimestamp as string).getTime() <=
            new Date(mostRecentReadChatMessage.insertionTimestamp as string).getTime()
          )
        ) {
          return
        }
        upsertMostRecentReadChatMessageId({
          variables: {chatId, mostRecentReadChatMessageId: lastReadChatMessage.id},
        })
      }
    },
    [
      chatId,
      chatMessages,
      isImpersonatedUser,
      isPageVisible,
      lastChatMessageVisibleInTheViewPort,
      mostRecentReadChatMessage,
      upsertMostRecentReadChatMessageId,
      userId,
    ]
  )
  useEffect(
    () => {
      if (isPageVisible && !firstLoadEffectRef.current) {
        // TODO: stop re-fetching here as it reinitialize the message lists when the user
        //  gets back to Flinkit. The user then looses the app state he left.
        //  This behavior is not user-friendly.
        refetchChatMessages().then(() => scrollToMostRecentChatMessage())
      }
    },
    [isPageVisible, refetchChatMessages, scrollToMostRecentChatMessage]
  )
  // Reset the load-ref for the first messages fetching once the chat was switched
  useEffect(() => {
    firstLoadEffectRef.current = true
  }, [chatId])
  // Actually applying the post-load effect for a new chat
  useEffect(() => {
    if (!firstLoadEffectRef.current || !chatMessages) return
    firstLoadEffectRef.current = false
    // Do not make the scroll to the bottom if the selected chat message is there
    // to avoid the scrollbar popping from the bottom to the selected message.
    if (chatMessageId) return
    setTimeout(scrollToMostRecentChatMessage, 50)
  }, [chatMessageId, chatMessages, scrollToMostRecentChatMessage])
  useEffect(() => {
    if (chatMessageInsertError) {
      notify(
        'chat.chatMessageBubble.whatsappMessageStatus.error.failedToSendWithoutErrorCode',
        {type: 'error'},
      )
    }
  }, [chatMessageInsertError, notify])
  useEffect(() => {
    // Refresh messages list when opening a different chat.
    refetchChatMessages()
  }, [chatId, refetchChatMessages])
  // Reset chat text and file attachment toolbar when opening a different chat
  useEffect(() => {
    if (!chatId) return
    setText("")
    setFileAttachments([])
  }, [chatId, setFileAttachments])
  // TODO: Remember and restore unsent messages per chat in local storage
  return (
    <Grid2 className={styles.chat} size={{xs: 12}} {...dropZoneProps}>
      {header}
      <Grid2
        className={`
          ${styles.chatMessagesContainer}
          ${isDragging ? styles.fileDragActive : ''}
        `}
        id="chatMessagesContainer"
        ref={chatMessageListContainerRef as MutableRefObject<HTMLDivElement>}
        size={{xs: 12}}
      >
        {(isLoadingChatMessages && !chatMessages) ? <LinearProgress /> : (
          <ChatMessageList
            chatMessages={chatMessages}
            fetchMoreChatMessages={fetchMoreChatMessages}
            // TODO: Remove this prop; can be set _within_ this component:
            //       set/reset before/after the mutation callback is called
            isForwardingChatMessage={isInsertingChatMessage}
            isShowingTranslations={isShowingTranslations}
            onForwardChatMessage={forwardChatMessage}
            onLastChatMessageVisibleInTheViewPortChange={
              setLastChatMessageVisibleInTheViewPort
            }
            ref={chatMessageListRef as any}
          />
        )}
      </Grid2>
      {isChatToolbarVisible && (
        <ChatToolbar
          /* TODO: Any behavior requiring knowledge about the chat type should be
           *       implemented in the according chat page instead. It is bad practice to
           *       leak chat type specific implementation details here */
          buttons={(type === ChatTypesEnum.External) && (
            <>
              {/*
              TODO: Create template button which owns _entire_ template sending logic
              */}
              <IconButton
                color="primary"
                onClick={() => {
                  trackEvent(
                    'OPEN_TEMPLATE_MESSAGE_COMPOSER_DIALOG',
                    EventCategory.CHAT,
                    'CHAT_TOOLBAR'
                  )
                  setIsTemplateDialogOpen(true)
                }}
              >
                <ChatMessageTemplateToolbarIcon />
              </IconButton>
              <ChatAutoTranslationSettingButton
                chatId={chatId}
                isShowingTranslations={isShowingTranslations}
                onChangeIsShowingTranslations={onChangeIsShowingTranslations}
              />
            </>
          )}
          chatId={chatId}
          fileAttachments={fileAttachments}
          isInsertingChatMessage={isInsertingChatMessage}
          isReplyWindowExpired={isReplyWindowExpired}
          isTemplateDialogOpen={isTemplateDialogOpen}
          onChangeTemplateDialogOpen={setIsTemplateDialogOpen}
          onChangeText={setText}
          onFilesButtonClick={isNativeMobileApp ?
            () => setIsMobileFilePickerPopperOpen(true) :
            openFileSelectionDialog
          }
          onSelectFileAttachments={setFileAttachments as any}
          onSendTemplateChatMessage={sendTemplateChatMessage}
          onSendTextChatMessage={sendTextChatMessage}
          text={text}
        />
      )}
      {fileAttachmentInput as ReactNode}
      {isNativeMobileApp && (
        <MobileFilePickerPopper
          anchorElementRef={chatMessageListContainerRef}
          isOpen={isMobileFilePickerPopperOpen}
          mimeTypes={mimeTypes.map(r => r.mimeType)}
          multiple
          onClose={() => setIsMobileFilePickerPopperOpen(false)}
          onSelectFiles={setFileAttachments}
          shouldCheckForThreeSixtyCloudApiMaxFileSize={type === ChatTypesEnum.External}
        />
      )}
      <Snackbar
        autoHideDuration={6000}
        message={useTranslate()('chat.toolbar.fileAttachment.draggedFileRejected')}
        open={isDraggedFileRejected}
      />
    </Grid2>
  )
}

const optimisticChatMessage = ({
  channelId = null, chatId, id, merchantUser, text, type,
  whatsappMessagesStatus = WhatsappMessageStatusesEnum.Sending,
}: optimisticChatMessageProps = {}) => ({
  insertChatMessage: {
    __typename: 'InsertChatMessageResult',
    chatMessage: {
      __typename: 'chat_messages',
      authorUser: {
        __typename: 'users',
        channelChatBotUser: null,
        customerUser: null,
        id: merchantUser?.user?.id ?? null,
        merchantApiUser: null,
        merchantUser: merchantUser ?? null,
      },
      authorUserDisplayName: '',
      // TODO: Set chat.type to proper value.
      //    We use chat.type to discriminate optimistic responses from actual responses.
      //    We should use a client side field for this instead.
      chat: {__typename: 'chat', channelId, id: chatId, isProductNews: false, type: null},
      chatId,
      // TODO: Include file attachments in optimistic response;
      //    this will require client-side rendering of attachment data.
      chatMessageFileAttachments: [],
      chatMessageTemplate: null,
      chatMessageTemplateValues: [],
      chatMessageTranslations: [],
      chatMessageWhatsappMessages: null,
      detectedLanguage: null,
      forwardedChatMessage: null,
      id,
      insertionTimestamp: (new Date()).toISOString(),
      lastReactionChatMessage: null,
      receivingErrorCode: null,
      repliedChatMessage: null,
      templateHeaderMediaKey: null,
      text,
      timestamp: (new Date()).toISOString(),
      type,
      whatsappMessagesStatus,
    },
  },
})

const useStyles = makeStyles(theme => ({
  chat: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: 0,
    paddingRight: 0,
  },
  chatMessagesContainer: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    minWidth: '18.75rem',
    overflowAnchor: 'none',
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
  },
  fileDragActive: {
    filter: theme.palette.filters.dragAndDropGreen,
  },
  templateHeaderMediaImage: {
    borderRadius: '5px',
    height: '185px',
    objectFit: 'cover',
    width: '100%',
  },
}))

const CHAT_MESSAGES_LOAD_MORE_LIMIT = 25
const CUSTOMER_USER_FIELDS = `
  customerUser{
    id
    merchantCustomerUsers{companyName customerCode firstName id lastName}
    whatsappDisplayName
    whatsappPhoneNumber
  }
`
const PARTIAL_CHAT_MESSAGE_FIELDS = `
  authorUser{
    ${CUSTOMER_USER_FIELDS}
    channelChatBotUser{id}
    id
    merchantApiUser{id name}
    merchantUser{firstName id lastName}
  }
  authorUserDisplayName
  chatMessageFileAttachments{
    contentLanguage
    chatMessageFileAttachmentTranslations{language text}
    filename
    height
    id
    key
    metadata
    mimeType
    thumbnailMediaKey
    transcribedText
    width
  }
  chatMessageTemplate{
    buttonText
    buttonUrl
    headline
    id
    text
    type
    whatsappMessageTemplates(where: {chatMessageTemplate: {type: {_eq: MAGIC}}}){
      id status
    }
  }
  chatMessageTemplateValues{
    chatMessageTemplatePlaceholder{index type}
    value
  }
  templateHeaderMediaKey
  text
`
const CHAT_MESSAGE_FIELDS = `
  ${PARTIAL_CHAT_MESSAGE_FIELDS}
  __typename
  chatId
  chatMessageTranslations{language text}
  chatMessageWhatsappMessages{
    whatsappMessage{
      id
      lastSendAttemptErrorCode
      lastSendAttemptErrorDetails
      type
      receivingErrorCode
      status
     }
  }
  chat{channelId id isProductNews type}
  detectedLanguage
  forwardedChatMessage{${PARTIAL_CHAT_MESSAGE_FIELDS}}
  id
  insertionTimestamp
  lastReactionChatMessage{id reactionEmoji}
  receivingErrorCode
  repliedChatMessage{
    authorUser{customerUser{id merchantCustomerUsers{firstName id lastName}} id}
    chatId
    chatMessageFileAttachments{
      filename
      id
      key
      metadata
      mimeType
      thumbnailMediaKey
      transcribedText
    }
    chatMessageTemplate{id text}
    chatMessageTemplateValues{chatMessageTemplatePlaceholder{index type} value}
    id
    text
  }
  timestamp
  type
  whatsappMessagesStatus
`
const SELECTED_CHAT_MESSAGE_QUERY = gql`
  query($chatMessageId: uuid!){
    chat_messages_by_pk(id: $chatMessageId){id insertionTimestamp}
  }
`
const CHAT_MESSAGES_QUERY = gql`
  query chat_messages(
    $chatId: uuid!
    $limit: Int!
    $cursorComparison: timestamptz_comparison_exp
    $timestampOrderBy: order_by
  ){
    chat_messages(
      limit: $limit
      order_by: {insertionTimestamp: $timestampOrderBy}
      where: {
        _or: [
          {chat: {type: {_eq: EXTERNAL}}, whatsappMessagesStatus: {_neq: CANCELED}}
          {chat: {type: {_eq: INTERNAL}}}
        ]
        chatId: {_eq: $chatId}
        insertionTimestamp: $cursorComparison
        type: {_neq: REACTION}
      }
    ){${CHAT_MESSAGE_FIELDS}}
  }
`
const CHAT_MESSAGE_EVENT_LOGS_SUBSCRIPTION = gql`
  subscription($chatId: uuid!, $cursor: timestamptz!, $limit: Int!){
    chat_messages_event_logs_stream(
      batch_size: $limit
      cursor: {initial_value: {insertionTimestamp: $cursor}, ordering: ASC}
      where: {
        chatMessage: {
          chatId: {_eq: $chatId}
          type: {_neq: REACTION}
        }
      }
    ){chatMessage{${CHAT_MESSAGE_FIELDS}} id}
  }
`
const CHAT_MESSAGE_INSERT_MUTATION = gql`
  mutation(
    $chat: Chat!
    $chatMessageFileAttachments: [ChatMessageFileAttachment!]
    $chatMessageTemplateId: uuid
    $chatMessageTemplateValues: [ChatMessageTemplateValue!]
    $forwardedChatMessageId: uuid
    $id: uuid
    $platform: String!
    $templateHeaderMediaBase64Content: String
    $templateHeaderMediaMimeType: String
    $text: String
    $type: String!
  ) {
    insertChatMessage(
      chat: $chat
      chatMessageFileAttachments: $chatMessageFileAttachments
      chatMessageTemplateId: $chatMessageTemplateId
      chatMessageTemplateValues: $chatMessageTemplateValues
      forwardedChatMessageId: $forwardedChatMessageId
      id: $id
      platform: $platform
      templateHeaderMediaBase64Content: $templateHeaderMediaBase64Content
      templateHeaderMediaMimeType: $templateHeaderMediaMimeType
      text: $text
      type_: $type
    ) {chatMessage{${CHAT_MESSAGE_FIELDS}}}
  }
`
const USER_CHAT_FIELDS = `
  chatId
  mostRecentReadChatMessage{id insertionTimestamp}
  userId
`
const USER_CHAT_QUERY = gql`query($chatId: uuid!){
  user_chats(where: {chatId: {_eq: $chatId}}){${USER_CHAT_FIELDS}}
}`
const USER_CHAT_UPSERT_MUTATION = gql`
  mutation(
    $chatId: uuid!
    $mostRecentReadChatMessageId: uuid
  ){
    insert_user_chats_one(
      object: {
        chatId: $chatId
        mostRecentReadChatMessageId: $mostRecentReadChatMessageId
      }
      on_conflict: {
        constraint: user_chats_pkey
        update_columns: [mostRecentReadChatMessageId]
      }
    ){${USER_CHAT_FIELDS}}
  }
`

interface ChatProps {
  header: ReactNode,
  isChatToolbarVisible?: boolean,
  isReplyWindowExpired?: boolean,
  isShowingTranslations?: boolean,
  onChangeIsShowingTranslations?: (isShowingTranslations: boolean) => void,
  onExternalInboundChatMessageReceived?: (chatMessage: ChatMessages) => void
  type: ChatTypesEnum
}

interface optimisticChatMessageProps {
  channelId?: string|null
  chatId?: string
  id?: string
  merchantUser?: MerchantUsers
  text?: string|null
  type?: WhatsappMessageTypesEnum
  whatsappMessagesStatus?: WhatsappMessageStatusesEnum,
}

export default Chat
