import {Tab, Tabs} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {FC} from 'react'
import {useTranslate} from 'react-admin'
import {useNavigate} from 'react-router-dom'

import {ChatTypesEnum} from '../../types/graphqlSchema'
import {EventCategory, trackEvent} from '../../utils/tracking'
import AttentionIndicator from '../AttentionIndicator'

const ChatFilterTabs: FC<ChatFilterTabsProps> = ({
  chatType, className, filters, value,
}) => {
  const styles = useStyles()
  const navigate = useNavigate()
  const translate = useTranslate()
  return (
    <Tabs
      TabIndicatorProps={{children: <span />}}
      className={`${styles.root} ${className ?? ''}`}
      value={value}
      variant="standard"
    >
      {filters.map(filter => (
        <Tab
          className={`
            ${styles.tabRoot}
            ${filter.hasUnreadChatMessages ? styles.unreadTab : ''}
          `}
          component="a"
          disableRipple
          key={filter.name}
          wrapped
          label={
            <div>
              {translate(`chat.${chatType.toLowerCase()}.filters.${filter.name}`)}
              {filter.hasUnreadChatMessages && <AttentionIndicator />}
            </div>
          }
          onClick={() => {
            navigate(`#${filter.name}`, {replace: true})
            trackEvent(`FILTER_CHATS_${filter.name.toUpperCase()}`, EventCategory.CHAT)
          }}
          value={filter.name}
        />
      ))}
    </Tabs>
  )
}

interface ChatFilters {
  hasUnreadChatMessages: boolean,
  name: string
}

interface ChatFilterTabsProps {
  chatType: ChatTypesEnum
  className?: string
  filters: ChatFilters[]
  value: string
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.remSpacing(1),
    paddingLeft: theme.remSpacing(2),
  },
  tabRoot: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.remSpacing(1.5),
      paddingRight: theme.remSpacing(1.5),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.remSpacing(.5),
      paddingRight: theme.remSpacing(.5),
    },
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
    },
  },
  unreadTab: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.remSpacing(0.9),
      paddingRight: theme.remSpacing(0.9),
    },
  },
}))


export default ChatFilterTabs
