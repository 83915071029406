import {makeStyles} from '@mui/styles'
import {FC, MouseEventHandler} from 'react'

import {ShareIcon} from '../../icons'

const ChatMessageForwardButton: FC<ChatMessageForwardButtonProps> = ({
  className, onClick,
}) => (
  <div className={`${useStyles().root} ${className ?? ''}`} onClick={onClick}>
    <ShareIcon color="background"/>
  </div>
)

const useStyles = makeStyles(theme => ({
  root: {
    '& > .MuiSvgIcon-root': {
      height: theme.typography.pxToRem(14),
      width: theme.typography.pxToRem(14),
    },
    alignItems: 'center',
    background: '#88888890', // TODO: Use theme palette color
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    height: theme.typography.pxToRem(30),
    justifyContent: 'center',
    width: theme.typography.pxToRem(30),
  },
}))

interface ChatMessageForwardButtonProps {
  className?: string
  onClick?: MouseEventHandler<HTMLDivElement>
}

export default ChatMessageForwardButton
