import {Box, FormLabel, Typography} from '@mui/material'
import {FC, ReactNode} from 'react'

const SubtitledInput: FC<SubtitledInputProps> = ({children, subtitle, title}) => (
  <Box display="flex" flexDirection="column" width="100%">
    <FormLabel><Typography>{title}</Typography></FormLabel>
    <Typography color="textSecondary" variant="caption">{subtitle}</Typography>
    {children}
  </Box>
)

interface SubtitledInputProps {
  children: ReactNode,
  title: string,
  subtitle: string
}

export default SubtitledInput
