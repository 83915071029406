import {useSubscription} from '@apollo/client'
import {isAfter} from 'date-fns'
import gql from 'graphql-tag'
import {MouseEvent, useEffect, useMemo, useRef} from 'react'
import {useGetIdentity, useTranslate} from 'react-admin'
import {useNavigate} from 'react-router-dom'

import useBrowserNotifications from '../../hooks/useBrowserNotifications'
import {useCompileChatMessageText} from '../../hooks/useCompileChatMessageText'
import useSessionMerchantUser from '../../hooks/useSessionMerchantUser'
import {ChatTypesEnum, SubscriptionRoot} from '../../types/graphqlSchema'

const UnreadChatMessagesBrowserNotificationPresenter = () => {
  const navigate = useNavigate()
  const translate = useTranslate()
  const previousChatMessageTimestampRef = useRef<string>()
  const {showNotification} = useBrowserNotifications()
  const {identity: {id: userId} = {}} = useGetIdentity()
  const {compileChatMessageText} = useCompileChatMessageText()
  const {merchantUser: {id: sessionMerchantUserId} = {}} = useSessionMerchantUser()
  const cursor = useMemo(() => new Date().toISOString(), [])
  const {
    data: {chat_messages_event_logs_stream: chatMessagesEventLogs} = {},
    restart: restartSubscription,
  } = useSubscription<SubscriptionRoot['chat_messages_event_logs']>(
    CHAT_MESSAGES_EVENT_LOGS_SUBSCRIPTION,
    {
      skip: !sessionMerchantUserId,
      variables: {
        cursor,
        sessionMerchantUserId,
      },
    }
  )
  useEffect(() => {
    if (!chatMessagesEventLogs?.length) return
    const chatMessage = chatMessagesEventLogs[0].chatMessage
    if (!chatMessage || (chatMessage.authorUser.id === userId)) return
    // Don't show notification when the customer user removes its reaction
    if (chatMessage.type === 'REACTION' && !chatMessage.reactionEmoji) return
    if (
      chatMessage.authorUser.channelChatBotUserId ||
      chatMessage.authorUser.merchantApiUserId
    ) return
    /**
     * This component keeps being rerendered (likely due to a change in how react-router
     * works) and as such, to prevent the same message notification being displayed, we
     * keep a ref of the most recently displayed message. If any new message comes in,
     * we compare against this ref and if is equal to it or less/younger than it, we can
     * assume this message was already received, and the component has been rerendered for
     * a different reason.
     */
    if (
      previousChatMessageTimestampRef.current &&
      chatMessage.insertionTimestamp && (
        (previousChatMessageTimestampRef.current === chatMessage.insertionTimestamp) ||
        isAfter(previousChatMessageTimestampRef.current, chatMessage.insertionTimestamp)
      )
    ) return
    previousChatMessageTimestampRef.current = chatMessage.insertionTimestamp
    const pathname = chatMessage.chat.type === ChatTypesEnum.External ?
      'inbox' :
      'team-chat'
    showNotification({
      body: compileChatMessageText(chatMessage),
      onclick: (e: NotificationEvent) => {
        e.target.close()
        window.focus()
        navigate(
          `/${pathname}/${[
            chatMessage.chat.channelId,
            chatMessage.chat.id,
          ].filter(Boolean).join('/')}`,
          {replace: false}
        )
      },
      title: chatMessage?.type === 'REACTION' ?
        translate(
          'unreadChatMessagesBrowserNotificationPresenter.newReactionMessageNotification',
          {authorUserDisplayName: chatMessage?.authorUserDisplayName}
        ) :
        translate(
          'unreadChatMessagesBrowserNotificationPresenter.newMessageNotification',
          {authorUserDisplayName: chatMessage?.authorUserDisplayName}
        )
      ,
    })
  }, [
    chatMessagesEventLogs,
    compileChatMessageText,
    navigate,
    restartSubscription,
    showNotification,
    translate,
    userId,
  ])
  return null
}

const CHAT_MESSAGES_EVENT_LOGS_SUBSCRIPTION = gql`
  subscription($sessionMerchantUserId: uuid!, $cursor: timestamptz!){
    chat_messages_event_logs_stream(
      batch_size: 1
      cursor: {initial_value: {insertionTimestamp: $cursor}, ordering: ASC}
      where: {
        chatMessage: {
          chat: {
            _or: [
              {
                isArchived: {_eq: false}
                type: {_eq: EXTERNAL}
                _or: [
                  {assignedMerchantUserId: {_eq: $sessionMerchantUserId}}
                  {assignedMerchantUserId: {_is_null: true}}
                ]
              },
              {type: {_eq: INTERNAL}}
            ]
          }
        }
      }
    ){
      chatMessage{
        authorUser{id channelChatBotUserId merchantApiUserId}
        authorUserDisplayName
        chat{
          channelId
          id
          type
        }
        id
        insertionTimestamp
        reactionEmoji
        text
        type
      }
    }
  }
`

interface NotificationEvent extends MouseEvent {
  target: Notification
}

export default UnreadChatMessagesBrowserNotificationPresenter
