import {useSubscription} from '@apollo/client'
import gql from 'graphql-tag'

import {ChatTypesEnum, SubscriptionRoot} from '../../types/graphqlSchema'
import useSessionMerchantUser from '../useSessionMerchantUser'

const useHasUnreadChatMessages = ({
  channelId, chatType, isMineOnly, isUnassignedOnly, skip,
}: UseHasUnreadChatMessagesProps) => {
  const {merchantUser: {id: sessionMerchantUserId} = {}} = useSessionMerchantUser()
  const queryDocument = chatType === ChatTypesEnum.External ?
    MOST_RECENT_EXTERNAL_CHAT_WITH_UNREAD_MESSAGES_SUBSCRIPTION :
    MOST_RECENT_INTERNAL_CHAT_WITH_UNREAD_MESSAGES_SUBSCRIPTION
  const {data: {chats} = {}} = useSubscription<SubscriptionRoot['chats']>(
    queryDocument,
    {
      skip: skip || !sessionMerchantUserId,
      variables: {
        ...(chatType === ChatTypesEnum.External && {
          assignedMerchantUserIdComparisonExp: isUnassignedOnly ? (
            {_is_null: true}
          ) : isMineOnly ? (
            {_eq: sessionMerchantUserId}
          ) : undefined,
          // XXX: A fallback uuid is still required because SQL cannot equal compare
          //      columns with `NULL`
          channelId: channelId ?? '00000000-0000-0000-0000-000000000000',
          sessionMerchantUserId,
        }),
      },
    }
  )
  return !!chats?.length
}

const MOST_RECENT_EXTERNAL_CHAT_WITH_UNREAD_MESSAGES_SUBSCRIPTION = gql`subscription(
  $assignedMerchantUserIdComparisonExp: uuid_comparison_exp = {},
  $channelId: uuid,
  $sessionMerchantUserId: uuid!
){
  chats(
    limit: 1,
    where: {
      _or: [
        {assignedMerchantUserId: {_eq: $sessionMerchantUserId}}
        {assignedMerchantUserId: {_is_null: true}}
      ]
      assignedMerchantUserId: $assignedMerchantUserIdComparisonExp,
      channelId: {_eq: $channelId}
      isArchived: {_eq: false}
      type: {_eq: EXTERNAL}
      unreadMessageCount: {_gt: 0}
    }
  ){id}
}`

const MOST_RECENT_INTERNAL_CHAT_WITH_UNREAD_MESSAGES_SUBSCRIPTION = gql`subscription{
  chats(
    limit: 1,
    where: {
      type: {_eq: INTERNAL}
      unreadMessageCount: {_gt: 0}
    }
  ){id}
}`

interface UseHasUnreadChatMessagesProps {
  channelId?: string
  chatType: ChatTypesEnum
  isMineOnly?: boolean,
  isUnassignedOnly?: boolean,
  skip?: boolean,
}

export default useHasUnreadChatMessages
