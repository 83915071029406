import {Visibility, VisibilityOff} from '@mui/icons-material'
import {
  Button, ButtonProps, CircularProgress, Grid, IconButton, InputLabel,
  MenuItem, OutlinedInput, OutlinedInputProps, Select, SelectProps, Typography,
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {FC, ReactNode, useState} from 'react'
import {useTranslate} from 'react-admin'
import {
  Controller,
  FieldValues,
  FormProvider,
  Resolver,
  SubmitHandler,
  UseControllerProps,
  useForm,
  useFormContext,
} from 'react-hook-form'

import Dot from './Dot'
import {CheckIcon, CloseIcon} from './icons'

const Form = <T extends FieldValues, >({
  children, className, submit, validate,
}: ExtendedHookFormProps<T>) => {
  const styles = useStyles()
  const form = useForm({mode: 'all', resolver: validate})
  return (
    <FormProvider {...form}>
      <form
        className={`${styles.form} ${className ?? ''}`}
        onSubmit={form.handleSubmit(async values => {
          const result = await submit(values) as {errorMessage?: string}
          if (result && result.errorMessage) {
            form.setError("root", {message: result.errorMessage})
          }
        })}
      >
        <Grid
          alignItems="stretch"
          container
          direction="column"
          justifyContent="center"
          spacing={3}
          wrap="nowrap"
        >
          {children}
        </Grid>
      </form>
    </FormProvider>
  )
}

const PasswordField: FC<OutlinedInputProps> = props => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  return (
    <TextField
      endAdornment={(
        <IconButton onClick={() => setIsPasswordVisible(s => !s)} size="large">
          {isPasswordVisible ? <Visibility/> : <VisibilityOff/>}
        </IconButton>
      )}
      name="password"
      type={isPasswordVisible ? 'text' : 'password'}
      {...props}
    />
  )
}

const PasswordFieldError = () => {
  const styles = useStyles()
  const translate = useTranslate()
  const {getFieldState, watch} = useFormContext()
  const password = watch('password', '')
  const passwordFieldState = getFieldState('password')
  const validations = {
    digit: {
      label: translate('validations.password.digit'),
      valid: (/\d/).test(password),
    },
    length: {
      label: translate('validations.password.length'),
      valid: password.length >= 8,
    },
    lowercase: {
      label: translate('validations.password.lowercase'),
      valid: (/\p{Ll}/u).test(password),
    },
    uppercase: {
      label: translate('validations.password.uppercase'),
      valid: (/\p{Lu}/u).test(password),
    },
  }
  return (
    <Grid className={styles.passwordFieldErrors} container>
      {Object.values(validations).map((error, i) => (
        <Grid container item key={i} xs={6}>
          {(passwordFieldState.isDirty || passwordFieldState.isTouched) ?
            (
              error.valid ? (
                <CheckIcon
                  className={styles.passwordErrorIcon} color="success" size="small"
                />
              ) : (
                <CloseIcon
                  className={styles.passwordErrorIcon} color="error" size="small"
                />
              )
            ) : <Dot className={styles.passwordErrorDot} />
          }
          <Typography color="textSecondary" variant="caption">
            {error.label}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
}

const TextField = ({
  endAdornment, label, name, parse, placeholder, type,
}: ExtendedTextInputProps) => {
  const styles = useStyles()
  const {control, formState} = useFormContext()
  return (
    <Controller
      control={control}
      name={name ?? ''}
      defaultValue=""
      render={({field, fieldState}) => (
        <div>
          <InputLabel htmlFor={name}>
            <Typography color="textPrimary">{label}</Typography>
          </InputLabel>
          <OutlinedInput
            endAdornment={endAdornment}
            error={fieldState.isTouched && !!fieldState.error}
            fullWidth
            placeholder={placeholder}
            type={type}
            {...field}
            onChange={e => field.onChange(parse ? parse(e.target.value) : e.target.value)}
            value={field.value}
          />
          {(fieldState.error && (fieldState.isTouched || formState.isSubmitted)) && (
            <>
              <Dot className={styles.dot}/>
              <Typography color="error" variant="caption">
                {fieldState.error.message}
              </Typography>
            </>
          )}
        </div>
      )}
    />
  )
}

const SelectField: FC<ExtendedSelectProps> = ({label, name, options, placeholder}) => {
  const styles = useStyles()
  return (
    <Controller
      name={name ?? ''}
      render={({field, fieldState}) => (
        <div>
          <InputLabel htmlFor={name}>
            <Typography color="textPrimary">{label}</Typography>
          </InputLabel>
          <Select variant="standard" disableUnderline displayEmpty {...field}>
            <MenuItem disabled value="">{placeholder}</MenuItem>
            {options.map(({label, value}) => (
              <MenuItem key={label} value={value}>{label}</MenuItem>
            ))}
          </Select>
          {fieldState.isTouched && fieldState.error && (
            <>
              <Dot className={styles.dot}/>
              <Typography color="error" variant="caption">
                {fieldState.error.message}
              </Typography>
            </>
          )}
        </div>
      )}
    />
  )
}

const SubmitButton: FC<ButtonProps> = props => {
  const {formState: {isSubmitting}} = useFormContext()
  const styles = useStyles()
  return (
    <Button
      className={styles.submitButton}
      color="primary"
      disabled={isSubmitting}
      fullWidth
      type="submit"
      variant="contained"
      {...props}
    >
      {isSubmitting ? <CircularProgress size={20}/> : props.children}
    </Button>
  )
}

const SubmitErrorMessage = () => {
  const {formState: {errors, isSubmitting}} = useFormContext()
  return !isSubmitting && errors ? (
    <Typography align="center" color="error">{errors.root?.message}</Typography>
  ) : null
}

const useStyles = makeStyles(theme => ({
  dot: {
    backgroundColor: theme.palette.error.main,
    height: '4px',
    margin: theme.remSpacing(1),
    verticalAlign: 'middle',
    width: '4px',
  },
  form: {
    '& .MuiGrid-item': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .MuiInputBase-root': {
      borderRadius: theme.remSpacing(1),
      width: '100%',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.remSpacing(1),
      paddingLeft: theme.remSpacing(1),
    },
    '& .MuiSelect-select': {
      borderRadius: theme.remSpacing(1),
      paddingLeft: theme.remSpacing(2),
    },
    '& .MuiTypography-root': {
      verticalAlign: 'baseline',
    },
    '& > .MuiGrid-root': {
      height: '100%',
      margin: 'auto',
      width: theme.remSpacing(56),
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    height: '100%',
    marginLeft: theme.remSpacing(13),
    marginRight: theme.remSpacing(13),
    [theme.breakpoints.down('lg')]: {
      '& .MuiGrid-spacing-xs-3': {
        margin: 0,
        width: '100%',
      },
      marginLeft: theme.remSpacing(5),
      marginRight: theme.remSpacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.remSpacing(3),
      marginRight: theme.remSpacing(3),
    },
  },
  passwordErrorDot: {
    backgroundColor: theme.palette.secondary.main,
    height: '4px',
    margin: theme.remSpacing(0.75),
    verticalAlign: 'middle',
    width: '4px',
  },
  passwordErrorIcon: {
    fontSize: theme.typography.caption.fontSize,
    marginRight: '4px',
    marginTop: '1px',
  },
  passwordFieldErrors: {
    '&, & .MuiGrid-root': {
      marginLeft: 0,
      marginRight: 0,
    },
    marginTop: theme.remSpacing(1.5),
    rowGap: theme.remSpacing(0.5),
    width: '100%',
  },
  submitButton: {
    border: 'none',
    margin: 0,
  },
}))

interface ExtendedHookFormProps<T extends FieldValues> {
  children: ReactNode | Iterable<ReactNode>,
  className?: string,
  submit: SubmitHandler<T>,
  validate: Resolver<T>,
}

type ExtendedSelectProps = SelectProps & {
  options: {label: string, value: string}[]
  placeholder?: string
}

type ExtendedTextInputProps = OutlinedInputProps & {
  parse?: (_: any) => any
  validate?: UseControllerProps['rules']
}

export {
  Form, PasswordField, PasswordFieldError, SelectField,
  SubmitButton, SubmitErrorMessage, TextField,
}
