import {AccordionProps, AccordionTypeMap} from '@mui/material/Accordion'
import {Children, cloneElement, FC, ReactElement, useState} from 'react'

const AccordionWrapper: FC<AccordionWrapperProps> = ({children, className}) => {
  const [expandedAccordion, setExpandedAccordion] = useState<string | undefined>()
  const onExpandAccordion = (accordionName: string) => (_e, isExpanded: boolean) => {
    setExpandedAccordion(isExpanded ? accordionName : undefined)
  }
  return (
    <div className={className}>
      {Children.map(children, (child?: AccordionElement) => child && (
        cloneElement(child, {
          expanded: expandedAccordion === child.props.name,
          onChange: onExpandAccordion(child.props.name),
        })
      ))}
    </div>
  )
}

interface AccordionWrapperProps {
  children: AccordionElement | AccordionElement[],
  className?: string,
}

type AccordionElement = ReactElement<
  AccordionProps<AccordionTypeMap['defaultComponent'], {name: string}>
> | false | null | undefined

export default AccordionWrapper
