import {Box, Divider, Grid, Grid2, Typography} from '@mui/material'
import {FC, ReactElement, ReactNode, ReactNodeArray} from 'react'
import {Title} from 'react-admin'
import {useParams} from 'react-router-dom'
import {makeStyles} from 'tss-react/mui'

import useIsDesktop from '../../hooks/useIsDesktop'
import DisplayBox from '../DisplayBox'

const ChatPage: FC<ChatPageProps> = ({
  chatListHeader,
  chatMessageSearchInboxSideBar,
  children,
  isShowingChatMessageSearchInboxSideBar = false,
  searchInbox,
  startNewChatButton,
  title,
}) => {
  const {chatId} = useParams<{chatId: string}>()
  const {classes: styles} = useStyles({hasChatId: !!chatId})
  const isDesktop = useIsDesktop()
  const [chatList, chat] = children
  return (
    <Grid2 className={styles.root}>
      {!isShowingChatMessageSearchInboxSideBar && (
        <>
          <Grid2 className={styles.chatListContainer}>
            <Title title={title} />
            {isDesktop && (
              <Typography
                className={styles.mainTitle}
                color="textPrimary"
                variant="h2"
              >
                {title}
              </Typography>
            )}
            <div className={styles.searchInbox}>{searchInbox}</div>
            <div className={styles.chatListHeader}>
              {chatListHeader}
            </div>
            <Box height="100%" style={{overflowY: 'auto'}}>
              {chatList}
              {startNewChatButton}
            </Box>
          </Grid2>
          <DisplayBox mobile={false}>
            <Divider flexItem orientation="vertical" />
          </DisplayBox>
        </>
      )}
      {(isDesktop || (!isDesktop && !isShowingChatMessageSearchInboxSideBar)) && (
        <Grid className={styles.chatContainer}>
          {chat}
        </Grid>
      )}
      {isShowingChatMessageSearchInboxSideBar && (
        <>
          <DisplayBox mobile={false}>
            <Divider flexItem orientation="vertical" />
          </DisplayBox>
          <Grid className={styles.chatMessageSearchInboxSideBarContainer}>
            {chatMessageSearchInboxSideBar}
          </Grid>
        </>
      )}
    </Grid2>
  )
}

const useStyles = makeStyles<MakeStylesOptions>()((theme, {hasChatId}) => {
  const sideBarsCommonStyles = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    // make sufficient space for all chat filter tabs and their unread indicator
    minWidth: '320px',
    position: 'relative',
    width: '38%',
  } as any
  return {
    chatContainer: {
      [theme.breakpoints.down('lg')]: {
        display: hasChatId ? 'block' : 'none',
        width: '100%',
      },
      backgroundPosition: 'left center',
      backgroundRepeat: 'repeat',
      borderLeft: `solid .5px ${theme.palette.disabled.main}`,
      borderRight: `solid .5px ${theme.palette.disabled.main}`,
      flexGrow: 1,
      height: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      width: '62%',
    },
    chatListContainer: {
      ...sideBarsCommonStyles,
      [theme.breakpoints.down('lg')]: {
        display: hasChatId ? 'none' : 'flex',
        flexDirection: 'column',
        width: '100%',
      },
    },
    chatListHeader: {
      marginBottom: theme.remSpacing(1),
    },
    chatMessageSearchInboxSideBarContainer: {
      ...sideBarsCommonStyles,
      [theme.breakpoints.down('lg')]: {
        display: hasChatId ? 'flex' : 'none',
        flexDirection: 'column',
        width: '100%',
      },
    },
    mainTitle: {
      marginBottom: theme.remSpacing(3),
      marginLeft: theme.remSpacing(5),
      marginRight: theme.remSpacing(2),
      marginTop: theme.remSpacing(4),
      paddingBottom: theme.remSpacing(4),
    },
    root: {
      display: 'flex',
      overflowX: 'hidden',
    },
    searchInbox: {
      [theme.breakpoints.down('lg')]: {
        marginTop: theme.remSpacing(3),
      },
      marginLeft: theme.remSpacing(3),
      marginRight: theme.remSpacing(3),
    },
    startNewChatButton: {
      margin: '1rem',
      marginBottom: '2rem',
    },
  }
})

interface ChatPageProps {
  chatListHeader?: ReactNode
  chatMessageSearchInboxSideBar?: ReactNode
  children: ReactNodeArray
  isShowingChatMessageSearchInboxSideBar?: boolean
  searchInbox?: ReactNode
  startNewChatButton: ReactNode
  title: ReactElement | string
}

interface MakeStylesOptions {
  hasChatId: boolean
}

export default ChatPage
