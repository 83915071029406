import {useMutation, useQuery} from '@apollo/client'
import {Box, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {AutoSave} from '@react-admin/ra-form-layout'
import gql from 'graphql-tag'
import {sortBy} from 'lodash-es'
import {FC, useContext, useEffect, useMemo, useState} from 'react'
import {
  FunctionField,
  regex,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRecordContext,
  useTranslate,
} from 'react-admin'

import ApiUrlsContext from '../contexts/ApiUrlsContext'
import useHasPermission from '../hooks/useHasPermission'
import {
  MutationRoot,
  MutationRootUploadWhatsappBusinessProfilePhotoArgs,
  QueryRoot,
} from '../types/graphqlSchema'
import {EMAIL_ADDRESS_REGEX} from '../utils/consts'
import {actionErrorCode} from '../utils/errors'
import ExtendedEdit from './ExtendedEdit'
import {InfoIcon} from './icons'
import ProfileImageFilePicker from './settings/ProfileImageFilePicker'

const WhatsappAccountBusinessProfileEdit: FC<WhatsappAccountBusinessProfileEditProps> = ({
  id,
}) => {
  const styles = useStyles()
  const notify = useNotify()
  const translate = useTranslate()
  const [imageFile, setImageFile] = useState<FileAttachment>()
  const {getFilesApiUrl} = useContext(ApiUrlsContext)
  const {
    data: {
      whatsapp_accounts_by_pk: {
        businessProfilePhotoFile = undefined,
        hostingType = undefined,
      } = {},
    } = {},
    loading: isLoadingWhatsappAccount,
  } = useQuery<QueryRoot['whatsapp_accounts_by_pk']>(
    WHATSAPP_ACCOUNT_QUERY,
    {skip: !id, variables: {id}}
  )
  const isCloudApiHostingType = hostingType === 'CLOUD_API'
  const canEdit = useHasPermission('edit', 'whatsapp_accounts')
  const uploadedImageUrl = useMemo(
    () => (
      businessProfilePhotoFile?.s3Key && getFilesApiUrl(businessProfilePhotoFile?.s3Key)
    ),
    [getFilesApiUrl, businessProfilePhotoFile],
  )
  const {
    data: {whatsapp_business_verticals: allWhatsappBusinessVerticals = []} = {},
    loading: isLoadingWhatsappBusinessVerticals,
  } = useQuery<QueryRoot['whatsapp_business_verticals']>(
    WHATSAPP_BUSINESS_VERTICALS_QUERY,
    {fetchPolicy: 'cache-first'},
  )
  const whatsappBusinessVerticals = useMemo(
    () => isCloudApiHostingType ?
      allWhatsappBusinessVerticals :
      allWhatsappBusinessVerticals.filter(v => !!v.metaOnPremiseApiName),
    [allWhatsappBusinessVerticals, isCloudApiHostingType],
  )
  const translatedWhatsappBusinessVerticals = useMemo(
    () => sortBy(
      whatsappBusinessVerticals.map(({vertical}) => ({
        description: translate(`whatsapp_business_verticals.${vertical}`),
        vertical,
      })),
      'description'
    ),
    [translate, whatsappBusinessVerticals]
  )
  const [uploadProfilePhoto, {loading: isUploadingImage}] = useMutation<
    MutationRoot['uploadWhatsappBusinessProfilePhoto'],
    MutationRootUploadWhatsappBusinessProfilePhotoArgs
  >(
    UPLOAD_WHATSAPP_PROFILE_PHOTO_MUTATION,
    {
      onCompleted: () => {
        notify('whatsappAccountBusinessProfileEdit.successfulProfilePhotoUpload')
      },
      onError: e => {
        if (actionErrorCode(e) === 'INVALID_WHATSAPP_BUSINESS_PROFILE_IMAGE_SIZE') {
          notify(
            'whatsappAccountBusinessProfileEdit.validations.profilePhotoSize',
            {type: 'error'},
          )
        }
      },
      variables: {
        contentBase64: imageFile?.encodedContent as string,
        filename: imageFile?.filename as string,
        mimeType: imageFile?.mimeType as string,
        whatsappAccountId: id,
      },
    }
  )
  useEffect(() => {
    if (isLoadingWhatsappAccount || !imageFile) return
    uploadProfilePhoto()
  }, [imageFile, isLoadingWhatsappAccount, uploadProfilePhoto])
  if (!id || !hostingType || isLoadingWhatsappBusinessVerticals) return null
  return (
    <>
      <ExtendedEdit
        id={id}
        redirect={false}
        resource="whatsapp_accounts"
      >
        <SimpleForm
          className={styles.editBusinessProfileForm}
          resetOptions={{keepDirtyValues: true}}
          toolbar={(
            <AutoSave
              confirmationDuration={false}
              debounce={1000}
              typographyProps={{display: 'none'}}
            />
          )}
        >
          <Box
            alignItems="center"
            className={styles.profileImageBox}
            display="flex"
            gap="0.5rem"
          >
            <ProfileImageFilePicker
              canEdit={canEdit}
              imageFile={imageFile}
              loading={isUploadingImage}
              onSelectImageFile={setImageFile}
              uploadedImageUrl={uploadedImageUrl}
            />
            {canEdit && (
              <Box
                alignItems="center"
                className={styles.profileImageConstraints}
                display="flex"
                gap="0.5rem"
                width="100%"
              >
                <InfoIcon color="info" />
                <Typography variant="caption">{
                  translate('whatsappAccountBusinessProfileEdit.profilePhotoConstraints')
                }</Typography>
              </Box>
            )}
          </Box>
          {isCloudApiHostingType && (
            <TextInput
              disabled={!canEdit}
              inputProps={{maxLength: 139}}
              maxRows={4}
              minRows={4}
              multiline
              source="businessAbout"
            />
          )}
          <TextInput
            disabled={!canEdit}
            inputProps={{maxLength: isCloudApiHostingType ? 512 : 256}}
            maxRows={4}
            minRows={4}
            multiline
            source="businessDescription"
          />
          <TextInput
            disabled={!canEdit}
            inputProps={{maxLength: 256}}
            source="businessAddress"
          />
          <TextInput
            disabled={!canEdit}
            inputProps={{maxLength: 128}}
            parse={v => v?.toLowerCase()}
            source="businessEmail"
            validate={[regex(
              EMAIL_ADDRESS_REGEX,
              translate('whatsappAccountBusinessProfileEdit.validations.emailAddress')
            )]}
          />
          <TextInput
            disabled={!canEdit}
            inputProps={{maxLength: 256}}
            source="businessWebsite"
            validate={[regex(
              BUSINESS_WEBSITE_REGEX,
              translate('whatsappAccountBusinessProfileEdit.validations.websiteUrl'),
            )]}
          />
          {
            /**
             * We wrap the input below in a function field so that we can conditionally
             * apply the validation function. React-admin always shows the "None" (empty)
             * option whenever the `required()` validator is missing. In the presence of
             * the `required()` function, saving/submit will fail when this field is null.
             * However, this conflicts with our database/meta schema since the field can
             * be null initially, but once set, cannot be set back to null. Therefore, we
             * allow the input to be optional if the current value is null, and change it
             * only after a value is set.
             */
          }
          <FunctionField
            render={r => (
              <SelectInput
                choices={translatedWhatsappBusinessVerticals}
                disabled={!canEdit}
                optionText={<WhatsappBusinessVerticalOptionText />}
                optionValue="vertical"
                emptyText={translate('ra.input.select.emptyText')}
                source="whatsappBusinessVertical"
                validate={r.whatsappBusinessVertical && required()}
                sx={{'& span[aria-hidden="true"]': {display: 'none'}}}
              />
            )}
            sx={{width: '100%'}}
          />
        </SimpleForm>
      </ExtendedEdit>
    </>
  )
}

const WhatsappBusinessVerticalOptionText = () => {
  const record = useRecordContext()
  return (
    <Typography color="primary" variant="body1">
      {record.description}
    </Typography>
  )
}

const WHATSAPP_BUSINESS_VERTICALS_QUERY = gql`
  query{whatsapp_business_verticals{metaOnPremiseApiName vertical}}
`

const UPLOAD_WHATSAPP_PROFILE_PHOTO_MUTATION = gql`
  mutation(
    $contentBase64: String!
    $filename: String!
    $merchantId: uuid
    $mimeType: String!
    $whatsappAccountId: uuid
  ){
    uploadWhatsappBusinessProfilePhoto(
      contentBase64: $contentBase64
      filename: $filename
      mimeType: $mimeType
      merchantId: $merchantId
      whatsappAccountId: $whatsappAccountId
    ){
      merchant{id whatsappBusinessProfileImageFile{id s3Key}}
      whatsappAccount{id businessProfilePhotoFile{id s3Key}}
    }
  }
`

const WHATSAPP_ACCOUNT_QUERY = gql`
  query ($id: uuid!) {
    whatsapp_accounts_by_pk(id: $id) {
      id
      businessProfilePhotoFile{id s3Key}
      hostingType
    }
  }
`

const BUSINESS_WEBSITE_REGEX = (/^(http|https):\/\/(\w+)/)

interface WhatsappAccountBusinessProfileEditProps {
  id: string | undefined,
}

const useStyles = makeStyles(theme => ({
  editBusinessProfileForm: {
    '& .MuiFormControl-root': {
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      width: '50%',
    },
  },
  profileImageBox: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    width: '50%',
  },
  profileImageConstraints: {
    backgroundColor: theme.palette.info.light,
    border: `1px solid ${theme.palette.info.main}`,
    borderRadius: '10px',
    color: theme.palette.primary.main,
    marginBottom: theme.remSpacing(2),
    padding: theme.remSpacing(2),
  },
}))

export default WhatsappAccountBusinessProfileEdit
