import {Cancel, Pause, PlayArrow} from '@mui/icons-material'
import {IconButton, Slider, Typography} from '@mui/material'
import {isArray} from 'lodash-es'
import {FC, useEffect, useMemo, useState} from 'react'
import {makeStyles} from 'tss-react/mui'

import {elapsedTimeFromSeconds} from '../../../utils/dates'

const AudioPlayer: FC<AudioPlayerProps> = ({
  className,
  disabled = false,
  fileAttachmentUrl,
  onDelete,
}) => {
  const audio = useMemo(
    () => new Audio(fileAttachmentUrl),
    [fileAttachmentUrl]
  )
  const [duration, setDuration] = useState(0)
  const [audioElapsedSeconds, setAudioElapsedSeconds] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const {classes: styles} = useStyles({isPlaying})
  useEffect(() => {
    const onTimeUpdate = () => setAudioElapsedSeconds(audio.currentTime)
    const onDurationChange = () => setDuration(audio.duration)
    const onTogglePlay = () => setIsPlaying(v => !v)
    const onPlaybackEnded = () => setTimeout(
      () => setAudioElapsedSeconds(0),
      // The 300ms timeout is chosen based on the length of the .MuiSlider-thumb
      // animation, currently 0.25 seconds. We want to set the playback to zero
      // only after the animation is over, as it will appear weird if the slider
      // slowly moves backwards when set to zero.
      300,
    )
    audio.addEventListener('timeupdate', onTimeUpdate)
    audio.addEventListener('durationchange', onDurationChange)
    audio.addEventListener('play', onTogglePlay)
    audio.addEventListener('pause', onTogglePlay)
    audio.addEventListener('ended', onPlaybackEnded)
    return () => {
      audio.removeEventListener('timeupdate', onTimeUpdate)
      audio.removeEventListener('durationchange', onDurationChange)
      audio.removeEventListener('play', onTogglePlay)
      audio.removeEventListener('pause', onTogglePlay)
      audio.removeEventListener('ended', onPlaybackEnded)
    }
  }, [audio])
  return (
    <>
      <div className={[styles.container, className].filter(Boolean).join(' ')}>
        <IconButton
          disabled={disabled}
          onClick={() => isPlaying ? audio.pause() : audio.play()}
        >
          {isPlaying ? <Pause /> : <PlayArrow />}
        </IconButton>
        <Typography className={styles.timestamp} color="textSecondary" variant="caption">
          {elapsedTimeFromSeconds(audioElapsedSeconds || duration)}
        </Typography>
        <Slider
          aria-labelledby="continuous-slider"
          className={styles.slider}
          max={duration}
          onChange={(_e, time) => {audio.currentTime = isArray(time) ? time[0] : time}}
          step={duration / 100}
          value={audioElapsedSeconds}
        />
        {onDelete && (
          <IconButton
            disabled={disabled}
            onClick={onDelete}
          >
            <Cancel/>
          </IconButton>
        )}
      </div>
    </>
  )
}

const useStyles = makeStyles<{isPlaying: boolean}>()((theme, {isPlaying}) => ({
  container: {
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: theme.remSpacing(100),
    display: 'flex',
    flexGrow: 0.3,
    justifyContent: 'space-between',
    minWidth: theme.remSpacing(30),
  },
  slider: {
    '& .MuiSlider-thumb, .MuiSlider-track': {
      transition: isPlaying ? 'all .25s linear' : 'unset',
    },
    flexGrow: 1,
    marginLeft: theme.remSpacing(2),
    marginRight: theme.remSpacing(2),
  },
  timestamp: {
    minWidth: '5ch',
  },
}))

interface AudioPlayerProps {
  className?: string
  disabled?: boolean
  fileAttachmentUrl?: string
  onDelete?: () => void
}

export default AudioPlayer
