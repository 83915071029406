import {makeStyles} from '@mui/styles'
import {FC} from 'react'

import {
  ChatMessageFileAttachmentTranslations,
  ChatMessageTranslations,
} from '../../../types/graphqlSchema'

const ChatMessageTranslation: FC<ChatMessageTranslationProps> = ({translations}) => {
  const classes = useStyles()
  return translations.map(({language, text}) => (
    <div className={classes.root} key={`translation-${language}`}>{text}</div>
  ))
}

const useStyles = makeStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.disabled.main}`,
    fontStyle: 'italic',
    paddingTop: theme.remSpacing(1),
  },
}))

interface ChatMessageTranslationProps {
  translations: ChatMessageTranslations[]|ChatMessageFileAttachmentTranslations[]
}

export default ChatMessageTranslation
