import {AppUpdate} from '@capawesome/capacitor-app-update'
import {Button, DialogContent, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'

import {DEFAULT_LANGUAGE, MESSAGES} from '../utils/i18nProvider'
import ExtendedDialog from './ExtendedDialog'
import {LogoIcon} from './icons'

const AppUpdateAvailableDialog = ({isImmediateUpdateAllowed = false} = {}) => (
  <ExtendedDialog keepMounted open>
    <DialogContent className={useStyles().dialogContent}>
      <LogoIcon className={useStyles().logo}/>
      <Typography className={useStyles().dialogMessage} variant="body1">
        {MESSAGES[DEFAULT_LANGUAGE].dialogs.appUpdateAvailable.message}
      </Typography>
      <Button
        color="primary"
        onClick={() => {
          if (isImmediateUpdateAllowed) {
            AppUpdate.performImmediateUpdate()
          }
          else {
            AppUpdate.openAppStore()
          }
        }}
        variant="contained"
      >
        {MESSAGES[DEFAULT_LANGUAGE].dialogs.appUpdateAvailable.button}
      </Button>
    </DialogContent>
  </ExtendedDialog>
)

const useStyles = makeStyles(() => ({
  dialogContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: '2rem',
  },
  dialogMessage: {
    textAlign: 'center',
  },
  logo: {
    height: '150px',
    width: '150px',
  },
}))

export default AppUpdateAvailableDialog
