import {Theme} from '@mui/material'
import {FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {useGetIdentity, useLocale, useTranslate} from 'react-admin'
import {Navigate} from 'react-router-dom'
import {makeStyles} from 'tss-react/mui'

import {SandboxContext} from '../../../contexts/SandboxContext'
import useLongPressEvent from '../../../hooks/useLongPressEvent'
import hexToRgb from '../../../theme/hexToRgb'
import {ChatMessages, WhatsappMessageTypesEnum} from '../../../types/graphqlSchema'
import displayChatMessageStatus from '../../../utils/displayChatMessageStatus'
import ChatContext from '../ChatContext'
import ChatMessageBubble from './ChatMessageBubble'
import ChatMessageForwardButton from './ChatMessageForwardButton'

const ChatMessageListItem: FC<ChatMessageListItemProps> = (
  {isShowingTranslations, onForward, value}
) => {
  const {isSandbox} = useContext(SandboxContext)
  const {chatMessageId: selectedChatMessageId} = useContext(ChatContext)
  const translate = useTranslate()
  const locale = useLocale()
  const {identity: {id: userId} = {}} = useGetIdentity()
  const {cancelTouch, onTouchEnd, onTouchStart} = useLongPressEvent({onEnter: onForward})
  const {
    authorUser,
    chat: {channelId, isProductNews},
    chatMessageTranslations,
    chatMessageWhatsappMessages,
    text,
  } = value
  const isRight = channelId ? !authorUser.customerUser : (authorUser.id === userId)
  const displayText = useMemo(() => {
    if (
      chatMessageWhatsappMessages?.[0]?.whatsappMessage.type ===
        WhatsappMessageTypesEnum.Unknown
    ) {
      return translate('chat.chatMessageBubble.unknownWhatsappMessageTypeText')
    }
    if (isProductNews && !isRight) {
      return chatMessageTranslations.find(t => t.language === locale)?.text ?? text
    }
    return text
  }, [
    chatMessageTranslations,
    chatMessageWhatsappMessages,
    isProductNews,
    isRight,
    locale,
    text,
    translate,
  ])
  const [isHighlighted, setIsHighlighted] = useState(false)
  const bubbleBlinkTimeoutId = useRef<NodeJS.Timeout>()
  const makeChatMessageBubbleBlink = useCallback(() => {
    setIsHighlighted(true)
    clearTimeout(bubbleBlinkTimeoutId.current)
    bubbleBlinkTimeoutId.current = setTimeout(() => setIsHighlighted(false), 2500)
  }, [])
  useEffect(() => {
    if (selectedChatMessageId !== value.id) return
    makeChatMessageBubbleBlink()
    return () => {
      clearTimeout(bubbleBlinkTimeoutId.current)
      setIsHighlighted(false)
    }
  }, [makeChatMessageBubbleBlink, selectedChatMessageId, value])
  const {classes: styles} = useStyles({isHighlighted})
  // TODO: temporary fix to redirect user to 404 page incase a chat_message does not
  //       contain a chat due to permission check failure
  if (!value?.chat) return <Navigate to="/*" />
  return (
    <li
      className={`${styles.root} ${isRight ? styles.right : styles.left}`}
      id={value.id}
      onTouchEnd={e => {
        // Enable context menu when the user releases the touch
        window.oncontextmenu = () => true
        onTouchEnd(e)
      }}
      onTouchMoveCapture={cancelTouch}
      onTouchStart={e => {
        // Disable context menu for touch screen events
        window.oncontextmenu = event => (event as PointerEvent).pointerType !== 'touch'
        onTouchStart(e)
      }}
    >
      <ChatMessageBubble
        displayText={displayText}
        isRight={isRight}
        isShowingTranslations={isShowingTranslations || isRight}
        status={displayChatMessageStatus(value)}
        value={value}
      />
      {(channelId && !isSandbox) && (
        <ChatMessageForwardButton className={styles.forwardButton} onClick={onForward} />
      )}
    </li>
  )
}

const useStyles = makeStyles<MakeStyleProps, 'forwardButton'>()((
  theme, {isHighlighted}, classes
) => ({
  forwardButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    marginBottom: theme.remSpacing(3),
    marginLeft: theme.remSpacing(1),
    marginRight: theme.remSpacing(1),
    zIndex: 1,
  },
  left: getDirectionStyle('left', theme, classes),
  right: getDirectionStyle('right', theme, classes),
  root: {
    '& + &': {
      marginTop: theme.remSpacing(1),
    },
    [`&:not(:hover) > .${classes.forwardButton}`]: {
      display: 'none',
    },
    alignItems: 'center',
    backgroundColor: isHighlighted ?
      `rgba(${hexToRgb(theme.palette.chatBubble.main)}, 0.5)` : 'unset',
    color: '#525f7f',
    display: 'flex',
    paddingBottom: '.25rem',
    paddingTop: '.25rem',
    // Make anchor link go some pixels above where it's linked to.
    // Tells the browser to scroll a bit up by some pixels above the chat message bubble
    // when the user clicks on the chat message replied bubble anchor.
    scrollMarginTop: theme.remSpacing(6),
    transition: 'background-color 0.5s ease-out',
    width: '100%',
  },
}))

const getDirectionStyle = (
  direction: string, theme: Theme, classes: Record<string, string>
): Record<string, any> => ({
  [`& + .${direction === 'right' ? classes.right : classes.left}`]: {
    display: 'none',
    marginTop: theme.remSpacing(30),
  },
  flexDirection: `row${direction === 'right' ? '-reverse' : ''}`,
  justifyContent: `${direction}`,
})

interface ChatMessageListItemProps {
  isShowingTranslations?: boolean
  onForward: () => void
  value: ChatMessages
}

interface MakeStyleProps {
  isHighlighted?: boolean
}

export default ChatMessageListItem
