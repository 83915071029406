import * as dateFNSLocales from 'date-fns/locale'
import {assign, difference, isArray, isEqual, isObject, omit, transform} from 'lodash-es'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import {resolveBrowserLocale} from 'react-admin'

import germanMessages from '../i18n/de'
import englishMessages from '../i18n/en'
import {assert} from './asserts'

const MESSAGES = {
  de: germanMessages,
  en: englishMessages,
}
const SUPPORTED_I18N_LANGUAGES = Object.keys(MESSAGES)
const DATE_FNS_LOCALES_FROM_I18N_LANGUAGES = {
  de: dateFNSLocales.de,
  en: dateFNSLocales.enUS,
}
const DEFAULT_LANGUAGE =
  SUPPORTED_I18N_LANGUAGES.includes(resolveBrowserLocale()) ?
    resolveBrowserLocale() :
    'en'
assert(
  isEqual(SUPPORTED_I18N_LANGUAGES, Object.keys(DATE_FNS_LOCALES_FROM_I18N_LANGUAGES))
)

const flattenKeys = (obj, parentKey = '') =>
  transform(obj, (result, value, key: string) => {
    const currentKey = parentKey ? `${parentKey}.${key}` : key
    if (isObject(value) || isArray(value)) {
      assign(result, flattenKeys(value, currentKey))
    }
    else {
      result[currentKey] = value
    }
  }, {})

Object.keys(MESSAGES).forEach(language => {
  const translationMessageKeys: string[] = Object.keys(flattenKeys(MESSAGES[language]))
  const otherTranslationMessageKeys: string[] = Object.keys(omit(MESSAGES, language))
  otherTranslationMessageKeys.forEach(otherLanguageKey => {
    const missingTranslationKeys: string[] = difference(
      translationMessageKeys, Object.keys(flattenKeys(MESSAGES[otherLanguageKey]))
    )
    if (missingTranslationKeys.length) {
      console.error(
        `%cLanguage "${otherLanguageKey}" misses ` +
        `${missingTranslationKeys.length} translation keys present in language ` +
        `"${language}" ⬇⬇⬇\n\n`,
        "color: brown"
      )
    }
    missingTranslationKeys.forEach(key => {
      console.error(
        `🚨 Translation key %c"${key}"%c is present for language "${language}" but ` +
        `missing for language "${otherLanguageKey}"\n\n`,
        'color: brown',
        'color: red'
      )
    })
  })
})

const i18nProvider = polyglotI18nProvider(
  locale => MESSAGES[locale],
  DEFAULT_LANGUAGE,
  {
    onMissingKey: (key, _, locale) => {
      // Fallback message for untranslated/unknown errors e.g GraphQL/NetworkErrors
      if ((/(GraphQL|Network) error/).test(key)) {
        return MESSAGES[locale].ra.message.error
      }
      return key
    },
  },
)

export default i18nProvider
export {
  DATE_FNS_LOCALES_FROM_I18N_LANGUAGES,
  DEFAULT_LANGUAGE,
  MESSAGES,
  SUPPORTED_I18N_LANGUAGES,
}
