import {createContext, useContext, useEffect, useState} from 'react'
import {Location, UNSAFE_LocationContext} from 'react-router'

const HistoryStackContext = createContext<Location[]>([])

/**
 * Keeps track of and makes available the complete history stack.
 *
 * TODO: We should find a better way of keeping a stack of the browser's history since
 * UNSAFE_LocationContext is an (internal) implementation detail of react-router.
 * At the moment, we are forced to reuse react-router's internals as this is what is
 * used by the entire app for routing.
 */
const HistoryStackProvider = ({children}) => {
  const locationContext = useContext(UNSAFE_LocationContext)
  const [historyStack, setHistoryStack] = useState<Location[]>([])
  useEffect(() => {
    setHistoryStack(s => ({
      'POP': s.slice(0, -1),
      'PUSH': [...s, locationContext.location],
      'REPLACE': [...s.slice(0, -1), locationContext.location],
    }[locationContext.navigationType]))
  }, [locationContext])
  return (
    <HistoryStackContext.Provider value={historyStack}>
      {children}
    </HistoryStackContext.Provider>
  )
}

export default HistoryStackContext

export {HistoryStackProvider}
