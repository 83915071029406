import {CircularProgress, Fab} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {FC, useRef} from 'react'

import useSessionMerchantUser from '../../hooks/useSessionMerchantUser'
import {EditFilledIcon} from '../icons'
import ImageFilePicker, {ImageFilePickerForwardRef} from '../ImageFilePicker'
import ProfileInitials from './ProfileInitials'

const ProfileImageFilePicker: FC<ProfileImageFilePickerProps> = (
  {canEdit, imageFile, loading, onSelectImageFile, uploadedImageUrl}
) => {
  const styles = useStyles()
  const imageFilePickerRef = useRef<ImageFilePickerForwardRef>({} as any)
  const {merchantUser} = useSessionMerchantUser()
  return (
    <ImageFilePicker
      className={styles.root}
      defaultImage={
        uploadedImageUrl ?? <ProfileInitials name={merchantUser?.merchant.name ?? ''}/>
      }
      disableFileSelectionOnClickImage
      isRemoveButtonVisible={false}
      onChange={onSelectImageFile}
      ref={imageFilePickerRef}
      value={imageFile}
    >
      {canEdit && (
        <Fab
          className={styles.iconButton}
          disabled={loading}
          onClick={() => imageFilePickerRef.current.openFileSelectionDialog()}
          size="small"
        >
          {loading ? <CircularProgress color="secondary" size={16}/> : <EditFilledIcon/>}
        </Fab>
      )}
    </ImageFilePicker>
  )
}

const useStyles = makeStyles(theme => {
  const imageSize = theme.remSpacing(14)
  const iconButtonSize = theme.remSpacing(5.5)
  const imageStyles = {
    borderRadius: '50%',
    height: imageSize,
    width: imageSize,
  }
  return {
    iconButton: {
      '&, &:hover': {
        backgroundColor: theme.palette.info.main,
      },
      color: 'white',
      // Horizontally center the button
      left: `calc(50% - (${iconButtonSize} / 2))`,
      top: `calc(${imageSize} - (${iconButtonSize} / 2))`,
    },
    root: {
      '& img': {
        ...imageStyles,
        border: `1px solid ${theme.palette.disabled.main}`,
      },
      marginBottom: `calc(${iconButtonSize} / 2)`,
      width: imageSize,
    },
  }
})

interface ProfileImageFilePickerProps {
  canEdit?: boolean
  imageFile?: FileAttachment
  loading?: boolean
  onSelectImageFile: (fileAttachment?: FileAttachment) => void
  uploadedImageUrl?: string
}

export default ProfileImageFilePicker
